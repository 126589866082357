import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { IOrderConfirmationModel } from './../../../models/order';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, AfterViewInit {
  @ViewChild('portalRegistrationNotification', {static: false}) public portalPopup: TemplateRef<any>;

  orderConfirmation: IOrderConfirmationModel;
  modalRef: BsModalRef;

  constructor(private route: ActivatedRoute, private router: Router, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.orderConfirmation = history.state.orderConfirmationModel;

    this.redirectIfNotValid();
  }

  ngAfterViewInit(): void {
    this.openPortalRegistrationModal();
  }

  redirectIfNotValid() {
    // TODO: This check should be moved to browser cache in case of refresh and should also include checking the hold attached.
    if (this.orderConfirmation != null) { return; }

    this.router.navigateByUrl('/appointments');
  }

  openPortalRegistrationModal() {
    this.modalRef = this.modalService.show(this.portalPopup, { class: 'modal-sm' });
  }

  closePortalRegistrationNotification(): void {
    this.modalRef.hide();
  }
}
