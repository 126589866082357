import { IQuestionAnswerPoolModel, IQuestionPoolModel, IPatientQuestionAnswerModel } from './../../../models/form';
import { FormBuilder, FormGroup, Validators, ControlContainer, FormGroupDirective } from '@angular/forms';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IsValidUSDateValidator } from 'src/app/validators/Date';
import { FormService } from '../../../services/http/form.service'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class DynamicFormComponent implements OnInit {
  @Input() formName: string;
  @Input() singleUse: string;
  @Input() employer: string;
  @ViewChild('phaseModal', { static: false }) public phaseModal: TemplateRef<any>;
  phaseModalRef: BsModalRef;
  formBound: boolean;
  formDir: FormGroupDirective;
  parentForm: FormGroup;
  isMinor: boolean;
  dForm: FormGroup;
  $questionPool: IQuestionPoolModel[];

  constructor(private fb: FormBuilder, private formService: FormService, private parent: FormGroupDirective, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.formDir = this.parent;
    this.parentForm = this.parent.form;

    this.dForm = this.fb.group({
      // sick: ['', [Validators.required]],

      //disabled 8/18/21 by AT
      // allergicReactionCovid19: ['', [Validators.required]],
      // allergicReactionGeneric: ['', [Validators.required]],
      // allergicReactionOther: ['', [Validators.required]],
      // pregnant: ['', [Validators.required]],
      // vaccine14Days: ['', [Validators.required]],
      // dermalFilters: ['', [Validators.required]],
      // recentCovid: ['', [Validators.required]],
      // antibodies: ['', [Validators.required]],
      // clotting: ['', [Validators.required]],

      //hadCOVIDVaccine: ['', [Validators.required]],
      // previousVaccineBrand: [''],
      // previousVaccineDate: [''],
      // previousVaccineBrand2: [''],
      // previousVaccineDate2: [''],
      // signature: ['', [Validators.required]],



      // //new questions
      // sickOrFever: ['', [Validators.required]],
      // allergies: ['', [Validators.required]],
      // seriousallergies: ['', [Validators.required]],
      // anaphylaxis: ['', [Validators.required]],
      // //pregnant: ['', [Validators.required]],
      // bleedingDisorder: ['', [Validators.required]],
      // guillainBarreSyndrome: ['', [Validators.required]],
      // //covidIll: ['', [Validators.required]],
      // convalescentPlasma: ['', [Validators.required]],
      // dermalFillers: ['', [Validators.required]],
      // historyBloodClots: ['', [Validators.required]],
      // historyMycarditis: ['', [Validators.required]],
      // historyHeparin: ['', [Validators.required]],
      // historyInflamation: ['', [Validators.required]],
      // //immune: ['', [Validators.required]],
      // //immunosuppressive: ['', [Validators.required]],

      // //new AT 11/1/21
      // //underlyingmedical: ['', [Validators.required]],
      // //increasedriskforcovid: ['', [Validators.required]],
      // youoryourchildimmunocomprimised: ['', [Validators.required]],

      // highRiskVaccineBrand: [''],
      // highRiskVaccineDate: [''],
      // boosterDoseBrand: [''],
      // boosterDoseDate: [''],
      // otherDoseBrand: [''],
      // otherDoseDate: [''],

      // //highRiskConditions: ['', [Validators.required]],
      // //doseNumber: ['', [Validators.required]],
      // //signatureDate: [''],

    });

    //added form validators on load AT 8/23/21
    //this.dForm.controls.previousVaccineBrand.setValidators([Validators.required]);
    //this.dForm.controls.previousVaccineDate.setValidators([IsValidUSDateValidator,
    // Validators.pattern(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)]);
    // this.dForm.controls.previousVaccineDate2.setValidators([IsValidUSDateValidator,
    //   Validators.pattern(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)]);


    //this.dForm.controls.signatureDate.setValidators([Validators.required, IsValidUSDateValidator,
    //Validators.pattern(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)]);

    this.parentForm.addControl('dForm', this.dForm);
  }

  getQuestionPoolQuestionById(id: number): IQuestionPoolModel {
    return this.$questionPool?.find(qp => qp.questionId === id);
  }

  getQuestionAnswerPoolByQuestionId(questionId: number): IQuestionAnswerPoolModel[] {
    var questionPool = this.getQuestionPoolQuestionById(questionId);

    return questionPool?.questionAnswerPools?.filter(qpa => qpa.questionId === questionId).sort(
      (a, b) => { return a.answerPoolOrder > b.answerPoolOrder ? 1 : -1 });

  }


  getQuestionAnswerPoolByQuestionAnswerId(questionId: number, answerId: number): IQuestionAnswerPoolModel {
    var questionPool = this.getQuestionPoolQuestionById(questionId);
    return questionPool?.questionAnswerPools?.find(qpa => qpa.answerId === answerId);
  }

  getPatientQuestionAnswerPool(appointmentId: number): IPatientQuestionAnswerModel[] {
    const pqams: IPatientQuestionAnswerModel[] = [];


    //disabled 8/18/21 by AT
    //pqams.push(this.createPatientModel(appointmentId, 50, Number(2), ''));
    //pqams.push(this.createPatientModel(appointmentId, 16, Number(this.dForm.controls.allergicReactionCovid19.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 17, Number(this.dForm.controls.allergicReactionGeneric.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 18, Number(this.dForm.controls.allergicReactionOther.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 51, Number(this.dForm.controls.pregnant.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 20, Number(this.dForm.controls.vaccine14Days.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 21, Number(this.dForm.controls.dermalFilters.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 22, Number(this.dForm.controls.recentCovid.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 23, Number(this.dForm.controls.antibodies.value), ''));
    //pqams.push(this.createPatientModel(appointmentId, 49, Number(this.dForm.controls.clotting.value), ''));

    // pqams.push(this.createPatientModel(appointmentId, 1, Number(this.dForm.controls.hadCOVIDVaccine.value), ''));
    // if (this.dForm.controls.hadCOVIDVaccine.value === '1') {


    //}

    // if (this.dForm.controls.previousVaccineBrand.value != null &&
    //   this.dForm.controls.previousVaccineBrand.value != "" &&
    //   Number(this.dForm.controls.previousVaccineBrand.value) != 0) {
    //   pqams.push(this.createPatientModel(appointmentId, 80, Number(this.dForm.controls.previousVaccineBrand.value), ''));
    // }
    // if (this.dForm.controls.previousVaccineDate.value != null &&
    //   this.dForm.controls.previousVaccineDate.value != "") {
    //   pqams.push(this.createPatientModel(appointmentId, 79, null, this.dForm.controls.previousVaccineDate.value));
    // }
    // if (this.dForm.controls.previousVaccineBrand2.value != null &&
    //   this.dForm.controls.previousVaccineBrand2.value != "" &&
    //   Number(this.dForm.controls.previousVaccineBrand2.value) != 0) {
    //   pqams.push(this.createPatientModel(appointmentId, 72, Number(this.dForm.controls.previousVaccineBrand2.value), ''));
    // }
    // if (this.dForm.controls.previousVaccineDate2.value != null &&
    //   this.dForm.controls.previousVaccineDate2.value != "") {
    //   pqams.push(this.createPatientModel(appointmentId, 68, null, this.dForm.controls.previousVaccineDate2.value));
    // }


    // if (this.dForm.controls.highRiskVaccineBrand.value != null &&
    //   this.dForm.controls.highRiskVaccineBrand.value != "" &&
    //   Number(this.dForm.controls.highRiskVaccineBrand.value) != 0) {
    //   pqams.push(this.createPatientModel(appointmentId, 73, Number(this.dForm.controls.highRiskVaccineBrand.value), ''));
    // }
    // if (this.dForm.controls.highRiskVaccineDate.value != null &&
    //   this.dForm.controls.highRiskVaccineDate.value != "") {
    //   pqams.push(this.createPatientModel(appointmentId, 74, null, this.dForm.controls.highRiskVaccineDate.value));
    // }
    // if (this.dForm.controls.boosterDoseBrand.value != null &&
    //   this.dForm.controls.boosterDoseBrand.value != ""&&
    //   Number(this.dForm.controls.boosterDoseBrand.value) != 0) {
    //   pqams.push(this.createPatientModel(appointmentId, 75, Number(this.dForm.controls.boosterDoseBrand.value), ''));
    // }
    // if (this.dForm.controls.boosterDoseDate.value != null &&
    //   this.dForm.controls.boosterDoseDate.value != "") {
    //   pqams.push(this.createPatientModel(appointmentId, 76, null, this.dForm.controls.boosterDoseDate.value));
    // }
    // if (this.dForm.controls.otherDoseBrand.value != null &&
    //   this.dForm.controls.otherDoseBrand.value != ""&&
    //   Number(this.dForm.controls.otherDoseBrand.value) != 0) {
    //   pqams.push(this.createPatientModel(appointmentId, 77, Number(this.dForm.controls.otherDoseBrand.value), ''));
    // }
    // if (this.dForm.controls.otherDoseDate.value != null &&
    //   this.dForm.controls.otherDoseDate.value != "") {
    //   pqams.push(this.createPatientModel(appointmentId, 78, null, this.dForm.controls.otherDoseDate.value));
    // }

    // //pqams.push(this.createPatientModel(appointmentId, 31, Number(this.dForm.controls.highRiskConditions.value), ''));
    // //pqams.push(this.createPatientModel(appointmentId, 44, Number(this.dForm.controls.doseNumber.value), ''));

    // //new questions
    // pqams.push(this.createPatientModel(appointmentId, 52, Number(this.dForm.controls.sickOrFever.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 53, Number(this.dForm.controls.allergies.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 54, Number(this.dForm.controls.seriousallergies.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 55, Number(this.dForm.controls.anaphylaxis.value), ''));
    // //pqams.push(this.createPatientModel(appointmentId, 56, Number(this.dForm.controls.pregnant.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 60, Number(this.dForm.controls.bleedingDisorder.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 61, Number(this.dForm.controls.guillainBarreSyndrome.value), ''));
    // //pqams.push(this.createPatientModel(appointmentId, 58, Number(this.dForm.controls.covidIll.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 59, Number(this.dForm.controls.convalescentPlasma.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 57, Number(this.dForm.controls.dermalFillers.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 62, Number(this.dForm.controls.historyBloodClots.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 63, Number(this.dForm.controls.historyMycarditis.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 64, Number(this.dForm.controls.historyHeparin.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 65, Number(this.dForm.controls.historyInflamation.value), ''));
    // //pqams.push(this.createPatientModel(appointmentId, 66, Number(this.dForm.controls.immune.value), ''));
    // //pqams.push(this.createPatientModel(appointmentId, 67, Number(this.dForm.controls.immunosuppressive.value), ''));

    // //new AT 11/1/21
    // //pqams.push(this.createPatientModel(appointmentId, 68, Number(this.dForm.controls.brand2.value), ''));
    // pqams.push(this.createPatientModel(appointmentId, 69, Number(this.dForm.controls.youoryourchildimmunocomprimised.value), ''));
    // //pqams.push(this.createPatientModel(appointmentId, 70, Number(this.dForm.controls.underlyingmedical.value), ''));
    // //pqams.push(this.createPatientModel(appointmentId, 71, Number(this.dForm.controls.increasedriskforcovid.value), ''));
    
    // pqams.push(this.createPatientModel(appointmentId, 47, null, this.dForm.controls.signature.value));
    // //pqams.push(this.createPatientModel(appointmentId, 48, null, this.dForm.controls.signatureDate.value));

    // //console.error(pqams);


    return pqams;
  }

  setIsMinor(isMinor: boolean) {
    this.isMinor = isMinor;
  }

  bindForm(isVaccine: boolean) {
    if (isVaccine) {
      this.formBound = true;
      this.formService.getDomainProductVaccineByEmployer(this.formName).subscribe(data => {
        this.$questionPool = data as IQuestionPoolModel[];
      })
    } else {
      this.parentForm.removeControl('dForm');
    }
  }

  copyMessage(val: string) {
    this.dForm.get('signature').patchValue(`Verified by registration staff at ${val}`);
  }

  /*   hadCOVIDVaccineChanged() {
      if (this.dForm.controls.hadCOVIDVaccine.value === "1") {
        this.dForm.controls.previousVaccineDate.setValidators([Validators.required, IsValidUSDateValidator,
        Validators.pattern(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)]);
        this.dForm.controls.previousVaccineBrand.setValidators([Validators.required]);
  
      } else {
        this.dForm.controls.previousVaccineDate.clearValidators();
        this.dForm.controls.previousVaccineBrand.clearValidators();
      }
      this.parentForm.addControl('dForm', this.dForm);
    } */

  createPatientModel(appointmentId: number, questionId: number, answerId: number, answerText: string): IPatientQuestionAnswerModel {
    const patientQuestionAnswerModel: IPatientQuestionAnswerModel = {
      patientId: 0,
      appointmentId: appointmentId,
      questionId: questionId,
      answerId: answerId,
      answerText: answerText
    };

    return patientQuestionAnswerModel;
  }
}
