import { ICancellationModel, ICancellationAppointmentModel } from './../../../models/cancellation';
import { CancellationService } from './../../../services/http/cancellation.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IsValidUSDateValidator } from 'src/app/validators/Date';


@Component({
  selector: 'app-vaccine-cancellation',
  templateUrl: './vaccine-cancellation.component.html',
  styleUrls: ['./vaccine-cancellation.component.css']
})
export class VaccineCancellationComponent implements OnInit {
  aForm: FormGroup;
  token$: string;
  cancellationAppointmentFailed: boolean;
  cancellationAppointments$: ICancellationAppointmentModel[];
  cancellationRequestFailed: boolean;
  cancellationSuccess$: boolean;
  isTokenUsed$: boolean;

  getFirstAppointment(): ICancellationAppointmentModel{
    return this.cancellationAppointments$?.find(a => true);
  }

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,
    private cancellationService: CancellationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(p => {
      this.token$ = p.token;

      this.cancellationService.getIsCancellationTokenUsed(this.token$).subscribe(data => {
        this.isTokenUsed$ = data as boolean;
      });
    });

    this.aForm = this.fb.group({
      phone: ['', [Validators.required, Validators.maxLength(12)]],
      dob: ['', [Validators.required, IsValidUSDateValidator,
      Validators.pattern(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)]]
    });
  }

  GetAppointmentsSubmit() {
    this.cancellationAppointmentFailed = false;
    if (this.aForm.invalid) { return; }

    const cancellationModel = this.getCancellationModel();

    this.cancellationService.getAppointmentsByToken(cancellationModel).subscribe(data => {
      this.cancellationAppointments$ = data as ICancellationAppointmentModel[];

      if (this.cancellationAppointments$?.length < 1) { this.cancellationAppointmentFailed = true; }
    },
      err => {
        this.cancellationAppointmentFailed = true;
      });
  }

  SubmitCancellation() {
    this.cancellationRequestFailed = false;
    if (this.aForm.invalid) { return; }

    const cancellationModel = this.getCancellationModel();

    this.cancellationService.cancelAppointmentsByToken(cancellationModel).subscribe(data => {
      this.cancellationSuccess$ = data as boolean;

      if (!this.cancellationSuccess$) { this.cancellationRequestFailed = true; }
    },
      err => {
        this.cancellationRequestFailed = true;
      });

  }

  SubmitDoNotCancel() {
    this.router.navigateByUrl('/appointments/vaccine');
  }

  getCancellationModel(): ICancellationModel {
    const cancellationModel: ICancellationModel = {
      cancellationToken: this.token$,
      phone: this.aForm.get('phone').value,
      dob: this.aForm.get('dob').value
    };

    return cancellationModel;
  }
}
