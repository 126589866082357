import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private http: HttpClient) { }
  url: string = environment.apiBaseUrl;

  getDomainEthnicity() {
    return this.http.get(this.url + 'Domain/DomainEthnicity')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainGenderIdentity() {
    return this.http.get(this.url + 'Domain/DomainGenderIdentity')
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getDomainGuardian() {
    return this.http.get(this.url + 'Domain/DomainGuardian')
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getDomainProduct() {
    return this.http.get(this.url + 'Domain/DomainProduct')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainRace() {
    return this.http.get(this.url + 'Domain/DomainRace')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainSexAtBirth() {
    return this.http.get(this.url + 'Domain/DomainSexAtBirth')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainSite() {
    return this.http.get(this.url + 'Domain/DomainSite')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainState() {
    return this.http.get(this.url + 'Domain/DomainState')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDtcDomainProductTestCount() {
    return this.http.get(this.url + 'Domain/GetDtcDomainProductFutureTestCounts')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainProductTestCount(employerName: string) {
    let params = new HttpParams().set("employerName", employerName);
    return this.http.get(this.url + 'Domain/GetDomainProductFutureTestCounts/', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDtcDomainProductVaccine() {
    return this.http.get(this.url + 'Domain/GetDtcDomainProductVaccine')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainProductVaccineByEmployer(employerName: string) {
    let params = new HttpParams().set("employerName", employerName);
    return this.http.get(this.url + 'Domain/GetDomainProductVaccineByEmployer/', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainSiteCount(product: string) {
    let params = new HttpParams().set("product", product);
    return this.http.get(this.url + 'Domain/GetDomainSiteFutureCountByProduct/', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainSiteEmployerCount(product: string, employer: string) {
    let params = new HttpParams().set("product", product).set("employer", employer);
    return this.http.get(this.url + 'Domain/GetDomainSiteEmployerFutureCountByProduct/', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getDomainProductTypeConfigContentsById(productType: string) {
    let params = new HttpParams().set("productType", productType);
    return this.http.get(this.url + 'Domain/DomainProductTypeConfigContentsById', { params })
    .pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Invalid Server Response; please try again later.');
  }
}
