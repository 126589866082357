import { ICancellationModel } from './../../models/cancellation';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CancellationService {

  constructor(private http: HttpClient) { }
  url: string = environment.apiBaseUrl;

  getIsCancellationTokenUsed(cancellationToken: string) {
    const params = new HttpParams().set('cancellationToken', cancellationToken);
    return this.http.get(this.url + 'Cancellation/IsCancellationTokenUsed/', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getAppointmentsByToken(cancellation: ICancellationModel){
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.post(this.url + 'Cancellation/GetAppointmentsByToken/', JSON.stringify(cancellation), { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  cancelAppointmentsByToken(cancellation: ICancellationModel){
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.post(this.url + 'Cancellation/CancelAppointmentsByToken/', JSON.stringify(cancellation), { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Invalid Server Response; please try again later.');
  }
}
