import { VaccineCancellationComponent } from './components/appointments/vaccine-cancellation/vaccine-cancellation.component';
import { VaccineConfirmationComponent } from './components/appointments/vaccine-confirmation/vaccine-confirmation.component';
import { VaccineComponent } from './components/appointments/vaccine/vaccine.component';
import { TokenComponent } from './components/appointments/token/token.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { OrderComponent } from './components/appointments/order/order.component';
import { ConfirmationComponent } from './components/appointments/confirmation/confirmation.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { VaccineTokenComponent } from './components/appointments/vaccine-token/vaccine-token.component';

const routes: Routes = [
  { path: '', component: AppointmentsComponent, data: { title: 'Appointment Search'}, pathMatch: 'full' },
  { path: 'appointments/order', component: OrderComponent, data: { title: 'Appointment Order'}, pathMatch: 'full'},
  { path: 'appointments/confirmation', component: ConfirmationComponent, data: { title: 'Appointment Confirmation'}, pathMatch: 'full' },
  { path: 'appointments/token', component: TokenComponent, data: { title: 'Token Search'}, pathMatch: 'full'},
  { path: 'appointments/vaccine', component: VaccineComponent, data: { title: 'Vaccine Search'}, pathMatch: 'full'},
  { path: 'appointments/vaccineconfirmation', component: VaccineConfirmationComponent, data: { title: 'Vaccine Confirmation'}, pathMatch: 'full'},
  { path: 'appointments/vaccine/token', component: VaccineTokenComponent, data: { title: 'Vaccine Token Search'}, pathMatch: 'full'},
  { path: 'appointments/vaccine/token/:token', component: VaccineTokenComponent, data: { title: 'Vaccine Token Search'}, pathMatch: 'full'},
  { path: 'appointments/vaccine/cancellation/:token', component: VaccineCancellationComponent, data: { title: 'Vaccine Cancellation'}, pathMatch: 'full'}
];

const maskConfig: Partial<IConfig> = { clearIfNotMatch: true };

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
