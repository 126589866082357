<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div
        style="
          padding-top: 30px;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 10px;
        "
      >
        <span
          [innerHTML]="
            domainProductTypeConfigContentModel$?.tokenSearchPageGeneral
              | htmlSanitize
          "
        ></span>
      </div>
    </div>
  </div>
  <form [formGroup]="aForm" #formDir="ngForm">
    <div style="background: #93b4d2; padding: 10px">
      <div class="row">
        <div class="col-sm-12">
          <span
            [innerHTML]="
              domainProductTypeConfigContentModel$?.tokenSearchPageAssistance
                | htmlSanitize
            "
          ></span>
        </div>
        <div class="col-sm-2">
          <input
            class="w-100 form-control"
            type="text"
            formControlName="token"
            id="token"
            required
            mask="AA-AAAAAAAA-AAAA"
            [showMaskTyped]="true"
            [dropSpecialCharacters]="false"
            [ngClass]="{ 'is-invalid': getToken().invalid }"
            (keyup.enter)="searchToken()"
          />
        </div>
        <div class="col-sm-10">
          <button
            class="btn btn-primary"
            type="button"
            (click)="searchToken()"
            [disabled]="aForm.pristine || getToken().value.length != 16"
          >
            Submit
          </button>
          &nbsp;
          <span class="text-danger" *ngIf="this.tokenResponseError"
            >We are sorry there appears to be a problem on our end. We are
            looking into it. Please try again later.</span
          >
          <span class="text-danger" *ngIf="this.noFutureAppointmentsWarning">
            We are sorry there are currently no available appointments for the
            access code submitted. Please try again later. If you continue to
            have issues contact the person who provided the code.
          </span>
          <span class="text-danger" *ngIf="this.tokenResponse$?.errorMessage">{{
            this.tokenResponse$?.errorMessage
          }}</span>
        </div>
      </div>
      <br />
      <br />
      <div class="row" [hidden]="!this.getSelectedProduct()?.productDesc">
        <div class="col-sm-12">
          <div
            style="padding-right: 100px"
            [innerHTML]="this.getSelectedProduct()?.productDesc"
          ></div>
        </div>
      </div>
      <br />
      <br />
      <div
        class="row"
        [hidden]="
          !this.getAvailableSites() || this.getAvailableSites()?.length <= 1
        "
      >
        <div class="col-sm-12">
          <div>
            <h3>
              <label for="sites">
                Select a location to see appointment times.</label
              >
            </h3>
            <select
              class="form-control"
              formControlName="site"
              id="site"
              style="width: 75%"
              required
              (change)="siteSelected()"
            >
              <option value="0">Select a Site</option>
              <option
                *ngFor="let site of this.getAvailableSites(); let i = index"
                [value]="sites$[i].siteId"
              >
                {{ site.siteDescription }}
                <span *ngIf="site.futureAppointments <= 0"
                  >(Currently Unavailable)</span
                >
              </option>
            </select>
          </div>
          <br />
          <br />
        </div>
      </div>
      <div
        class="row"
        [hidden]="
          !this.getSelectedProduct() ||
          (this.getAvailableSites()?.length > 0 &&
            (this.getSite()?.value == '0' ||
              (this.getSite()?.value !== '0' &&
                this.getIsAvailableAppointmentDates())))
        "
      >
        <div class="col-sm-12">
          <div>
            <h3><span class="text-danger">(Currently Unavailable)</span></h3>
          </div>
        </div>
      </div>
      <div
        class="row"
        [hidden]="
          !this.getSelectedProduct()?.productDesc ||
          !this.getIsAvailableAppointmentDates()
        "
      >
        <div class="col-sm-12">
          <div>
            <h3><label for="availableDates">Select a Date</label></h3>
            <select
              class="form-control"
              formControlName="availableDates"
              id="availableDates"
              style="width: 75%"
              required
              (change)="availableDateSelected($event.target.value)"
            >
              <option value="">Select a Date</option>
              <option
                *ngFor="
                  let appointment of availableAppointments$;
                  let i = index
                "
                [value]="availableAppointments$[i].appointmentDate"
              >
                {{ appointment.appointmentDate | date: "MM/dd/yyyy" }}
                <span *ngIf="appointment.numOpenApptsForDay <= 10">
                  - {{ appointment.numOpenApptsForDay }} Remaining</span
                >
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <span [hidden]="!availableAppointmentsSelectedDate$">
      <h4>
        {{ getSelectedDate | date: "EEEE, MMMM dd, yyy" }} - Testing Appointment
        Times
      </h4>
      <div
        formArrayName="appointments"
        class="conatiner"
        style="
          padding-top: 30px;
          padding-left: 50px;
          padding-right: 20px;
          padding-bottom: 10px;
        "
      >
        <!--Begin appt time rows-->
        <div
          *ngFor="
            let line of this.availableAppointmentsSelectedDate$;
            let appointmentIndex = index
          "
          [formGroupName]="appointmentIndex"
        >
          <div class="row">
            <div class="col-sm-8">
              <h4>
                {{
                  this.availableAppointmentsSelectedDate$[appointmentIndex]
                    ?.appointmentTimeDisplay
                }}
              </h4>
              {{ this.getSelectedProduct().productDisplayName }} ({{
                this.tokenResponse$.price | currency
              }}
              each)
            </div>
            <div class="col-sm-4">
              Test Quantity:<span
                class="text-danger"
                *ngIf="
                  appointmentsSelected(appointmentIndex) >
                  maxAppointmentsToSelect(
                    this.availableAppointmentsSelectedDate$[appointmentIndex]
                      .numAppsAvailable
                  )
                "
              >
                {{
                  maxAppointmentsToSelect(
                    this.availableAppointmentsSelectedDate$[appointmentIndex]
                      .numAppsAvailable
                  )
                }}
                max
              </span>
              <input
                class="form-control"
                type="text"
                formControlName="numAppsSelected"
                placeholder="Enter a number"
                mask="99"
                [clearIfNotMatch]="false"
                (change)="resetHoldWarnings()"
                [ngClass]="{
                  'is-invalid':
                    getAppointment(appointmentIndex).controls.numAppsSelected
                      .invalid
                }"
              />
              <br />
              <div style="text-align: right; font-weight: bold">
                {{
                  this.availableAppointmentsSelectedDate$[appointmentIndex]
                    .numAppsAvailable
                }}
                Available
              </div>
            </div>
          </div>
        </div>
        <!--End appt time rows-->
      </div>
      <br />
      <div class="text-right">
        <h4 [hidden]="this.totalAppointmentsSelected > 1">
          Total: {{ subTotal | currency }}
        </h4>
        <button
          class="btn btn-primary"
          type="button"
          (click)="onSubmit()"
          [disabled]="
            aForm.pristine ||
            aForm.invalid ||
            this.totalAppointmentsSelected > 1 ||
            this.totalAppointmentsSelected < 1
          "
        >
          Next
        </button>
      </div>
    </span>
    <div
      class="text-right text-danger"
      [hidden]="this.totalAppointmentsSelected <= 1"
    >
      Max 1 appointment per token
    </div>
  </form>
  <div class="text-right text-danger" [hidden]="!this.quantityChangeWarning">
    We apologize, but the appointment time&#40;s&#41; you selected is/are no
    longer available. Please select a new date above.
  </div>
  <div class="text-right text-danger" [hidden]="!this.dayChangeWarning">
    We apologize, but the appointment time&#40;s&#41; you selected is/are no
    longer available. Please select a new date above.
  </div>
  <br />
  <br />
  <br />
</div>
