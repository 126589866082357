<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div style="
          padding-top: 30px;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 10px;
        ">
        <span [hidden]="!this.multiTokenBookingSuccess" class="alert">
          <p style="font-size: 18pt">
            <strong>Appointment Created</strong>
          </p>
        </span>
        <span [innerHTML]="
            domainProductTypeConfigContentModel$?.tokenSearchPageGeneral
              | htmlSanitize
          "></span>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <h2>Schedule an Appointment</h2>
  <form [formGroup]="aForm" #formDir="ngForm">
    <div class="container" style="background: #93b4d2; padding: 10px">
      <div class="row">
        <div class="col-sm-12">
          <span [innerHTML]="
              domainProductTypeConfigContentModel$?.tokenSearchPageAssistance
                | htmlSanitize
            "></span>
          <span style="margin-right:20px; padding-right:20px;">
            National Jewish Health Employees Only: By use of this employee access code, I hereby authorize release of my COVID-19 vaccination information to National Jewish Health’s Employee Health Department.
          </span>
          <br /><br />
        </div>
        <div class="col-sm-2">
          <input class="w-100 form-control" type="text" formControlName="token" id="token" required
            mask="AA-AAAAAAAA-AAAA" [showMaskTyped]="true" [dropSpecialCharacters]="false"
            [ngClass]="{ 'is-invalid': getToken().invalid }" (keyup.enter)="searchToken()" />
        </div>
        <div class="col-sm-10">
          <button class="btn btn-primary" type="button" (click)="searchToken()"
            [disabled]="aForm.pristine || getToken().value.length != 16">
            Submit
          </button>
          &nbsp;
          <span class="text-danger" *ngIf="this.tokenResponseError">We are sorry there appears to be a problem on our
            end. We are
            looking into it. Please try again later.</span>
          <span class="text-danger" *ngIf="this.noFutureAppointmentsWarning">
            We are sorry there are currently no available appointments for the
            access code submitted. Please try again later. If you continue to
            have issues contact the person who provided the code.
          </span>
          <span class="text-danger" *ngIf="this.tokenResponse$?.errorMessage">{{
            this.tokenResponse$?.errorMessage
            }}</span>
        </div>
      </div>
      <span *ngIf="
          this.tokenResponse$?.singleUseFlag === 'N' &&
          this.availabilityCount > 0
        ">
        <br />
        <strong>{{ this.availabilityCount }} Appointments Available </strong>
        <br />
      </span>
      <br />
      <div class="row" [hidden]="!this.getSelectedProduct1()?.productDesc">
        <div class="col-sm-12">
          <div style="padding-right: 100px" [innerHTML]="this.getSelectedProduct1()?.productDesc | htmlSanitize"></div>
        </div>
      </div>
      <br />
      <div class="row" [hidden]="
          !this.getAvailableSites() || this.getAvailableSites()?.length <= 1
        ">
        <div class="col-sm-12">
          <div>
            <h3>
              <label for="sites">
                Select a location to see appointment times.</label>
            </h3>
            <select class="form-control" formControlName="site" id="site" style="width: 75%" required
              (change)="siteSelected()">
              <option value="0">Select a Site</option>
              <option *ngFor="let site of this.getAvailableSites(); let i = index" [value]="site.siteId">
                {{ site.siteDescription }}
                <span *ngIf="site.futureAppointments <= 0">(Currently Unavailable)</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="
          !this.getSelectedProduct1() ||
          (this.getAvailableSites()?.length > 0 &&
            (this.getSite()?.value == '0' ||
              (this.getSite()?.value !== '0' &&
                this.getIsAvailableAppointmentDates())))
        ">
        <div class="col-sm-12">
          <div>
            <h3><span class="text-danger">(Currently Unavailable)</span></h3>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="!this.getIsAvailableAppointmentDates()">
        <br />
        <div class="col-sm-12">
          <div>
            <h3>
              <label for="availableDates1">Date &amp; Time </label>
            </h3>
            <select class="form-control" formControlName="availableDates1" id="availableDates1" style="width: 75%"
              required (change)="availableDateSelected1($event.target.value)">
              <option value="">Select a Date</option>
              <option *ngFor="
                  let appointment of availableAppointments1$;
                  let i = index
                " [value]="availableAppointments1$[i].appointmentDate">
                {{ appointment.appointmentDate | date: "MM/dd/yyyy" }}
                <span *ngIf="appointment.numAppointments <= 10">
                  - {{ appointment.numAppointments }} Remaining</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="availableAppointmentsSelectedDate1$?.length <= 0">
        <div class="col-sm-12">
          <div>
            <br />
            <select class="form-control" formControlName="availableTimes1" id="availableTimes1" style="width: 75%"
              required (change)="availableAppointmentSelected1($event.target.value)">
              <option value="">Select a Time</option>
              <option *ngFor="
                  let appointment of availableAppointmentsSelectedDate1$;
                  let i = index
                " [value]="availableAppointmentsSelectedDate1$[i].appointmentId">
                {{ appointment.appointmentTimeDisplay }}
                <span *ngIf="appointment.numAppsAvailable <= 10">
                  - {{ appointment.numAppsAvailable }} Remaining</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <br />
      <span [hidden]="
          this.availableAppointments1$ &&
          this.availableAppointments1$[0] &&
          this.availableAppointments1$[0].childProduct == ''
        ">
        <div class="row" [hidden]="availableAppointmentsSelectedDate1$?.length <= 0">
          <div class="col-sm-12">
            <div>
              <h3>
                <label for="availableDates2">Date &amp; Time for Second Dose</label>
              </h3>
              <p>
                <strong>Note: Second dose date is automatically calculated to meet
                  vaccination requirements.
                </strong>
              </p>
              <select class="form-control" formControlName="availableDates2" id="availableDates2" style="width: 75%"
                required (change)="availableDateSelected2($event.target.value)">
                <option value="">Select a Date</option>
                <option *ngFor="
                    let appointment of availableAppointments2$;
                    let i = index
                  " [value]="availableAppointments2$[i].appointmentDate">
                  {{ appointment.appointmentDate | date: "MM/dd/yyyy" }}
                  <span *ngIf="appointment.numOpenApptsForDay <= 10">
                    - {{ appointment.numOpenApptsForDay }} Remaining</span>
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" [hidden]="
            availableAppointmentsSelectedDate1$?.length <= 0 ||
            !availableAppointmentsSelectedDate2$
          ">
          <div class="col-sm-12">
            <br />
            <select class="form-control" formControlName="availableTimes2" id="availableTimes2" style="width: 75%"
              required (change)="availableAppointmentSelected2($event.target.value)">
              <option value="">Select a Time</option>
              <option *ngFor="
                  let appointment of availableAppointmentsSelectedDate2$;
                  let i = index
                " [value]="availableAppointmentsSelectedDate2$[i].appointmentId">
                {{ appointment.appointmentTimeDisplay }}
                <span *ngIf="appointment.numAppsAvailable <= 10">
                  - {{ appointment.numAppsAvailable }} Remaining</span>
              </option>
            </select>
          </div>
        </div>
      </span>
    </div>
    <br />
    <br />
    <span [hidden]="
        !this.product1 ||
        availableAppointmentsSelectedDate1$?.length <= 0 ||
        !selectedAppointment1 ||
        (!(!this.product1.childProduct && this.product1.childProduct === '') &&
          (!availableAppointmentsSelectedDate2$ || !selectedAppointment2))
      ">
      <br />
      <div class="text-right">
        <button class="btn btn-primary" type="button" (click)="onSubmit()">
          Next
        </button>
      </div>
    </span>
  </form>
  <div class="text-right text-danger" [hidden]="!this.quantityChangeWarning">
    We apologize, but the appointment time&#40;s&#41; you selected is/are no
    longer available. Please select a new date above.
  </div>
  <div class="text-right text-danger" [hidden]="!this.dayChangeWarning">
    We apologize, but the appointment time&#40;s&#41; you selected is/are no
    longer available. Please select a new date above.
  </div>
  <br />
  <br />
  <br />
</div>