import { ITokenResponseModel } from './../../../models/token';
import { TokenService } from 'src/app/services/http/token.service';
import { DomainService } from 'src/app/services/http/domain.service';
import { IDomainProductCountModel, IDomainProductTypeConfigContentsModel, IDomainSiteCountModel } from './../../../models/domains';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { IHoldRequestModel, IAppointmentHoldRequestModel, IAppointmentOnHoldModel } from 'src/app/models/appointmentHolds';
import { IAvailableAppointmentsModel, IFutureAppointmentsByDateModel } from 'src/app/models/appointments';
import { AppointmentService } from 'src/app/services/http/appointment.service';
import { AppointmentHoldService } from 'src/app/services/http/appointmenthold.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent implements OnInit {
  aForm: FormGroup;
  availableAppointments$: IFutureAppointmentsByDateModel[];
  availableAppointmentsSelectedDate$: IAvailableAppointmentsModel[];
  domainProductTypeConfigContentModel$: IDomainProductTypeConfigContentsModel;
  products$: IDomainProductCountModel[];
  sites$: IDomainSiteCountModel[];
  price$: number;
  tokenResponse$: ITokenResponseModel;
  selectedDate: Date;
  subTotal = 0;
  totalAppointmentsSelected: number;
  tokenResponseError: boolean;
  quantityChangeWarning: boolean;
  dayChangeWarning: boolean;
  soldOutWarning: boolean;
  noFutureAppointmentsWarning: boolean;

  get getSelectedDate(): Date {
    return this.selectedDate;
  }

  getIsAvailableAppointmentDates(): boolean {
    return this.availableAppointments$?.length > 0;
  }

  maxAppointmentsToSelect(maxAvailable): number {
    return maxAvailable >= 1 ? 1 : maxAvailable;
  }

  appointmentsSelected(index: number): number {
    const appointments = this.aForm.get('appointments') as FormArray;
    return appointments.at(index).value.numAppsSelected;
  }

  getToken() {
    return this.aForm.get('token');
  }

  getAppointment(index: number): FormGroup {
    const appointments = this.aForm.get('appointments') as FormArray;
    return appointments.controls[index] as FormGroup;
  }

  getSelectedProduct(): IDomainProductCountModel {
    return this.products$?.find(p => p.product === this.getProduct().value);
  }

  getSelectedSite(): IDomainSiteCountModel {
    return this.sites$.find(s => s.siteId.toString() === this.getSite().value.toString());
  }

  getProduct() {
    return this.aForm.get('product');
  }

  getSite() {
    return this.aForm.get('site');
  }

  getAvailableSites(): IDomainSiteCountModel[] {
    return this.sites$?.filter(s => s.active.toLowerCase() === 'y' && s.futureAppointments > 0);
  }

  get appointment(): FormGroup {
    return this.fb.group({
      appointmentDate: Date,
      appointmentId: ['', Validators.required],
      appointmentTimeDisplay: [''],
      numAppsAvailable: [''],
      numAppsSelected: [0, [Validators.min(0), Validators.max(4)]]
    });
  }

  constructor(private router: Router, private fb: FormBuilder, private tokenService: TokenService,
    private appointmentService: AppointmentService, private appointmentHoldService: AppointmentHoldService,
    private domainService: DomainService) { }

  ngOnInit(): void {

    this.aForm = this.fb.group({
      token: [''],
      product: [''],
      site: [''],
      availableDates: [''],
      appointments: this.fb.array([])
    });

    this.domainService.getDomainProductTypeConfigContentsById('Test').subscribe(data => {
      this.domainProductTypeConfigContentModel$ = data as IDomainProductTypeConfigContentsModel;
    });

    this.totalAppointmentsSelected = 0;

    this.onAppointmentChanges();
  }

  getFutureAppointments(dt: Date): void {
    this.appointmentService.getFutureAppointmentsAvailable(this.getSelectedProduct()?.product,
      this.getSelectedSite()?.siteId, this.tokenResponse$.employerName).subscribe(data => {
        this.availableAppointments$ = data as IFutureAppointmentsByDateModel[];

        if (dt === null || !this.availableAppointments$.find(a => a.appointmentDate === dt)) {
          if (this.quantityChangeWarning) {
            this.quantityChangeWarning = false;
            if (this.availableAppointments$?.length > 0) {
              this.dayChangeWarning = true;
            } else {
              this.soldOutWarning = true;
            }
          }

          dt = this.availableAppointments$[0]?.appointmentDate;
          this.aForm.get('availableDates').patchValue('');
        }

        this.selectedDate = dt;
      });
  }

  onAppointmentChanges(): void {
    this.aForm.get('appointments').valueChanges.subscribe(val => {
      this.subTotal = 0;
      this.totalAppointmentsSelected = 0;

      const appointments = this.aForm.get('appointments') as FormArray;

      appointments.controls.forEach(appointment => {
        this.subTotal = this.subTotal + (appointment.value.numAppsSelected * this.tokenResponse$.price);
        this.totalAppointmentsSelected = +this.totalAppointmentsSelected + +appointment.value.numAppsSelected;
      });
    });
  }

  resetHoldWarnings() {
    this.tokenResponseError = false;
    this.quantityChangeWarning = false;
    this.dayChangeWarning = false;
    this.soldOutWarning = false;
    this.noFutureAppointmentsWarning = false;
  }

  updateAvailableAppointmentsForSelectedDate() {
    const datePipe = new DatePipe('en-US');

    if (datePipe.transform(this.selectedDate, 'MM/dd/yyyy') == null) {
      this.availableAppointmentsSelectedDate$ = null;
      return;
    }

    this.appointmentService.getAvailableAppointments(
      datePipe.transform(this.selectedDate, 'MM/dd/yyyy'), this.getSelectedProduct()?.product, this.getSelectedSite()?.siteId,
      this.tokenResponse$.employerName).subscribe(data => {
        this.availableAppointmentsSelectedDate$ = data as IAvailableAppointmentsModel[];
        console.log(this.availableAppointmentsSelectedDate$);
        const appointmentsFormArray = this.aForm.get('appointments') as FormArray;

        // Reset pristine/touched and available appointment list
        this.aForm.get('appointments').reset();
        appointmentsFormArray.clear();

        this.availableAppointmentsSelectedDate$.forEach(appointment => {
          this.selectedDate = appointment.appointmentDate;
          appointmentsFormArray.push(this.appointment);
        });

        this.aForm.get('appointments').patchValue(this.availableAppointmentsSelectedDate$);

        ((this.aForm.get('appointments') as FormArray).controls).forEach(e => {
          const x = this.maxAppointmentsToSelect(e.value.numAppsAvailable);
          e.get('numAppsSelected').setValidators([Validators.min(0), Validators.max(x)]);
          e.get('numAppsSelected').updateValueAndValidity();
        });
      }
      );
  }

  searchToken() {
    if (this.getToken().value.length !== 16) { return; }

    this.resetHoldWarnings();
    this.getSite().patchValue(0);

    this.tokenService.validateToken(this.getToken().value).subscribe(data => {
      this.tokenResponse$ = data as ITokenResponseModel;

      this.domainService.getDomainProductTestCount(this.tokenResponse$.employerName).subscribe(data => {
        this.products$ = data as IDomainProductCountModel[];

        if (this.tokenResponse$.errorMessage) {
          return;
        }

        this.getProduct().patchValue(this.tokenResponse$.product);
        this.price$ = this.tokenResponse$.price;

        this.productSelected();

      },
        (err) => {
          this.tokenResponseError = true;
        }
      );
    },
      (err) => {
        this.tokenResponseError = true;
      }
    );
  }

  productSelected() {
    this.availableAppointments$ = null;
    this.availableAppointmentsSelectedDate$ = null;
    this.getSite().patchValue(0);

    if (this.getSelectedProduct()?.futureAppointments > 0) {
      this.domainService.getDomainSiteCount(this.getSelectedProduct().product).subscribe(data => {
        this.sites$ = data as IDomainSiteCountModel[];

        if (!this.getAvailableSites || this.getAvailableSites()?.length === 0) {
          this.soldOutWarning = true;
        }
        if (this.getAvailableSites()?.length === 1) {
          this.getSite().patchValue(this.sites$[0].siteId);
          this.siteSelected();
        }
      });
    } else {
      this.noFutureAppointmentsWarning = true;
      this.sites$ = null;
    }
  }

  siteSelected() {
    this.availableAppointmentsSelectedDate$ = null;

    if (this.getSelectedSite()?.futureAppointments > 0) {
      this.getFutureAppointments(null);
    } else {
      this.availableAppointments$ = null;
    }
  }

  availableDateSelected(selectedDate: Date) {
    this.availableAppointmentsSelectedDate$ = null;
    this.resetHoldWarnings();

    if (selectedDate) {
      this.selectedDate = selectedDate;
      this.updateAvailableAppointmentsForSelectedDate();
    }
  }

  onSubmit() {
    // TODO: Get Hold key
    const currentSelectedDate = this.aForm.get('availableDates').value;
    const appointments = this.aForm.get('appointments').value as IAvailableAppointmentsModel[];

    // tslint:disable-next-line: prefer-const
    let appointmentHolds: IAppointmentHoldRequestModel[] = [];
    appointments.forEach(appointment => {
      if (appointment.numAppsSelected > 0) {
        // tslint:disable-next-line: prefer-const
        let appt: IAppointmentHoldRequestModel = {
          appointmentId: appointment.appointmentId,
          numberOfAppointments: appointment.numAppsSelected,
          product: this.getSelectedProduct().product,
          token: this.tokenResponse$.token,
          price: this.tokenResponse$.price
        };
        appointmentHolds.push(appt);
      }
    });

    const holdRequest: IHoldRequestModel = {
      appointmentHoldRequests: appointmentHolds,
      subTotal: this.subTotal
    };

    console.log(holdRequest);
    this.appointmentHoldService.requestAppointmentHolds(holdRequest).subscribe(data => {
      const holds$ = data as IAppointmentOnHoldModel[];

      if (holds$?.length > 0) {
        sessionStorage.setItem('appointmentHolds', JSON.stringify(holds$));
        this.router.navigateByUrl('/appointments/order');
      } else {
        this.quantityChangeWarning = true;
        this.siteSelected();
      }
    },
      (err) => {
        this.quantityChangeWarning = true;
        this.siteSelected();
      }
    );
  }
}
