<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div style="
          padding-top: 30px;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 10px;
        ">
        <span [hidden]="true" class="alert">
          <p style="font-size: 18pt">
            <strong>CURRENTLY UNAVAILABLE</strong>
          </p>
        </span>
        <span [innerHTML]="
            domainProductTypeConfigContentModel$?.searchPage | htmlSanitize
          "></span>
      </div>
    </div>
  </div>
</div>
<h2>Schedule an Appointment</h2>
<form [formGroup]="aForm" #formDir="ngForm">
  <div class="container" style="background: #93b4d2; padding: 10px" [hidden]="!(this.products$?.length > 0)">
    <!--<div class="row">
      <div class="col-sm-12">
        <div style="padding-right: 100px">
          <h3>
            <label for="attestation">Attest to Vaccine Eligibility &amp; Risks</label>
          </h3>
          <strong>By selecting yes, you are attesting (verifying)</strong> that
          you meet the current State of Colorado CDPHE guidelines for COVID-19
          vaccine distribution as described above. National Jewish Health is
          required to report vaccine distribution information back to the State
          of Colorado.
          <a href="https://covid19.colorado.gov/for-coloradans/vaccine/vaccine-for-coloradans"
            title="COVID-19 Vaccine Eligibility Guidelines" target="_blank">CDPHE COVID-19 Vaccine Eligibility
            Guidlines</a>
        </div>
        <br />
        <div style="padding-right: 100px">
          <strong>By selecting yes, you are attesting (verifying)</strong> that
          you understand the risks of vaccination as described above and in the
          applicable FDA Fact Sheet above.
        </div>
        <br />
        <select class="form-control" formControlName="attestation" name="attestation" id="attestation"
          style="width: 75%" required (change)="attestationSelected($event.target.value)">
          <option value="">Attest to Vaccine Eligibility &amp; Risks</option>
          <option value="Y">
            Yes, I attest (verify) that I meet the CDPHE guidelines for phased
            vaccine distribution AND understand the risks associated with the
            vaccine and self-referral appointments as described above.
          </option>
          <option value="N">
            No, I am not currently eligible for vaccine based on the CDPHE
            guidelines AND/OR do not understand the risks.
          </option>
        </select>
      </div>
    </div>
    <br /> -->
    <!--<span [hidden]="Attestation?.value !== 'Y'"> -->
    <span>
      <div class="row">
        <div class="col-sm-12">
          <div>
            <h3><label for="product">Available Vaccine</label></h3>
            <select class="form-control" formControlName="product" id="product" style="width: 75%" requiredg
              (change)="productSelected()">
              <option value="">Select a Vaccine</option>
              <option *ngFor="let product of products$; let i = index" [value]="products$[i].product">
                {{ product.productDisplayName }}
              </option>
            </select>
          </div>
          <br />
          <br />
        </div>
      </div>
      <div class="row" [hidden]="!this.getSelectedProduct1()?.productDesc">
        <div class="col-sm-12">
          <div style="padding-right: 100px" [innerHTML]="this.getSelectedProduct1()?.productDesc | htmlSanitize"></div>
        </div>
      </div>
      <div class="row" [hidden]="
          !this.getAvailableSites() || this.getAvailableSites()?.length <= 1
        ">
        <div class="col-sm-12">
          <div>
            <h3>
              <label for="sites">
                Select a location to see appointment times.</label>
            </h3>
            <select class="form-control" formControlName="site" id="site" style="width: 75%" required
              (change)="siteSelected()">
              <option value="0">Select a Site</option>
              <option *ngFor="let site of this.getAvailableSites(); let i = index" [value]="site.siteId">
                {{ site.siteDescription }}
                <span *ngIf="site.futureAppointments <= 0">(Currently Unavailable)</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="
          !this.getSelectedProduct1() ||
          (this.getAvailableSites()?.length > 0 &&
            (this.getSite()?.value == '0' ||
              (this.getSite()?.value !== '0' &&
                this.getIsAvailableAppointmentDates())))
        ">
        <div class="col-sm-12">
          <div>
            <h3><span class="text-danger">(Currently Unavailable)</span></h3>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="!this.getIsAvailableAppointmentDates()">
        <div class="col-sm-12">
          <div>
            <h3>
              <label for="availableDates1">Date &amp; Time </label>
            </h3>
            <select class="form-control" formControlName="availableDates1" id="availableDates1" style="width: 75%"
              required (change)="availableDateSelected1($event.target.value)">
              <option value="">Select a Date</option>
              <option *ngFor="
                  let appointment of availableAppointments1$;
                  let i = index
                " [value]="availableAppointments1$[i].appointmentDate">
                {{ appointment.appointmentDate | date: "MM/dd/yyyy" }}
                <span *ngIf="appointment.numAppointments <= 10">
                  - {{ appointment.numAppointments }} Remaining</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" [hidden]="availableAppointmentsSelectedDate1$?.length <= 0">
        <div class="col-sm-12">
          <div>
            <br />
            <select class="form-control" formControlName="availableTimes1" id="availableTimes1" style="width: 75%"
              required (change)="availableAppointmentSelected1($event.target.value)">
              <option value="">Select a Time</option>
              <option *ngFor="
                  let appointment of availableAppointmentsSelectedDate1$;
                  let i = index
                " [value]="availableAppointmentsSelectedDate1$[i].appointmentId">
                {{ appointment.appointmentTimeDisplay }}
                <span *ngIf="appointment.numAppsAvailable <= 10">
                  - {{ appointment.numAppsAvailable }} Remaining</span>
              </option>
            </select>
          </div>
        </div>
      </div>
      <br />
      <span [hidden]="
          this.availableAppointments1$ &&
          this.availableAppointments1$[0] &&
          this.availableAppointments1$[0].childProduct == ''
        ">
        <div class="row" [hidden]="availableAppointmentsSelectedDate1$?.length <= 0">
          <div class="col-sm-12">
            <div>
              <h3>
                <label for="availableDates2">Date &amp; Time for Second Dose</label>
              </h3>
              <p>
                <strong>Note: Second dose date is automatically calculated to meet
                  vaccination requirements.
                </strong>
              </p>
              <select class="form-control" formControlName="availableDates2" id="availableDates2" style="width: 75%"
                required (change)="availableDateSelected2($event.target.value)">
                <option value="">Select a Date</option>
                <option *ngFor="
                    let appointment of availableAppointments2$;
                    let i = index
                  " [value]="availableAppointments2$[i].appointmentDate">
                  {{ appointment.appointmentDate | date: "MM/dd/yyyy" }}
                  <span *ngIf="appointment.numOpenApptsForDay <= 10">
                    - {{ appointment.numOpenApptsForDay }} Remaining</span>
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" [hidden]="
            availableAppointmentsSelectedDate1$?.length <= 0 ||
            !availableAppointmentsSelectedDate2$
          ">
          <div class="col-sm-12">
            <br />
            <select class="form-control" formControlName="availableTimes2" id="availableTimes2" style="width: 75%"
              required (change)="availableAppointmentSelected2($event.target.value)">
              <option value="">Select a Time</option>
              <option *ngFor="
                  let appointment of availableAppointmentsSelectedDate2$;
                  let i = index
                " [value]="availableAppointmentsSelectedDate2$[i].appointmentId">
                {{ appointment.appointmentTimeDisplay }}
                <span *ngIf="appointment.numAppsAvailable <= 10">
                  - {{ appointment.numAppsAvailable }} Remaining</span>
              </option>
            </select>
          </div>
        </div>
      </span>
    </span>
  </div>
  <br />
  <br />
  <span [hidden]="
      !this.product1 ||
      availableAppointmentsSelectedDate1$?.length <= 0 ||
      !selectedAppointment1 ||
      (!(!this.product1.childProduct && this.product1.childProduct === '') &&
        (!availableAppointmentsSelectedDate2$ || !selectedAppointment2))
    ">
    <br />
    <div class="text-right">
      <button class="btn btn-primary" type="button" (click)="onSubmit()">
        Next
      </button>
    </div>
  </span>
</form>
<div class="text-right text-danger" [hidden]="!this.quantityChangeWarning">
  We apologize, but the appointment time&#40;s&#41; you selected is/are no
  longer available. Please select a new date above.
</div>
<div class="text-right text-danger" [hidden]="!this.dayChangeWarning">
  We apologize, but the appointment time&#40;s&#41; you selected is/are no
  longer available. Please select a new date above.
</div>
<br />
<br />
<br />