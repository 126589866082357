<!--Begin timer code-->
<script type="text/javascript">
  setTimeout(function () {
    document.getElementById("submitButton").disabled = true;
  }, 600000);

  var countdownNum = 600;
  incTimer();

  function incTimer() {
    setTimeout(function () {
      if (countdownNum != 0) {
        countdownNum--;
        document.getElementById("timeLeft").innerHTML =
          "<b>Time left to complete this page: " + +"</b>";
        incTimer();
      } else {
        document.getElementById("timeLeft").innerHTML =
          "<b>Time has expired! Your tickets have been released</b>";
      }
    }, 1000);
  }
</script>
<div
  style="
    background: #ff0000;
    padding: 15px;
    color: #fff;
    position: fixed;
    top: 1em;
    right: 1em;
    z-index: 1;
  "
  id="timeLeft"
>
  <h3 align="center" style="color: black">Time Left: {{ getTimeLeft() }}</h3>
</div>
<!--End timer code-->
<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div
        style="
          padding-top: 30px;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 10px;
        "
      >
        <span [hidden]="this.isVaccine"
          ><h2>
            <strong
              >{{ (this.appointments$?.appointments)[0].productDisplayName }}
              Details -
              {{
                (this.appointments$?.appointments)[0]?.appointmentDate
                  | date: "EEEE, MMMM d, y"
              }}</strong
            >
          </h2>
        </span>
        <span [hidden]="!this.isVaccine"
          ><h2>Complete Your Appointment Details</h2>
          <strong>
            You are booking the
            {{ (this.appointments$?.appointments)[0].productDisplayName }}
          </strong>
          <br />
          <span [hidden]="!this.getChildAppointment()">
            <strong>First dose date and time:</strong>
            {{
              (this.appointments$?.appointments)[0]?.appointmentDate
                | date: "EEEE, MMMM d, y"
            }}
            {{ (this.appointments$?.appointments)[0]?.appointmentTimeDisplay }}
            <br />
            <strong>Second dose date and time:</strong>
            {{
              this.getChildAppointment()?.appointmentDate
                | date: "EEEE, MMMM d, y"
            }}
            {{ this.getChildAppointment()?.appointmentTimeDisplay }}
          </span>
          <span [hidden]="this.getChildAppointment()">
            {{
              (this.appointments$?.appointments)[0]?.appointmentDate
                | date: "EEEE, MMMM d, y"
            }}
            {{ (this.appointments$?.appointments)[0]?.appointmentTimeDisplay }}
          </span>
          <br />
        </span>

        <div>
          <p [hidden]="isVaccine">
            Please enter the information below for each test.
          </p>
          <p [hidden]="isVaccine">
            <strong>Note: </strong>If the person being tested is under 18, enter
            the email address of the authorized representative (parent,
            guardian, etc.).
          </p>
          <p>&nbsp;</p>
          <a (click)="openReleaseHoldModal(releaseHoldModalRef)"
            >Change Selected Date</a
          >
          <ng-template #releaseHoldModalRef>
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" (click)="decline()">
                  &times;
                </button>
                <h4 class="modal-title">Release Hold on Appointments</h4>
              </div>
              <div class="modal-body">
                <p>
                  Changing dates will release your hold on these appointment
                  times? Do you want to change your dates and release your hold
                  on these appointments?
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="releaseAppointmentHolds(true)"
                >
                  Release Hold
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="decline()"
                >
                  No
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="aForm" #formDir="ngForm">
  <!--replicate this section for to match the number of tickets the user selected on the first page-->
  <div class="container" formArrayName="appointments">
    <span
      *ngFor="
        let appt of aForm.get('appointments')['controls'];
        let appointmentIndex = index
      "
      [formGroupName]="appointmentIndex"
    >
      <div class="container" style="padding-right: 100px">
        <div class="form-row">
          <div class="col-sm-6">
            <h3>
              Patient Information
              <span [hidden]="this.isVaccine">
                - Test {{ appointmentIndex + 1 }} -
                {{
                  this.appointments$.appointments[appointmentIndex]
                    .appointmentTimeDisplay
                }}
              </span>
            </h3>
            <span [hidden]="isVaccine">
              <strong>
                (If you have a patient account, please use the same first and
                last name as it appears on that account so that your results are
                delivered as soon as possible.)
              </strong>
            </span>
            <span [hidden]="!isVaccine">
              <strong
                >Please Note: If you are under 18 years of age, please provide
                an email address for your parent or guardian below. You MUST be
                accompanied by a parent or guardian to all vaccination
                appointments.</strong
              >
            </span>
            <div class="row">

              <div class="col-sm-5 name-larger">
                <br />
                <label for="firstName"
                  >Legal First Name<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls.firstName
                          .invalid &&
                          !getAppointment(appointmentIndex).controls.firstName
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.firstName
                            .errors?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.firstName
                            .errors?.maxlength
                        "
                        >Max Length 20.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls.firstName
                            .errors?.maxlength &&
                          getAppointment(appointmentIndex).controls.firstName
                            .errors?.pattern
                        "
                        >Letters Only.</span
                      >
                    </span>
                  </span>
                </label>
                <input
                  formControlName="firstName"
                  name="firstName"
                  id="firstName"
                  type="text"
                  class="form-control"
                  required
                  maxlength="20"
                  placeholder="Legal First Name"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.firstName
                        .invalid && formDir.submitted
                  }"
                />
              </div>

              <div class="col-sm-5 name-larger">
                <br />
                <label for="lastName"
                  >Legal Last Name<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls.lastName
                          .invalid &&
                          !getAppointment(appointmentIndex).controls.lastName
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.lastName
                            .errors?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.lastName
                            .errors?.maxlength
                        "
                        >Max Length 27.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls.lastName
                            .errors?.maxlength &&
                          getAppointment(appointmentIndex).controls.lastName
                            .errors?.pattern
                        "
                      >
                        Letters Only
                      </span>
                    </span>
                  </span></label
                >
                <input
                  formControlName="lastName"
                  name="lastName"
                  id="lastName"
                  type="text"
                  class="form-control"
                  required
                  maxlength="27"
                  placeholder="Legal Last Name"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.lastName
                        .invalid && formDir.submitted
                  }"
                />
              </div>
              <div class="col-sm-2 name-larger">
                <br />
                <label for="middleInitial"
                  >MI<span class="text-danger">
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls.middleInitial
                          .invalid &&
                          !getAppointment(appointmentIndex).controls
                            .middleInitial.pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .middleInitial.errors?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .middleInitial.errors?.maxlength
                        "
                        >Max Length 1.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls
                            .middleInitial.errors?.maxlength &&
                          getAppointment(appointmentIndex).controls
                            .middleInitial.errors?.pattern
                        "
                        >Letters Only.</span
                      >
                    </span>
                  </span>
                </label>
                <input
                  formControlName="middleInitial"
                  name="middleInitial"
                  id="middleInitial"
                  type="text"
                  class="form-control"
                  width
                  maxlength="1"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.middleInitial
                        .invalid && formDir.submitted
                  }"
                />
              </div>
              
            </div>
            <br />
            <div class="row">
              <div class="col-sm-6">
                <label for="preferredFirstName">
                  Preferred First Name
                  <span class="text-danger">
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls
                          .preferredFirstName.invalid &&
                          !getAppointment(appointmentIndex).controls
                            .preferredFirstName.pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .preferredFirstName.errors?.maxlength
                        "
                        >Max Length 20.</span
                      >
                    </span>
                  </span>
                </label>
                <input
                  formControlName="preferredFirstName"
                  name="preferredFirstName"
                  id="preferredFirstName"
                  type="text"
                  class="form-control"
                  maxlength="20"
                  placeholder="Preferred First Name"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls
                        .preferredFirstName.invalid && formDir.submitted
                  }"
                />
              </div>

              <div class="col-sm-6">
                <label for="preferredLastName">
                  Preferred Last Name
                  <span class="text-danger">
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls
                          .preferredLastName.invalid &&
                          !getAppointment(appointmentIndex).controls
                            .preferredLastName.pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .preferredLastName.errors?.maxlength
                        "
                        >Max Length 27.</span
                      >
                    </span>
                  </span>
                </label>
                <input
                  formControlName="preferredLastName"
                  name="preferredLastName"
                  id="preferredLastName"
                  type="text"
                  class="form-control"
                  maxlength="27"
                  placeholder="Preferred Last Name"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls
                        .preferredLastName.invalid && formDir.submitted
                  }"
                />
              </div>
              
            </div>
            <br />
            <label for="mailingAddress"
              >Mailing Address<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getAppointment(appointmentIndex).controls.mailingAddress
                      .invalid &&
                      !getAppointment(appointmentIndex).controls.mailingAddress
                        .pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.mailingAddress
                        .errors?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.mailingAddress
                        .errors?.maxlength
                    "
                    >Max Length 50.</span
                  >
                </span>
              </span></label
            >
            <span [hidden]="appointmentIndex <= 0">
              &nbsp;&nbsp;&nbsp; Same as Patient Info for Test 1
              <input
                type="radio"
                value="yes"
                formControlName="sameContact"
                name="sameContact"
                id="sameContact"
                (change)="patientSameMailingAddressChanged(appointmentIndex)"
              />
              &nbsp;&nbsp;&nbsp; Not the Same
              <input
                type="radio"
                value="no"
                formControlName="sameContact"
                name="sameContact"
                id="sameContact"
                (change)="patientSameMailingAddressChanged(appointmentIndex)"
              />
            </span>

            <span
              [hidden]="
                getAppointment(appointmentIndex).controls.sameContact.value ===
                'yes'
              "
            >
              <div class="row">
                <div class="col-sm-12">
                  <input
                    formControlName="mailingAddress"
                    name="mailingAddress"
                    id="mailingAddress"
                    type="text"
                    class="form-control"
                    required
                    maxlength="50"
                    placeholder="Address"
                    [ngClass]="{
                      'is-invalid':
                        getAppointment(appointmentIndex).controls.mailingAddress
                          .invalid && formDir.submitted
                    }"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <br />
                  <label for="mailingCity"
                    >City<span class="text-danger"
                      >*
                      <span
                        *ngIf="
                          (getAppointment(appointmentIndex).controls.mailingCity
                            .invalid &&
                            !getAppointment(appointmentIndex).controls
                              .mailingCity.pristine) ||
                          formDir.submitted
                        "
                      >
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls
                              .mailingCity.errors?.required
                          "
                          >Required.</span
                        >
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls
                              .mailingCity.errors?.maxlength
                          "
                          >Max Length 50.</span
                        >
                      </span>
                    </span></label
                  >
                  <input
                    formControlName="mailingCity"
                    name="mailingCity"
                    id="mailingCity"
                    type="text"
                    class="form-control"
                    required
                    placeholder="City"
                    maxlength="50"
                    [ngClass]="{
                      'is-invalid':
                        getAppointment(appointmentIndex).controls.mailingCity
                          .invalid && formDir.submitted
                    }"
                  />
                </div>
                <div class="col-sm-6">
                  <br />
                  <label for="mailingState"
                    >State<span class="text-danger"
                      >*
                      <span
                        *ngIf="
                          (getAppointment(appointmentIndex).controls
                            .mailingState.invalid &&
                            !getAppointment(appointmentIndex).controls
                              .mailingState.pristine) ||
                          formDir.submitted
                        "
                      >
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls
                              .mailingState.errors?.required
                          "
                          >Required.</span
                        >
                      </span>
                    </span></label
                  >
                  <select
                    class="form-control"
                    formControlName="mailingState"
                    name="mailingState"
                    id="mailingState"
                    required
                    placeholder="state"
                    [ngClass]="{
                      'is-invalid':
                        getAppointment(appointmentIndex).controls.mailingState
                          .invalid && formDir.submitted
                    }"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let state of domainStateModel$; let i = index"
                      [value]="domainStateModel$[i].state"
                    >
                      {{ state.stateDesc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <br />
                  <label for="mailingZipCode"
                    >Zip<span class="text-danger"
                      >*
                      <span
                        *ngIf="
                          (getAppointment(appointmentIndex).controls
                            .mailingZipCode.invalid &&
                            !getAppointment(appointmentIndex).controls
                              .mailingZipCode.pristine) ||
                          formDir.submitted
                        "
                      >
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls
                              .mailingZipCode.errors?.required
                          "
                          >Required.</span
                        >
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls
                              .mailingZipCode.errors?.maxlength
                          "
                          >Max Length 10.</span
                        >
                      </span>
                    </span></label
                  >
                  <input
                    formControlName="mailingZipCode"
                    name="mailingZipCode"
                    id="mailingZipCode"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Zip"
                    mask="00000"
                    [ngClass]="{
                      'is-invalid':
                        getAppointment(appointmentIndex).controls.mailingZipCode
                          .invalid && formDir.submitted
                    }"
                  />
                </div>
                <div class="col-sm-6">
                  <br />
                  <label for="mailingCounty"
                    >County<span class="text-danger">
                      *&nbsp;
                      <span
                        *ngIf="
                          (getAppointment(appointmentIndex).controls
                            .mailingCounty.invalid &&
                            !getAppointment(appointmentIndex).controls
                              .mailingCounty.pristine) ||
                          formDir.submitted
                        "
                      >
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls
                              .mailingCounty.errors?.required
                          "
                          >Required.</span
                        >
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls
                              .mailingCounty.errors?.maxlength
                          "
                          >Max Length 50.</span
                        >
                      </span>
                    </span></label
                  >
                  <input
                    formControlName="mailingCounty"
                    name="mailingCounty"
                    id="mailingCounty"
                    type="text"
                    class="form-control"
                    required
                    placeholder="County"
                    maxlength="50"
                    [ngClass]="{
                      'is-invalid':
                        getAppointment(appointmentIndex).controls.mailingCounty
                          .invalid && formDir.submitted
                    }"
                  />
                </div>
              </div>
            </span>
            <br />
            <label for="phone"
              >Phone
              <span [hidden]="!isVaccine"
                >(Number of parent/guardian if under 18)</span
              >
              <span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getAppointment(appointmentIndex).controls.phone.invalid &&
                      !getAppointment(appointmentIndex).controls.phone
                        .pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.phone.errors
                        ?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.phone.errors
                        ?.maxlength
                    "
                    >Max Length 12.</span
                  >
                </span>
              </span></label
            >
            <input
              formControlName="phone"
              name="phone"
              id="phone"
              type="text"
              class="form-control"
              required
              maxlength="20"
              mask="(000) 000-0000"
              [showMaskTyped]="true"
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.phone.invalid &&
                  formDir.submitted
              }"
            />
            <br />
            <label for="email"
              >Email
              <span [hidden]="isVaccine">
                (Email of parent/guardian if under 18)</span
              >
              <span [hidden]="!isVaccine"
                >(Email of parent/guardian if under 18)</span
              >
              <span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getAppointment(appointmentIndex).controls.email.invalid &&
                      !getAppointment(appointmentIndex).controls.email
                        .pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.email.errors
                        ?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.email.errors
                        ?.maxlength
                    "
                    >Max Length 20.</span
                  >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.email.errors
                        ?.email ||
                      getAppointment(appointmentIndex).controls.email.errors
                        ?.pattern
                    "
                    >Must be a valid email.</span
                  >
                </span>
              </span></label
            >
            <input
              formControlName="email"
              name="email"
              id="email"
              type="email"
              class="form-control"
              required
              maxlength="50"
              title="If the person being tested is under 18, enter the email address of the authorized representative (parent, guardian, etc.)"
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.email.invalid &&
                  formDir.submitted
              }"
            />
            <br />
            <label for="emailConfirmation"
              >Email Confirmation<span class="text-danger"
                >*
                <span
                  *ngIf="
                    ((getAppointment(appointmentIndex).controls
                      .emailConfirmation.invalid ||
                      getAppointment(appointmentIndex).errors?.IsMatch) &&
                      !getAppointment(appointmentIndex).controls
                        .emailConfirmation.pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls
                        .emailConfirmation.errors?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      !getAppointment(appointmentIndex).controls
                        .emailConfirmation.errors?.required &&
                      getAppointment(appointmentIndex).errors?.IsMatch
                    "
                    >Emails must match.</span
                  >
                </span>
              </span></label
            >
            <input
              autocomplete="nope"
              formControlName="emailConfirmation"
              name="emailConfirmation"
              id="emailConfirmation"
              type="email"
              class="form-control"
              required
              onpaste="return false;"
              maxlength="50"
              title="Re-enter email address"
              [ngClass]="{
                'is-invalid':
                  (getAppointment(appointmentIndex).controls.emailConfirmation
                    .invalid ||
                    getAppointment(appointmentIndex).errors?.IsMatch) &&
                  formDir.submitted
              }"
            />
            <span
              [hidden]="
                this.appointments$.appointments[
                  appointmentIndex
                ].insuranceRequired?.toLowerCase() !== 'y' ||
                this.appointments$.appointments[appointmentIndex].parentProduct
              "
            >
              <br />
              <h3>Insurance Information</h3>
              <strong>
                <span class="text-danger">
                  *
                  <span
                    *ngIf="
                      (getAppointment(appointmentIndex).controls.hasInsurance
                        .invalid &&
                        !getAppointment(appointmentIndex).controls.hasInsurance
                          .pristine) ||
                      formDir.submitted
                    "
                  >
                    <span
                      *ngIf="
                        getAppointment(appointmentIndex).controls.hasInsurance
                          .errors?.required
                      "
                      >Required.</span
                    >
                  </span>
                </span>
                <br />
                <input
                  type="radio"
                  name="hasInsurance"
                  value="yes"
                  formControlName="hasInsurance"
                  id="hasInsurance"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.hasInsurance
                        .invalid && formDir.submitted
                  }"
                  (change)="patientInsuranceChanged(appointmentIndex)"
                />
                Yes, I will provide insurance information
                <br />
                <input
                  type="radio"
                  name="hasInsurance"
                  value="no"
                  id="hasInsurance"
                  maxlength="30"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.hasInsurance
                        .invalid && formDir.submitted
                  }"
                  (change)="patientInsuranceChanged(appointmentIndex)"
                />
                No, I do not have insurance
              </strong>
              <span
                [hidden]="
                  getAppointment(appointmentIndex).controls.hasInsurance
                    .value !== 'yes'
                "
              >
                <br />
                <label for="primaryInsuranceCo"
                  >Primary Insurance<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls
                          .primaryInsuranceCo.invalid &&
                          !getAppointment(appointmentIndex).controls
                            .primaryInsuranceCo.pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .primaryInsuranceCo.errors?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .primaryInsuranceCo.errors?.maxlength
                        "
                        >Max Length 30.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls
                            .primaryInsuranceCo.errors?.maxlength &&
                          getAppointment(appointmentIndex).controls
                            .primaryInsuranceCo.errors?.pattern
                        "
                        >Letters Only.</span
                      >
                    </span>
                  </span></label
                >
                <input
                  formControlName="primaryInsuranceCo"
                  name="primaryInsuranceCo"
                  id="primaryInsuranceCo"
                  type="text"
                  class="form-control"
                  required
                  maxlength="30"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls
                        .primaryInsuranceCo.invalid && formDir.submitted
                  }"
                />
                <br />
                <label for="policyHolderName"
                  >PolicyHolderName<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls
                          .policyHolderName.invalid &&
                          !getAppointment(appointmentIndex).controls
                            .policyHolderName.pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .policyHolderName.errors?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls
                            .policyHolderName.errors?.maxlength
                        "
                        >Max Length 24.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls
                            .policyHolderName.errors?.maxlength &&
                          getAppointment(appointmentIndex).controls
                            .policyHolderName.errors?.pattern
                        "
                        >Letters Only.</span
                      >
                    </span>
                  </span></label
                >
                <input
                  formControlName="policyHolderName"
                  name="policyHolderName"
                  id="policyHolderName"
                  type="text"
                  class="form-control"
                  required
                  maxlength="24"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.policyHolderName
                        .invalid && formDir.submitted
                  }"
                />
                <br />
                <label for="memberId"
                  >Member Id<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls.memberId
                          .invalid &&
                          !getAppointment(appointmentIndex).controls.memberId
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.memberId
                            .errors?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.memberId
                            .errors?.maxlength
                        "
                        >Max Length 30.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls.memberId
                            .errors?.maxlength &&
                          getAppointment(appointmentIndex).controls.memberId
                            .errors?.pattern
                        "
                        >Alphanumeric Only.</span
                      >
                    </span>
                  </span></label
                >
                <input
                  formControlName="memberId"
                  name="memberId"
                  id="memberId"
                  type="text"
                  class="form-control"
                  required
                  maxlength="30"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.memberId
                        .invalid && formDir.submitted
                  }"
                />
                <br />
                <label for="groupId"
                  >Group Id<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getAppointment(appointmentIndex).controls.groupId
                          .invalid &&
                          !getAppointment(appointmentIndex).controls.groupId
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.groupId
                            .errors?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.groupId
                            .errors?.maxlength
                        "
                        >Max Length 30.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls.groupId
                            .errors?.maxlength &&
                          getAppointment(appointmentIndex).controls.groupId
                            .errors?.pattern
                        "
                        >Alphanumeric Only.</span
                      >
                    </span>
                  </span></label
                >
                <input
                  formControlName="groupId"
                  name="groupId"
                  id="groupId"
                  type="text"
                  class="form-control"
                  required
                  maxlength="30"
                  [ngClass]="{
                    'is-invalid':
                      getAppointment(appointmentIndex).controls.groupId
                        .invalid && formDir.submitted
                  }"
                />
              </span>
            </span>
          </div>
          <div class="col-sm-6">
            <label for="dob"
              >Date of Birth<span class="text-danger"
                >*
                <span
                  *ngIf="
                    getAppointment(appointmentIndex).controls.dob.invalid &&
                    !getAppointment(appointmentIndex).controls.dob.pristine
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.dob.errors
                        ?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      !getAppointment(appointmentIndex).controls.dob.errors
                        ?.required
                    "
                  >
                    <span
                      *ngIf="
                        getAppointment(appointmentIndex).controls.dob.errors
                          ?.pattern
                      "
                      >Format must be MM/dd/yyyy</span
                    >
                    <span
                      *ngIf="
                        !getAppointment(appointmentIndex).controls.dob.errors
                          ?.pattern
                      "
                    >
                      <span
                        *ngIf="
                          getAppointment(appointmentIndex).controls.dob.errors
                            ?.IsValidUSDateValidator
                        "
                        >Must be a valid date.</span
                      >
                      <span
                        *ngIf="
                          !getAppointment(appointmentIndex).controls.dob.errors
                            ?.IsValidUSDateValidator
                        "
                      >
                        <span
                          *ngIf="this.appointments$.appointments[0].minimumAge >= 1 &&
                            getAppointment(appointmentIndex).controls.dob.errors
                              ?.MinAgeFromDateValidator
                          "
                          >Sorry, you must be aged
                          {{ this.appointments$.appointments[0].minimumAge }} or
                          older to make this appointment.</span
                        >
                        <span
                          *ngIf="this.appointments$.appointments[0].minimumAge < 1 &&
                            getAppointment(appointmentIndex).controls.dob.errors
                              ?.MinAgeFromDateValidator
                          "
                          >Sorry, you must be aged
                          {{ (this.appointments$.appointments[0].minimumAge * 12) }} months or
                          older to make this appointment.</span
                        >
                      
                        <span
                          *ngIf="
                            getAppointment(appointmentIndex).controls.dob.errors
                              ?.MaxAgeFromDateValidator
                          "
                          >Sorry, you must be age
                          {{ this.appointments$.appointments[0].maxAge }} or
                          younger to make this appointment.</span
                        >
                      </span>
                    </span>
                  </span>
                </span>
                <br />
              </span>
              <span [hidden]="isVaccine">
                (Test is not available without a physician appointment for
                patients under
                {{ this.appointments$.appointments[0].minimumAge }} years old)
              </span>
            </label>
            <input
              formControlName="dob"
              name="dob"
              id="dob"
              type="text"
              class="form-control"
              required
              placeholder="mm/dd/yyyy"
              mask="00/00/0000"
              [dropSpecialCharacters]="false"
              [showMaskTyped]="true"
              (input)="dobChanged(appointmentIndex)"
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.dob.invalid &&
                  formDir.submitted
              }"
            />
            <br />
            <span
              [hidden]="
                !this.appointments$.appointments[appointmentIndex]
                  .requireGuardian
              "
            >
              <label for="guardianType"
                >Guardian Type<span class="text-danger"
                  >*
                  <span
                    *ngIf="
                      (getAppointment(appointmentIndex).controls.guardianType
                        .invalid &&
                        !getAppointment(appointmentIndex).controls.guardianType
                          .pristine) ||
                      formDir.submitted
                    "
                  >
                    <span
                      *ngIf="
                        getAppointment(appointmentIndex).controls.guardianType
                          .errors?.required
                      "
                      >Required.</span
                    >
                  </span>
                </span>
              </label>
              <select
                class="form-control"
                formControlName="guardianType"
                name="guardianType"
                id="guardianType"
                required
                [ngClass]="{
                  'is-invalid':
                    getAppointment(appointmentIndex).controls.guardianType
                      .invalid && formDir.submitted
                }"
              >
                <option value=""></option>
                <option
                  *ngFor="let guardian of domainGuardianModel$; let i = index"
                  [value]="domainGuardianModel$[i].guardianType"
                >
                  {{ guardian.guardianType }}
                </option>
              </select>
              <br />
              <label for="guardianFirstName"
                >Guardian First Name<span class="text-danger"
                  >*
                  <span
                    *ngIf="
                      (getAppointment(appointmentIndex).controls
                        .guardianFirstName.invalid &&
                        !getAppointment(appointmentIndex).controls
                          .guardianFirstName.pristine) ||
                      formDir.submitted
                    "
                  >
                    <span
                      *ngIf="
                        getAppointment(appointmentIndex).controls
                          .guardianFirstName.errors?.required
                      "
                      >Required.</span
                    >
                    <span
                      *ngIf="
                        getAppointment(appointmentIndex).controls
                          .guardianFirstName.errors?.maxlength
                      "
                      >Max Length 20.</span
                    >
                    <span
                      *ngIf="
                        !getAppointment(appointmentIndex).controls
                          .guardianFirstName.errors?.maxlength &&
                        getAppointment(appointmentIndex).controls
                          .guardianFirstName.errors?.pattern
                      "
                      >Letters Only.</span
                    >
                  </span>
                </span>
              </label>
              <input
                formControlName="guardianFirstName"
                name="guardianFirstName"
                id="guardianFirstName"
                type="text"
                class="form-control"
                required
                maxlength="20"
                [ngClass]="{
                  'is-invalid':
                    getAppointment(appointmentIndex).controls.guardianFirstName
                      .invalid && formDir.submitted
                }"
              />
              <label for="guardianLastName"
                >Guardian Last Name<span class="text-danger"
                  >*
                  <span
                    *ngIf="
                      (getAppointment(appointmentIndex).controls
                        .guardianLastName.invalid &&
                        !getAppointment(appointmentIndex).controls
                          .guardianLastName.pristine) ||
                      formDir.submitted
                    "
                  >
                    <span
                      *ngIf="
                        getAppointment(appointmentIndex).controls
                          .guardianLastName.errors?.required
                      "
                      >Required.</span
                    >
                    <span
                      *ngIf="
                        getAppointment(appointmentIndex).controls
                          .guardianLastName.errors?.maxlength
                      "
                      >Max Length 27.</span
                    >
                    <span
                      *ngIf="
                        !getAppointment(appointmentIndex).controls
                          .guardianLastName.errors?.maxlength &&
                        getAppointment(appointmentIndex).controls
                          .guardianLastName.errors?.pattern
                      "
                      >Letters Only.</span
                    >
                  </span>
                </span>
              </label>
              <input
                formControlName="guardianLastName"
                name="guardianLastName"
                id="guardianLastName"
                type="text"
                class="form-control"
                required
                maxlength="27"
                [ngClass]="{
                  'is-invalid':
                    getAppointment(appointmentIndex).controls.guardianLastName
                      .invalid && formDir.submitted
                }"
              />
            </span>
            <br />
            <label for="sexAtBirth"
              >Gender (Sex at Birth)<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getAppointment(appointmentIndex).controls.sexAtBirth
                      .invalid &&
                      !getAppointment(appointmentIndex).controls.sexAtBirth
                        .pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.sexAtBirth
                        .errors?.required
                    "
                    >Required.</span
                  >
                </span>
              </span>
            </label>
            <select
              class="form-control"
              formControlName="sexAtBirth"
              name="sexAtBirth"
              id="sexAtBirth"
              required
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.sexAtBirth
                    .invalid && formDir.submitted
              }"
            >
              <option value=""></option>
              <option
                *ngFor="let sexAtBirth of domainSexAtBirthModel$; let i = index"
                [value]="domainSexAtBirthModel$[i].sexAtBirth"
              >
                {{ sexAtBirth.sexAtBirth }}
              </option>
            </select>
            <br />
            <label for="genderIdentity"
              >Gender Identity<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getAppointment(appointmentIndex).controls.genderIdentity
                      .invalid &&
                      !getAppointment(appointmentIndex).controls.genderIdentity
                        .pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.genderIdentity
                        .errors?.required
                    "
                    >Required.</span
                  >
                </span>
              </span></label
            >
            <select
              class="form-control"
              formControlName="genderIdentity"
              name="genderIdentity"
              id="genderIdentity"
              required
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.genderIdentity
                    .invalid && formDir.submitted
              }"
            >
              <option value=""></option>
              <option
                *ngFor="
                  let genderIdentity of domainGenderIdentityModel$;
                  let i = index
                "
                [value]="domainGenderIdentityModel$[i].genderIdentity"
              >
                {{ genderIdentity.genderIdentity }}
              </option>
            </select>
            <br />
            <label for="race"
              >Race<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getAppointment(appointmentIndex).controls.race.invalid &&
                      !getAppointment(appointmentIndex).controls.race
                        .pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.race.errors
                        ?.required
                    "
                    >Required.</span
                  >
                </span>
              </span></label
            >
            <select
              class="form-control"
              formControlName="race"
              name="race"
              id="race"
              required
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.race.invalid &&
                  formDir.submitted
              }"
            >
              <option value=""></option>
              <option
                *ngFor="let race of domainRaceModel$; let i = index"
                [value]="domainRaceModel$[i].race"
              >
                {{ race.race }}
              </option>
            </select>
            <br />
            <label for="ethnicity"
              >Ethnicity<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getAppointment(appointmentIndex).controls.ethnicity
                      .invalid &&
                      !getAppointment(appointmentIndex).controls.ethnicity
                        .pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getAppointment(appointmentIndex).controls.ethnicity.errors
                        ?.required
                    "
                    >Required.</span
                  >
                </span>
              </span></label
            >
            <select
              class="form-control"
              formControlName="ethnicity"
              name="ethnicity"
              id="ethnicity"
              required
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.ethnicity.invalid &&
                  formDir.submitted
              }"
            >
              <option value=""></option>
              <option
                *ngFor="let ethnicity of domainEthnicityModel$; let i = index"
                [value]="domainEthnicityModel$[i].ethnicity"
              >
                {{ ethnicity.ethnicity }}
              </option>
            </select>
            <br />
            <label for="last4SSN"
              >Last 4 of Social Security Number
              <span
                *ngIf="
                  (getAppointment(appointmentIndex).controls.last4SSN.invalid &&
                    !getAppointment(appointmentIndex).controls.last4SSN
                      .pristine) ||
                  formDir.submitted
                "
              >
                <span
                  *ngIf="
                    getAppointment(appointmentIndex).controls.last4SSN.errors
                      ?.maxlength
                  "
                  >Max length 4.</span
                >
              </span>
            </label>
            <input
              formControlName="last4SSN"
              name="last4SSN"
              id="last4SSN"
              type="text"
              class="form-control"
              maxlength="4"
              mask="9999"
            />
            <br />
            <span [hidden]="!this.isVaccine">
              You do not need to be a U.S. citizen, and you will not need to
              prove lawful presence to get a COVID-19 vaccine in Colorado. If
              you have an SSN we strongly encourage you to provide the last 4
              digits so we may prevent the creation of duplicate medical records
              and ensure safe patient care.
              <br />
            </span>
            <label
              class="form-check-label"
              for="printResults"
              [hidden]="isVaccine"
            >
              In addition to receiving my results online, please send my results
              in the mail.
            </label>
            &nbsp;
            <input
              [hidden]="isVaccine"
              formControlName="printResults"
              name="printResults"
              id="printResults"
              class="form-check-input"
              type="checkbox"
              value="Y"
            />
          </div>
        </div>
      </div>

      <hr
        style="height: 2px"
        [hidden]="
          this.appointments$.appointments[appointmentIndex].parentProduct
        "
      />
      <p
        [hidden]="
          this.appointments$.appointments[appointmentIndex].parentProduct
        "
      >
        &nbsp;
      </p>
    </span>
  </div>

  

  <!--End replicated section-->

  <span [hidden]="!this.isVaccine">

    <app-dynamic-form [formName]="'Registration'" [singleUse]="this.appointments$?.singleUseFlag" [employer]="this.appointments$?.employer"></app-dynamic-form>
  </span>



  <!--Payment detail-->
  <div class="container" formGroupName="paymentInformation">
    <div class="form-row">
      <div class="col-sm-6">
        <span [hidden]="subTotal <= 0">
          <h3>Payment Information</h3>
          <div role="radiogroup" aria-labelledby="sameAsPatient">
            Same as Patient Info for Test 1
            <input
              type="radio"
              name="sameContact"
              value="yes"
              formControlName="sameContact"
              id="sameContact"
              (change)="samePaymentChanged()"
            />
            &nbsp;&nbsp;&nbsp; Not the Same
            <input
              type="radio"
              name="sameContact"
              value="no"
              formControlName="sameContact"
              id="sameContact"
              (change)="samePaymentChanged()"
            />
          </div>
          <br />
          <span
            [hidden]="getPaymentInfo().controls.sameContact.value === 'yes'"
          >
            <label for="billingFirstName"
              >First Name<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getPaymentInfo().controls.billingFirstName.invalid &&
                      !getPaymentInfo().controls.billingFirstName.pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingFirstName.errors
                        ?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingFirstName.errors
                        ?.maxlength
                    "
                    >Max Length 50.</span
                  >
                  <span
                    *ngIf="
                      !getPaymentInfo().controls.billingFirstName.errors
                        ?.maxlength &&
                      getPaymentInfo().controls.billingFirstName.errors?.pattern
                    "
                    >Letters Only.</span
                  >
                </span>
              </span></label
            >
            <input
              formControlName="billingFirstName"
              name="billingFirstName"
              id="billingFirstName"
              type="text"
              class="form-control"
              required
              maxlength="50"
              [ngClass]="{
                'is-invalid':
                  getPaymentInfo().controls.billingFirstName.invalid &&
                  formDir.submitted
              }"
            />
            <br />
            <label for="billingLastName"
              >Last Name<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getPaymentInfo().controls.billingLastName.invalid &&
                      !getPaymentInfo().controls.billingLastName.pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingLastName.errors?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingLastName.errors
                        ?.maxlength
                    "
                    >Max Length 50.</span
                  >
                  <span
                    *ngIf="
                      !getPaymentInfo().controls.billingLastName.errors
                        ?.maxlength &&
                      getPaymentInfo().controls.billingLastName.errors?.pattern
                    "
                    >Letters Only.</span
                  >
                </span>
              </span></label
            >
            <input
              formControlName="billingLastName"
              name="billingLastName"
              id="billingLastName"
              type="text"
              class="form-control"
              required
              maxlength="50"
              [ngClass]="{
                'is-invalid':
                  getPaymentInfo().controls.billingLastName.invalid &&
                  formDir.submitted
              }"
            />
            <br />
            <div class="row">
              <div class="col-sm-12">
                <label for="billingMailingAddress"
                  >Address<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getPaymentInfo().controls.billingMailingAddress
                          .invalid &&
                          !getPaymentInfo().controls.billingMailingAddress
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getPaymentInfo().controls.billingMailingAddress.errors
                            ?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getPaymentInfo().controls.billingMailingAddress.errors
                            ?.maxlength
                        "
                        >Max Length 50.</span
                      >
                    </span>
                  </span></label
                >
                <input
                  formControlName="billingMailingAddress"
                  name="billingMailingAddress"
                  id="billingMgailingAddress"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Address"
                  maxlength="50"
                  [ngClass]="{
                    'is-invalid':
                      getPaymentInfo().controls.billingMailingAddress.invalid &&
                      formDir.submitted
                  }"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                <br />
                <label for="billingMailingCity"
                  >City<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getPaymentInfo().controls.billingMailingCity.invalid &&
                          !getPaymentInfo().controls.billingMailingCity
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getPaymentInfo().controls.billingMailingCity.errors
                            ?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getPaymentInfo().controls.billingMailingCity.errors
                            ?.maxlength
                        "
                        >Max Length 50.</span
                      >
                    </span>
                  </span></label
                >
                <input
                  formControlName="billingMailingCity"
                  name="billingMailingCity"
                  id="billingMailingCity"
                  type="text"
                  class="form-control"
                  required
                  maxlength="50"
                  [ngClass]="{
                    'is-invalid':
                      getPaymentInfo().controls.billingMailingCity.invalid &&
                      formDir.submitted
                  }"
                />
              </div>
              <div class="col-sm-4">
                <br />
                <label for="billingMailingState"
                  >State<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getPaymentInfo().controls.billingMailingState
                          .invalid &&
                          !getPaymentInfo().controls.billingMailingState
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getPaymentInfo().controls.billingMailingState.errors
                            ?.required
                        "
                        >Required.</span
                      >
                    </span>
                  </span></label
                >
                <select
                  class="form-control"
                  formControlName="billingMailingState"
                  name="billingMailingState"
                  id="billingMailingState"
                  required
                  [ngClass]="{
                    'is-invalid':
                      getPaymentInfo().controls.billingMailingState.invalid &&
                      formDir.submitted
                  }"
                >
                  <option value=""></option>
                  <option
                    *ngFor="let state of domainStateModel$; let i = index"
                    [value]="domainStateModel$[i].state"
                  >
                    {{ state.stateDesc }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <br />
                <label for="billingMailingZipCode"
                  >Zip<span class="text-danger"
                    >*
                    <span
                      *ngIf="
                        (getPaymentInfo().controls.billingMailingZipCode
                          .invalid &&
                          !getPaymentInfo().controls.billingMailingZipCode
                            .pristine) ||
                        formDir.submitted
                      "
                    >
                      <span
                        *ngIf="
                          getPaymentInfo().controls.billingMailingZipCode.errors
                            ?.required
                        "
                        >Required.</span
                      >
                      <span
                        *ngIf="
                          getPaymentInfo().controls.billingMailingZipCode.errors
                            ?.maxlength
                        "
                        >Max Length 10.</span
                      >
                    </span>
                  </span></label
                >
                <input
                  formControlName="billingMailingZipCode"
                  name="billingMailingZipCode"
                  id="billingMailingZipCode"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Zip"
                  mask="00000"
                  [ngClass]="{
                    'is-invalid':
                      getPaymentInfo().controls.billingMailingZipCode.invalid &&
                      formDir.submitted
                  }"
                />
              </div>
            </div>
            <br />
            <label for="billingPhone"
              >Phone<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getPaymentInfo().controls.billingPhone.invalid &&
                      !getPaymentInfo().controls.billingPhone.pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingPhone.errors?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingPhone.errors?.maxlength
                    "
                    >Max Length 50.</span
                  >
                </span>
              </span></label
            >
            <input
              formControlName="billingPhone"
              name="billingPhone"
              id="billingPhone"
              type="text"
              class="form-control"
              required
              maxlength="20"
              mask="(000) 000-0000"
              [showMaskTyped]="true"
              [ngClass]="{
                'is-invalid':
                  getPaymentInfo().controls.billingPhone.invalid &&
                  formDir.submitted
              }"
            />
            <br />
            <label for="billingEmail"
              >Email<span class="text-danger"
                >*
                <span
                  *ngIf="
                    (getPaymentInfo().controls.billingEmail.invalid &&
                      !getPaymentInfo().controls.billingEmail.pristine) ||
                    formDir.submitted
                  "
                >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingEmail.errors?.required
                    "
                    >Required.</span
                  >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingEmail.errors?.maxlength
                    "
                    >Max Length 50.</span
                  >
                  <span
                    *ngIf="
                      getPaymentInfo().controls.billingEmail.errors?.email ||
                      getPaymentInfo().controls.billingEmail.errors?.pattern
                    "
                    >Must be a valid email.</span
                  >
                </span>
              </span></label
            >
            <input
              formControlName="billingEmail"
              name="billingEmail"
              id="billingEmail"
              type="email"
              class="form-control"
              required
              maxlength="50"
              [ngClass]="{
                'is-invalid':
                  getPaymentInfo().controls.billingEmail.invalid &&
                  formDir.submitted
              }"
            />
            <br />
            <br />
          </span>
        </span>
      </div>
      <div class="col-sm-5">
        <span [hidden]="subTotal <= 0">
          <h3>Credit Card Information</h3>
          <br />
          <p>
            Please Note: This is a self-pay test and National Jewish Health will
            not bill your insurance.
          </p>
          <br />
          <label for="cardnumber"
            >Card Number<span class="text-danger"
              >*
              <span
                *ngIf="
                  (getPaymentInfo().controls.ccNumber.invalid &&
                    !getPaymentInfo().controls.ccNumber.pristine) ||
                  formDir.submitted
                "
              >
                <span
                  *ngIf="getPaymentInfo().controls.ccNumber.errors?.required"
                  >Required.</span
                >
                <span
                  *ngIf="
                    getPaymentInfo().controls.ccNumber.errors?.maxlength ||
                    getPaymentInfo().controls.ccNumber.errors?.minlength
                  "
                  >Invalid Number.</span
                >
              </span>
            </span></label
          >
          <input
            formControlName="ccNumber"
            name="ccNumber"
            id="ccNumber"
            type="text"
            class="form-control"
            required
            mask="0000 0000 0000 0999"
            [showMaskTyped]="true"
            [clearIfNotMatch]="false"
            [ngClass]="{
              'is-invalid':
                getPaymentInfo().controls.ccNumber.invalid && formDir.submitted
            }"
          />
          <br />
          <label for="expmonth"
            >Expiration Month<span class="text-danger"
              >*
              <span
                *ngIf="
                  (getPaymentInfo().controls.ccExpirationMonth.invalid &&
                    !getPaymentInfo().controls.ccExpirationMonth.pristine) ||
                  formDir.submitted
                "
              >
                <span
                  *ngIf="
                    getPaymentInfo().controls.ccExpirationMonth.errors?.required
                  "
                  >Required.</span
                >
              </span>
            </span></label
          >
          <select
            formControlName="ccExpirationMonth"
            name="ccExpirationMonth"
            id="ccExpirationMonth"
            class="form-control"
            required
            [ngClass]="{
              'is-invalid':
                getPaymentInfo().controls.ccExpirationMonth.invalid &&
                formDir.submitted
            }"
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <br />
          <label for="validationexpyear"
            >Expiration Year<span class="text-danger"
              >*
              <span
                *ngIf="
                  (getPaymentInfo().controls.ccExpirationYear.invalid &&
                    !getPaymentInfo().controls.ccExpirationYear.pristine) ||
                  formDir.submitted
                "
              >
                <span
                  *ngIf="
                    getPaymentInfo().controls.ccExpirationYear.errors?.required
                  "
                  >Required.</span
                >
              </span>
            </span></label
          >
          <select
            formControlName="ccExpirationYear"
            name="ccExpirationYear"
            id="ccExpirationYear"
            class="form-control"
            required
            [ngClass]="{
              'is-invalid':
                getPaymentInfo().controls.ccExpirationYear.invalid &&
                formDir.submitted
            }"
          >
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
          </select>
          <br />
          <label for="CVV"
            >CVV<span class="text-danger"
              >*
              <span
                *ngIf="
                  (getPaymentInfo().controls.ccCVV.invalid &&
                    !getPaymentInfo().controls.ccCVV.pristine) ||
                  formDir.submitted
                "
              >
                <span *ngIf="getPaymentInfo().controls.ccCVV.errors?.required"
                  >Required.</span
                >
                <span
                  *ngIf="
                    getPaymentInfo().controls.ccCVV.errors?.maxlength ||
                    getPaymentInfo().controls.ccCVV.errors?.minlength
                  "
                  >Must be 3-4 numbers.</span
                >
              </span>
            </span></label
          >
          <input
            formControlName="ccCVV"
            name="ccCVV"
            id="ccCVV"
            type="text"
            required
            class="form-control"
            maxlength="4"
            mask="0009"
            [clearIfNotMatch]="false"
            [ngClass]="{
              'is-invalid':
                getPaymentInfo().controls.ccCVV.invalid && formDir.submitted
            }"
          />
        </span>
        <div align="center">
          <br />
          <strong [hidden]="this.isVaccine"
            >Amount Being Charged: {{ subTotal | currency }}
          </strong>
          <br />
          <br />
          <button
            class="btn btn-primary"
            name="submit"
            id="submitButton"
            type="submit"
            [disabled]="
              this.secondsLeft <= 0 || processingSubmission || invalidHolds
            "
            value="Submit"
            (click)="onSubmit(false)"
          >
            Complete Order
          </button>
          <ng-template #dupWarning>
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" (click)="dupDecline()">
                  &times;
                </button>
                <h4 class="modal-title">
                  Name and email address already in use
                </h4>
              </div>
              <div class="modal-body">
                <p class="alert">
                  It appears that you already have made an appointment for
                  vaccine with National Jewish Health.
                </p>
                <p>
                  Do you wish to also book this new vaccination appointment?
                  Click Complete Order to continue or No to go back and update
                  appointment details.
                </p>
              </div>
              <div class="modal-header">
                <h4 class="modal-title">Still need help?</h4>
              </div>
              <div class="modal-body">
                Contact Administrative Services
                <a href="tel:8772255654">877.225.5654</a>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-primary"
                  name="dupSubmit"
                  id="dupSubmitButton"
                  type="submit"
                  [disabled]="
                    this.secondsLeft <= 0 ||
                    processingSubmission ||
                    invalidHolds
                  "
                  value="dupSubmit"
                  (click)="onSubmit(true)"
                >
                  Complete Order
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="dupDecline()"
                >
                  No
                </button>
              </div>
            </div>
          </ng-template>
          <br />
          <span
            *ngIf="this.aForm.invalid && formDir.submitted"
            class="text-danger"
            >There were validation errors.</span
          >
          <span *ngIf="transactionFailed !== ''" class="text-danger"
            ><br />{{ transactionFailed }}</span
          >
          <br />
          <br />
          <br />
          <div>
            You may receive sensitive information by email. Please provide a
            private email address. When you register, you will receive periodic
            email from National Jewish Health. You can unsubscribe anytime.
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
