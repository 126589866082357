import { IDomainGenderIdentityModel, IDomainGuardianModel } from './../../../models/domains';
import { DynamicFormComponent } from './../../features/dynamic-form/dynamic-form.component';
import { IHeldAppointmentModel } from './../../../models/appointments';
import { RegexValidators } from './../../../validators/RegexValidators';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { IAppointmentOnHoldModel } from 'src/app/models/appointmentHolds';
import { IHeldAppointmentsModel } from 'src/app/models/appointments';
import { IOrderModel, IPaymentModel, IOrderConfirmationModel, IPatientAppointmentsModel, IPatientInsuranceModel } from 'src/app/models/order';
import { IDomainStateModel } from 'src/app/models/domains';
import { IDomainEthnicityModel, IDomainRaceModel, IDomainSexAtBirthModel } from 'src/app/models/domains';
import { DomainService } from 'src/app/services/http/domain.service';
import { AppointmentService } from 'src/app/services/http/appointment.service';
import { AppointmentHoldService } from 'src/app/services/http/appointmenthold.service';
import { OrderService } from 'src/app/services/http/order.service';
import { IsValidUSDateValidator, MinAgeFromDateValidator, MaxAgeFromDateValidator } from 'src/app/validators/Date';
import { IsMatch } from 'src/app/validators/ComparisonValidators';
import { parse, subYears } from 'date-fns';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnDestroy {
  @ViewChild('dupWarning', { static: false }) public dupPopUp: TemplateRef<any>;
  @ViewChild(DynamicFormComponent) dfc: DynamicFormComponent;
  aForm: FormGroup;
  appointments$: IHeldAppointmentsModel;
  domainEthnicityModel$: IDomainEthnicityModel[];
  domainGenderIdentityModel$: IDomainGenderIdentityModel[];
  domainGuardianModel$: IDomainGuardianModel[];
  domainRaceModel$: IDomainRaceModel[];
  domainSexAtBirthModel$: IDomainSexAtBirthModel[];
  domainStateModel$: IDomainStateModel[];
  subTotal = 0;
  processingSubmission = false;
  transactionFailed = '';
  invalidHolds = false;
  releaseHoldModalRef: BsModalRef;
  dupModalRef: BsModalRef;
  secondsLeft = 0;
  interval;

  startTimer() {
    this.interval = setInterval(() => {
      if (this.secondsLeft > 0) {
        this.secondsLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  get isVaccine(): boolean {
    return this.appointments$?.appointments[0]?.productType.toLowerCase() === 'vaccine';
  }

  get appointment(): FormGroup {
    return this.fb.group({
      appointmentId: [''],
      appointmentDate: [''],
      appointmentTimeDisplay: [''],
      heldBy: [''],
      sameContact: [''],
      firstName: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('[a-zA-Z -]*$')]],
      middleInitial: ['', [Validators.maxLength(1), Validators.pattern('[a-zA-Z -]*$')]],
      lastName: ['', [Validators.required, Validators.maxLength(27), Validators.pattern('[a-zA-Z -]*$')]],
      preferredFirstName: ['', [Validators.maxLength(20)]],
      preferredLastName: ['', [Validators.maxLength(27)]],
      mailingAddress: ['', [Validators.required, Validators.maxLength(50)]],
      mailingCity: ['', [Validators.required, Validators.maxLength(50)]],
      mailingState: ['', Validators.required],
      mailingZipCode: ['', [Validators.required, Validators.maxLength(10)]],
      mailingCounty: ['', [Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.maxLength(12)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50),
      Validators.pattern(RegexValidators.EmailValidator)]],
      emailConfirmation: ['', [Validators.required]],
      dob: ['', [Validators.required, IsValidUSDateValidator,
      MinAgeFromDateValidator(this.appointments$?.appointments[0]?.appointmentDate as Date, this.appointments$.appointments[0].minimumAge),
      MaxAgeFromDateValidator(this.appointments$?.appointments[0]?.appointmentDate as Date, this.appointments$.appointments[0].maxAge),
      Validators.pattern(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/)]],
      guardianType: [''],
      guardianFirstName: [''],
      guardianLastName: [''],
      sexAtBirth: ['', Validators.required],
      genderIdentity: ['', Validators.required],
      race: ['', Validators.required],
      ethnicity: ['', Validators.required],
      last4SSN: ['', Validators.maxLength(4)],
      parentProduct: [''],
      hasInsurance: ['', [Validators.required]],
      primaryInsuranceCo: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*$')]],
      policyHolderName: ['', [Validators.required, Validators.maxLength(24), Validators.pattern('[a-zA-Z ]*$')]],
      memberId: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('[a-zA-Z0-9]*$')]],
      groupId: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('[a-zA-Z0-9]*$')]],
      printResults: ['']
    }, {
      validator: IsMatch('email', 'emailConfirmation')
    });
  }

  getAppointment(index: number): FormGroup {
    const appointments = this.aForm.get('appointments') as FormArray;
    return appointments.controls[index] as FormGroup;
  }

  getDynamicForm(): FormGroup {
    return this.aForm.get('dForm') as FormGroup;
  }

  getChildAppointment(): IHeldAppointmentModel {
    return this.appointments$?.appointments?.filter(a => a.parentProduct)[0];
  }

  getPaymentInfo(): FormGroup {
    return this.aForm.get('paymentInformation') as FormGroup;
  }

  constructor(private router: Router, private location: Location, private fb: FormBuilder,
    private appointmentService: AppointmentService, private appointmentHoldService: AppointmentHoldService,
    private domainService: DomainService, private orderService: OrderService, private modalService: BsModalService) { }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  ngOnInit(): void {
    this.aForm = this.fb.group({
      appointments: this.fb.array([]),
      paymentInformation: this.fb.group({
        sameContact: ['no'],
        billingFirstName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z -]*$')]],
        billingLastName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z -]*$')]],
        billingMailingAddress: ['', [Validators.required, Validators.maxLength(50)]],
        billingMailingCity: ['', [Validators.required, Validators.maxLength(50)]],
        billingMailingState: ['', Validators.required],
        billingMailingZipCode: ['', [Validators.required, Validators.maxLength(10)]],
        billingEmail: ['', [Validators.required, Validators.email, Validators.maxLength(50),
        Validators.pattern(RegexValidators.EmailValidator)]],
        billingPhone: ['', [Validators.required, Validators.maxLength(12)]],
        ccNumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(16)]],
        ccExpirationMonth: ['', Validators.required],
        ccExpirationYear: ['', Validators.required],
        ccCVV: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
      })
    });

    if (!this.getAppointments()) {
      this.router.navigateByUrl('/appointments');
      return;
    }

    this.domainService.getDomainEthnicity().subscribe(data => {
      this.domainEthnicityModel$ = data as IDomainEthnicityModel[];
    });

    this.domainService.getDomainGenderIdentity().subscribe(data => {
      this.domainGenderIdentityModel$ = data as IDomainGenderIdentityModel[];
    });

    this.domainService.getDomainGuardian().subscribe(data => {
      this.domainGuardianModel$ = data as IDomainGuardianModel[];
    });

    this.domainService.getDomainRace().subscribe(data => {
      this.domainRaceModel$ = data as IDomainRaceModel[];
    });

    this.domainService.getDomainSexAtBirth().subscribe(data => {
      this.domainSexAtBirthModel$ = data as IDomainSexAtBirthModel[];
    });

    this.domainService.getDomainState().subscribe(data => {
      this.domainStateModel$ = data as IDomainStateModel[];
    });

    
  }

  getTimeLeft(): string {
    const minutes: number = Math.floor(this.secondsLeft / 60);
    const seconds: number = this.secondsLeft - (minutes * 60);
    const dispalySeconds: string = seconds < 10 ? '0' + seconds : seconds.toString();
    return minutes + ':' + dispalySeconds;
  }

  setupAppointmentsList() {
    const appointmentsFormArray = this.aForm.get('appointments') as FormArray;
    // Reset pristine/touched and available appointment list
    this.aForm.get('appointments').reset();
    appointmentsFormArray.clear();
    this.subTotal = 0;
    let insuranceRequired = false;

    if (this.appointments$?.appointments !== undefined) {
      this.appointments$.appointments.forEach(appointment => {
        if (appointment.insuranceRequired.toLowerCase() === 'y') { insuranceRequired = true; }

        if (!appointment.parentProduct) {
          appointmentsFormArray.push(this.appointment);
        }

        this.subTotal = this.subTotal + (this.appointments$?.appointments)[0]?.price;
      });

      this.aForm.get('appointments').patchValue(this.appointments$?.appointments);


    }
    if (!insuranceRequired) {
      const appointments = this.aForm.get('appointments') as FormArray;

      for (const apt of appointments.controls) {
        apt.get('hasInsurance').clearValidators();
        apt.get('primaryInsuranceCo').clearValidators();
        apt.get('policyHolderName').clearValidators();
        apt.get('memberId').clearValidators();
        apt.get('groupId').clearValidators();

        apt.get('hasInsurance').disable();
        apt.get('primaryInsuranceCo').disable();
        apt.get('policyHolderName').disable();
        apt.get('memberId').disable();
        apt.get('groupId').disable();
      }
    }

    if (this.subTotal <= 0) {
      this.aForm.get('');
      this.aForm.get('paymentInformation').clearValidators();
      this.aForm.get('paymentInformation').disable();
    }
  }

  getAppointments(): boolean {
    // TODO: This check should be moved to browser cache in case of refresh and should also include checking the hold attached.
    const appointmentHolds = JSON.parse(sessionStorage.getItem('appointmentHolds')) as IAppointmentOnHoldModel[];

    if (appointmentHolds === null) {
      return false;
    }

    this.appointmentService.getAppointmentsOnHold(appointmentHolds).subscribe(data => {
      this.appointments$ = data as IHeldAppointmentsModel;

      console.log("bind form call.");

      this.dfc.bindForm(this.isVaccine);

      console.log("bind form called.");

      if (this.appointments$?.appointments === null || this.appointments$?.appointments.length <= 0) {
        this.releaseAppointmentHolds(true);
        return false;
      }

      this.secondsLeft = this.appointments$.expireSeconds;
      this.startTimer();
      this.setupAppointmentsList();
      return true;
    },
      (err) => {
        this.releaseAppointmentHolds(true);
        return false;
      }
    );
    return true;
  }

  releaseAppointmentHolds(redirect: boolean) {
    const appointmentHolds = JSON.parse(sessionStorage.getItem('appointmentHolds')) as IAppointmentOnHoldModel[];
    if (appointmentHolds !== null) {
      this.appointmentHoldService.releaseHolds(appointmentHolds).subscribe(data => { });
    }

    sessionStorage.removeItem('appointmentHolds');
    this.releaseHoldModalRef?.hide();
    if (redirect) {
      this.location.back();
    }
  }

  dobChanged(index: number) {
    const dob = parse(this.getAppointment(index).controls.dob.value, 'MM/dd/yyyy', new Date());

    if (dob && this.getAppointment(index).controls.dob.valid && dob >= subYears(new Date(this.appointments$.appointments[0].appointmentDate), this.appointments$.minorAge)) {
      this.getAppointment(index).controls.guardianType.setValidators([Validators.required, Validators.maxLength(20)]);
      this.getAppointment(index).controls.guardianFirstName.setValidators([Validators.required, Validators.maxLength(20), , Validators.pattern('[a-zA-Z -]*$')]);
      this.getAppointment(index).controls.guardianLastName.setValidators([Validators.required, Validators.maxLength(27), , Validators.pattern('[a-zA-Z -]*$')]);
      this.appointments$.appointments[index].requireGuardian = true;
    } else {
      this.getAppointment(index).controls.guardianType.clearValidators();
      this.getAppointment(index).controls.guardianFirstName.clearValidators();
      this.getAppointment(index).controls.guardianLastName.clearValidators();
      this.appointments$.appointments[index].requireGuardian = false;
    }

    this.dfc.setIsMinor((dob && dob >= subYears(new Date(this.appointments$.appointments[0].appointmentDate), this.appointments$.minorAge)));

    this.getAppointment(index).controls.guardianType.updateValueAndValidity();
    this.getAppointment(index).controls.guardianFirstName.updateValueAndValidity();
    this.getAppointment(index).controls.guardianLastName.updateValueAndValidity();
  };

  samePaymentChanged() {

    if (this.getPaymentInfo().controls.sameContact.value === 'yes') {
      this.getPaymentInfo().controls.billingFirstName.clearValidators();
      this.getPaymentInfo().controls.billingLastName.clearValidators();
      this.getPaymentInfo().controls.billingMailingAddress.clearValidators();
      this.getPaymentInfo().controls.billingMailingCity.clearValidators();
      this.getPaymentInfo().controls.billingMailingState.clearValidators();
      this.getPaymentInfo().controls.billingMailingZipCode.clearValidators();
      this.getPaymentInfo().controls.billingEmail.clearValidators();
      this.getPaymentInfo().controls.billingPhone.clearValidators();
    } else {
      this.getPaymentInfo().controls.billingFirstName.setValidators([Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z -]*$')]);
      this.getPaymentInfo().controls.billingLastName.setValidators([Validators.required, Validators.maxLength(50), Validators.pattern('[a-zA-Z -]*$')]);
      this.getPaymentInfo().controls.billingMailingAddress.setValidators([Validators.required, Validators.maxLength(50)]);
      this.getPaymentInfo().controls.billingMailingCity.setValidators([Validators.required, Validators.maxLength(50)]);
      this.getPaymentInfo().controls.billingMailingState.setValidators([Validators.required]);
      this.getPaymentInfo().controls.billingMailingZipCode.setValidators([Validators.required, Validators.maxLength(10)]);
      this.getPaymentInfo().controls.billingEmail.setValidators([Validators.required, Validators.email, Validators.maxLength(50),
      Validators.pattern(RegexValidators.EmailValidator)]);
      this.getPaymentInfo().controls.billingPhone.setValidators([Validators.required, Validators.maxLength(12)]);
    }

    this.getPaymentInfo().controls.billingFirstName.updateValueAndValidity();
    this.getPaymentInfo().controls.billingLastName.updateValueAndValidity();
    this.getPaymentInfo().controls.billingMailingAddress.updateValueAndValidity();
    this.getPaymentInfo().controls.billingMailingCity.updateValueAndValidity();
    this.getPaymentInfo().controls.billingMailingState.updateValueAndValidity();
    this.getPaymentInfo().controls.billingMailingZipCode.updateValueAndValidity();
    this.getPaymentInfo().controls.billingEmail.updateValueAndValidity();
    this.getPaymentInfo().controls.billingPhone.updateValueAndValidity();
  }

  patientSameMailingAddressChanged(index: number) {
    if (this.getAppointment(index).controls.sameContact.value === 'yes') {
      this.getAppointment(index).controls.mailingAddress.clearValidators();
      this.getAppointment(index).controls.mailingCity.clearValidators();
      this.getAppointment(index).controls.mailingState.clearValidators();
      this.getAppointment(index).controls.mailingZipCode.clearValidators();
      this.getAppointment(index).controls.mailingCounty.clearValidators();
    } else {
      this.getAppointment(index).controls.mailingAddress.setValidators([Validators.required, Validators.maxLength(50)]);
      this.getAppointment(index).controls.mailingCity.setValidators([Validators.required, Validators.maxLength(50)]);
      this.getAppointment(index).controls.mailingState.setValidators([Validators.required]);
      this.getAppointment(index).controls.mailingZipCode.setValidators([Validators.required, Validators.maxLength(10)]);
      this.getAppointment(index).controls.mailingCounty.setValidators([Validators.required, Validators.maxLength(50)]);
    }

    this.getAppointment(index).controls.mailingAddress.updateValueAndValidity();
    this.getAppointment(index).controls.mailingCity.updateValueAndValidity();
    this.getAppointment(index).controls.mailingState.updateValueAndValidity();
    this.getAppointment(index).controls.mailingZipCode.updateValueAndValidity();
    this.getAppointment(index).controls.mailingCounty.updateValueAndValidity();
  }

  patientInsuranceChanged(index: number) {
    if (this.getAppointment(index).controls.hasInsurance.value === 'yes') {
      this.getAppointment(index).controls.primaryInsuranceCo.setValidators([Validators.required, Validators.maxLength(30), Validators.pattern('[a-zA-Z ]*$')]);
      this.getAppointment(index).controls.policyHolderName.setValidators([Validators.required, Validators.maxLength(24), Validators.pattern('[a-zA-Z ]*$')]);
      this.getAppointment(index).controls.memberId.setValidators([Validators.required, Validators.maxLength(30), Validators.pattern('[a-zA-Z0-9]*$')]);
      this.getAppointment(index).controls.groupId.setValidators([Validators.required, Validators.maxLength(30), Validators.pattern('[a-zA-Z0-9]*$')]);
    } else {
      this.getAppointment(index).controls.primaryInsuranceCo.clearValidators();
      this.getAppointment(index).controls.policyHolderName.clearValidators();
      this.getAppointment(index).controls.memberId.clearValidators();
      this.getAppointment(index).controls.groupId.clearValidators();
    }

    this.getAppointment(index).controls.primaryInsuranceCo.updateValueAndValidity();
    this.getAppointment(index).controls.policyHolderName.updateValueAndValidity();
    this.getAppointment(index).controls.memberId.updateValueAndValidity();
    this.getAppointment(index).controls.groupId.updateValueAndValidity();
  }

  onSubmit(override: boolean) {
    // this.getFormErrors(this.aForm);
    this.transactionFailed = '';
    if (this.aForm.invalid) {

      //console.error("submit hit!");
      //console.error("form errors->" + this.aForm.errors);

      //this.getFormErrors(this.aForm);

      return;
    }

    this.processingSubmission = true;

    if (override) {
      this.dupModalRef.hide();
    }

    // TODO: Add additional validation and modeling to submit request.
    const orderModel: IOrderModel = this.createOrderModel(override);

    if (this.isVaccine) {
      orderModel.patientAppointments.forEach(pa => {
        pa.patientQuestionAnswers = this.dfc.getPatientQuestionAnswerPool(pa.appointmentId);
      });

      //console.error(orderModel.patientAppointments);

    }

    //console.log(orderModel);
    //return;

    this.orderService.submitOrder(orderModel).subscribe(data => {
      const orderConfirmationModel = data as IOrderConfirmationModel;

      


      switch (orderConfirmationModel?.resultCode?.toLowerCase().trim()) {
        case 'success': {
          if (orderConfirmationModel?.token?.length > 0
            && orderConfirmationModel?.redirectUrl?.length > 0) {
            window.location.href = orderConfirmationModel?.redirectUrl;
            break;
          };

          if (!this.isVaccine) {
            this.router.navigateByUrl('/appointments/confirmation', { state: { orderConfirmationModel } });
            break;
          }


          if (orderConfirmationModel?.token?.length > 0
            && orderConfirmationModel?.singleUseFlag === 'N' &&
            !this.appointments$?.employer?.toLowerCase().includes('(patient)')) {
            this.router.navigateByUrl('/appointments/vaccine/token', { state: { orderConfirmationModel } });
            break;
          }

          this.router.navigateByUrl('/appointments/vaccineconfirmation', { state: { orderConfirmationModel } });
          break;
        }
        case 'possible duplicate': {
          this.processingSubmission = false;
          this.dupModalRef = this.modalService.show(this.dupPopUp, { class: 'modal-sm' });
          break;
        }
        case 'invalid holds': {
          this.processingSubmission = false;
          this.transactionFailed = 'We were unable to process your request.';
          this.invalidHolds = true;
          this.releaseAppointmentHolds(false);
          break;
        }
        case 'declined': {
          this.processingSubmission = false;
          this.transactionFailed = orderConfirmationModel.resultDetails;
          break;
        }
        default: {
          this.processingSubmission = false;
          this.transactionFailed = 'We were unable to process your request.';
          break;
        }
      }
    },
      (err) => {
        this.processingSubmission = false;
        this.transactionFailed = 'We were unable to process your request.';
      }
    );
  }

  getFormErrors(form: AbstractControl) {
    if (form instanceof FormControl) {
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach(key => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          // Only add error if not null
          formErrors[key] = error;
          console.log(key);
          console.log(error);
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  createOrderModel(override: boolean): IOrderModel {
    const orderModel: IOrderModel = {
      patientAppointments: this.getPopulatedPatientModel(),
      payment: this.getPopulatedPaymentModel(),
      total: this.subTotal,
      override: override
    };

    return orderModel;
  }

  getPopulatedPatientModel(): IPatientAppointmentsModel[] {
    const appointments = this.aForm.get('appointments') as FormArray;
    const appointmentsModel: IPatientAppointmentsModel[] = [];
    let index = -1;
    for (const apt of appointments.controls) {
      index++;
      const appointment = this.createPatientModel();

      appointment.heldBy = apt.value.heldBy;
      appointment.appointmentId = apt.value.appointmentId;
      appointment.firstName = apt.value.firstName;
      appointment.middleInitial = apt.value.middleInitial;
      appointment.lastName = apt.value.lastName;
      appointment.preferredFirstName = apt.value.preferredFirstName;
      appointment.preferredLastName = apt.value.preferredLastName;
      appointment.phone = apt.value.phone;
      appointment.email = apt.value.email;
      appointment.dob = apt.value.dob;
      appointment.sextAtBirth = apt.value.sexAtBirth;
      appointment.genderIdentity = apt.value.genderIdentity;
      appointment.race = apt.value.race;
      appointment.ethnicity = apt.value.ethnicity;
      appointment.last4SSN = apt.value.last4SSN;
      appointment.mailTestResults = apt.value.printResults.toString();

      if (this.appointments$.appointments[index].requireGuardian) {
        appointment.guardianType = apt.value.guardianType;
        appointment.guardianFirstName = apt.value.guardianFirstName;
        appointment.guardianLastName = apt.value.guardianLastName;
      }

      if (apt.value.hasInsurance === 'yes') {
        const patientInsurance: IPatientInsuranceModel = {
          primaryInsuranceCo: apt.value.primaryInsuranceCo.toString(),
          policyHolderName: apt.value.policyHolderName,
          memberId: apt.value.memberId,
          groupId: apt.value.groupId
        }

        appointment.patientInsurance = patientInsurance;

      }

      if (apt.value.sameContact === 'yes') {
        appointment.address = this.getAppointment(0).controls.mailingAddress.value;
        appointment.city = this.getAppointment(0).controls.mailingCity.value;
        appointment.stateCode = this.getAppointment(0).controls.mailingState.value;
        appointment.zipCode = this.getAppointment(0).controls.mailingZipCode.value;
        appointment.county = this.getAppointment(0).controls.mailingCounty.value;
      } else {
        appointment.address = apt.value.mailingAddress;
        appointment.city = apt.value.mailingCity;
        appointment.stateCode = apt.value.mailingState;
        appointment.zipCode = apt.value.mailingZipCode;
        appointment.county = apt.value.mailingCounty;
      }

      if (appointments.length === 1 && this.appointments$.appointments.length === 2 && this.appointments$.appointments[1].parentProduct) {
        appointment.childAppointment = {
          appointmentId: this.appointments$.appointments[1].appointmentId,
          heldBy: this.appointments$.appointments[1].heldBy
        }
      }

      appointmentsModel.push(appointment);
    }

    return appointmentsModel;
  }

  openReleaseHoldModal(template: TemplateRef<any>) {
    this.releaseHoldModalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  decline(): void {
    this.releaseHoldModalRef.hide();
  }

  dupDecline(): void {
    this.dupModalRef.hide();
  }

  // TODO: Move below to global model class if needed
  createPatientModel(): IPatientAppointmentsModel {
    const patientModel: IPatientAppointmentsModel = {
      appointmentId: 0,
      firstName: '',
      middleInitial: '',
      lastName: '',
      preferredFirstName: '',
      preferredLastName: '',
      address: '',
      city: '',
      stateCode: '',
      zipCode: '',
      county: '',
      phone: '',
      email: '',
      dob: '',
      guardianType: '',
      guardianEmailAddress: '',
      guardianFirstName: '',
      guardianLastName: '',
      sextAtBirth: '',
      genderIdentity: '',
      race: '',
      ethnicity: '',
      last4SSN: '',
      mailTestResults: '',
      patientInsurance: null,
      patientQuestionAnswers: null,
      heldBy: '',
      childAppointment: null,
    };

    return patientModel;
  }

  getPopulatedPaymentModel(): IPaymentModel {
    const paymentModel = this.createPaymentModel();

    if (this.subTotal <= 0) {
      return null;
    }

    if (this.getPaymentInfo().controls.sameContact.value === 'yes') {
      paymentModel.firstName = this.getAppointment(0).controls.firstName.value;
      paymentModel.lastName = this.getAppointment(0).controls.lastName.value;
      paymentModel.address = this.getAppointment(0).controls.mailingAddress.value;
      paymentModel.city = this.getAppointment(0).controls.mailingCity.value;
      paymentModel.stateCode = this.getAppointment(0).controls.mailingState.value;
      paymentModel.zipCode = this.getAppointment(0).controls.mailingZipCode.value;
      paymentModel.email = this.getAppointment(0).controls.email.value;
      paymentModel.phone = this.getAppointment(0).controls.phone.value;

    } else {
      paymentModel.firstName = this.getPaymentInfo().controls.billingFirstName.value;
      paymentModel.lastName = this.getPaymentInfo().controls.billingLastName.value;
      paymentModel.address = this.getPaymentInfo().controls.billingMailingAddress.value;
      paymentModel.city = this.getPaymentInfo().controls.billingMailingCity.value;
      paymentModel.stateCode = this.getPaymentInfo().controls.billingMailingState.value;
      paymentModel.zipCode = this.getPaymentInfo().controls.billingMailingZipCode.value;
      paymentModel.email = this.getPaymentInfo().controls.billingEmail.value;
      paymentModel.phone = this.getPaymentInfo().controls.billingPhone.value;
    }

    paymentModel.cardNumber = this.getPaymentInfo().controls.ccNumber.value;
    paymentModel.cardExpirationMonth = this.getPaymentInfo().controls.ccExpirationMonth.value;
    paymentModel.cardExpirationYear = this.getPaymentInfo().controls.ccExpirationYear.value;
    paymentModel.cardCVV = this.getPaymentInfo().controls.ccCVV.value;

    return paymentModel;
  }

  createPaymentModel(): IPaymentModel {
    const paymentModel: IPaymentModel = {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      stateCode: '',
      zipCode: '',
      email: '',
      phone: '',
      cardNumber: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      cardCVV: ''
    };

    return paymentModel;
  }
}
