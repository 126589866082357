import { FormGroup, ValidatorFn } from '@angular/forms';

export function IsMatch(control1Name: string, control2Name: string): ValidatorFn {
  return (fg: FormGroup): { [key: string]: boolean } | null => {
    const control1 = fg.controls[control1Name];
    const control2 = fg.controls[control2Name];

    if (control2.errors && !control2.errors.IsMatch) {
      // return if another validator has already found an error on the control2
      return null;
    }

    // set error on control2 if validation fails
    if ((control1.value + '').toLowerCase() !== (control2.value + '').toLowerCase()) {
      return { IsMatch: true };
    }

    return null;
  };
}
