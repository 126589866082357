<div class="container" [hidden]="this.cancellationSuccess$">
  <div class="form-row">
      <h2>COVID-19 Vaccine Cancellation</h2>
  </div>
</div>
<div
  class="container"
  [hidden]="this.isTokenUsed$ || this.cancellationSuccess$"
>
  <div class="row">
    <div class="col-sm-12">
      <p
        class="alert"
        style="font-size: 14pt"
        [hidden]="this.cancellationAppointments$?.length < 1"
      >
        <strong
          >Please click below to confirm your vaccine cancellation.</strong
        >
      </p>
      <p [hidden]="this.cancellationAppointments$?.length >= 1">
        <strong
          >We strongly recommend keeping your current appointment(s), if at all
          possible,
        </strong>
        as we cannot guarantee a new appointment. We cannot change the type of
        vaccine once your appointment is scheduled. Please complete the form
        below to cancel your vaccination appointments.
      </p>
      <ul>
        <li>
          If you are cancelling prior to your first-dose of a two-dose vaccine,
          BOTH appointments will be cancelled. You will need to reschedule
          online for a new time if desired.
        </li>
        <li>
          If you are cancelling a second-dose, please note that it is important
          to complete both vaccinations. Please call our scheduling team at
          <a href="tel:13033981355">303.398.1355</a>. We cannot guarantee a new
          second-dose appointment, but will do our best to accommodate you.
        </li>
      </ul>
      <p [hidden]="this.cancellationAppointments$?.length >= 1">
        <strong
          >Please enter the phone number and date of birth associated with the
          appointment you wish to cancel. BOTH pieces of information must match
          your appointment record.</strong
        >
      </p>
    </div>
  </div>
  <form [formGroup]="aForm" #formDir="ngForm">
    <div
      class="container"
      style="padding-right: 100px"
      [hidden]="this.cancellationAppointments$?.length >= 1"
    >
      <div class="form-row">
        <label for="phone"
          >Phone
          <span class="text-danger"
            >*
            <span
              *ngIf="
                (aForm.controls.phone.invalid &&
                  !aForm.controls.phone.pristine) ||
                formDir.submitted
              "
            >
              <span *ngIf="aForm.controls.phone.errors?.required"
                >Required.</span
              >
              <span *ngIf="aForm.controls.phone.errors?.maxlength"
                >Max Length 12.</span
              >
            </span>
          </span></label
        >
        <input
          formControlName="phone"
          name="phone"
          id="phone"
          type="text"
          class="form-control"
          required
          maxlength="20"
          mask="(000) 000-0000"
          [showMaskTyped]="true"
          [ngClass]="{
            'is-invalid': aForm.controls.phone.invalid && formDir.submitted
          }"
        />
        <br />
      </div>
      <div class="form-row">
        <label for="dob"
          >Date of Birth<span class="text-danger"
            >*
            <span
              *ngIf="aForm.controls.dob.invalid && !aForm.controls.dob.pristine"
            >
              <span *ngIf="aForm.controls.dob.errors?.required">Required.</span>
              <span *ngIf="!aForm.controls.dob.errors?.required">
                <span *ngIf="aForm.controls.dob.errors?.pattern"
                  >Format must be MM/dd/yyyy</span
                >
                <span *ngIf="!aForm.controls.dob.errors?.pattern">
                  <span
                    *ngIf="aForm.controls.dob.errors?.IsValidUSDateValidator"
                    >Must be a valid date.</span
                  >
                </span>
              </span>
            </span>
            <br />
          </span>
        </label>
        <input
          formControlName="dob"
          name="dob"
          id="dob"
          type="text"
          class="form-control"
          required
          placeholder="mm/dd/yyyy"
          mask="00/00/0000"
          [dropSpecialCharacters]="false"
          [showMaskTyped]="true"
          [ngClass]="{
            'is-invalid': aForm.controls.dob.invalid && formDir.submitted
          }"
        />
        <br />
      </div>
      <div
        class="form-row"
        [hidden]="this.cancellationAppointments$?.length > 0"
      >
        <button
          class="btn btn-primary"
          name="submit"
          id="submit"
          type="submit"
          value="Submit"
          (click)="GetAppointmentsSubmit()"
        >
          Continue
        </button>
        <span class="alert" [hidden]="!this.cancellationAppointmentFailed">
          Sorry, we are unable to locate this appointment. Please make sure the
          information matches your appointment record and try again.
        </span>
      </div>
    </div>
  </form>
  <div
    class="container"
    style="padding-right: 100px"
    [hidden]="this.cancellationAppointments$?.length < 1"
  >
    <div class="form-row">
      <strong>Receipt #:</strong> {{ getFirstAppointment()?.chargeId }}
    </div>
    <div class="form-row">
      <strong>Name:</strong> {{ getFirstAppointment()?.firstName }} {{ getFirstAppointment()?.lastName }}
    </div>
    <div class="form-row">
      <strong>Receipt #:</strong> {{ getFirstAppointment()?.chargeId }}
    </div>
    <div
      class="form-row"
      *ngFor="
        let appt of this.cancellationAppointments$;
        let appointmentIndex = index
      "
    >
      <strong>Appointment Date/Time:</strong>
      {{ appt.appointmentDate | date: "EEEE, MMMM d, y" }},
      {{ appt.appointmentTimeDisplay }}
      <br />
    </div>
    <br />
    <div class="form-row">
      <div class="col-sm-6">
        <button
          class="btn btn-primary"
          name="confirm"
          id="confirm"
          type="submit"
          value="confirm"
          (click)="SubmitCancellation()"
        >
          Yes, Please Cancel
        </button>
        <span class="alert" [hidden]="!this.cancellationRequestFailed"
          ><span class="alert"
            >Sorry, we encountered an error with your cancellation
            request.</span
          >
          Please click on the link provided to try again.</span
        >
      </div>
      <div class="col-sm-6">
        <button
          class="btn btn-danger"
          name="confirm"
          id="confirm"
          type="submit"
          value="confirm"
          (click)="SubmitDoNotCancel()"
        >
          No, Go Back
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container" [hidden]="!this.isTokenUsed$">
  <div class="form-row">
    You do not need to do anything else to cancel your appointment(s).
  </div>
</div>

<div
  class="container"
  style="padding-right: 100px"
  [hidden]="!this.cancellationSuccess$"
>
  <div class="form-row">
    <h2>Cancellation Confirmation</h2>
  </div>
  <div class="form-row" style="font-size: 14pt">
    You have successfully canceled the following:
    <br /><br/>
  </div>
  <div class="form-row">
    <strong>Receipt #:</strong> {{ getFirstAppointment()?.chargeId }}
  </div>
  <div class="form-row">
    <strong>Name:</strong> {{ getFirstAppointment()?.firstName }} {{ getFirstAppointment()?.lastName }}
  </div>
  <div class="form-row">
    <strong>Receipt #:</strong> {{ getFirstAppointment()?.chargeId }}
  </div>
  <div
    class="form-row"
    *ngFor="
      let appt of this.cancellationAppointments$;
      let appointmentIndex = index
    "
  >
    <strong>Appointment Date/Time:</strong>
    {{ appt.appointmentDate | date: "EEEE, MMMM d, y" }},
    {{ appt.appointmentTimeDisplay }}
    <br />
  </div>
  <div class="form-row">
    <br />
    <ul>
      <li>
        <strong
          >If you canceled prior to your first-dose of a two-dose vaccine, BOTH
          appointments have been cancelled.</strong
        >
        You will need to reschedule online for a new time if desired.
      </li>
      <li>
        <strong
          >If you canceled a second-dose, please note that it important to
          complete both vaccinations. </strong
        >
        Please call our scheduling team at 303.398.1355. We cannot guarantee a
        new second-dose appointment, but will do our best to accommodate you.
      </li>
    </ul>
    <br/>
  </div>
  <div class="form-row">
    <strong>Thank you.</strong>
  </div>
  <br />
</div>
<br />
