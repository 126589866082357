import { AbstractControl, ValidatorFn } from '@angular/forms';
import { parse, subYears, addDays } from 'date-fns';

export function IsValidUSDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const dt = parse(control.value, 'MM/dd/yyyy', new Date());

  if (control.value !== null && isNaN(dt.getTime())) {
    return { IsValidUSDateValidator: true };
  }
  return null;
}

export function MaxDateValidator(years: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const dt = parse(control.value, 'MM/dd/yyyy', new Date());

    if (control.value !== null && !isNaN(dt.getTime()) && dt >= subYears(new Date(), years)) {
      return { MaxDateValidator: true };
    }
    return null;
  }
}


export function MinAgeFromDateValidator(date: Date, years: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const dt1 = parse(control.value, 'MM/dd/yyyy', new Date());

    // TODO: Change string and day changes once dates are pulled from server
    if (date.constructor.name.toLowerCase() === 'string') {
      date = new Date(date);
    }

    if(!years){
      return null;
    }

    if (control.value !== null && isNaN(dt1.getTime())) {
      return null;
    }
   
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate());
    const utc2 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

    var daysDiff = Math.floor((utc2 - utc1) / _MS_PER_DAY);

    //console.log('DateDiff Calc:', daysDiff);
    //console.log('Years', years);

    if ((daysDiff/365) < years) {
      return { MinAgeFromDateValidator: true };
    }
    return null;
  };
}


//new date function should now handle decimal points for min date validation
/*
export function MinAgeFromDateValidator(date: Date, years: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const dt = parse(control.value, 'MM/dd/yyyy', new Date());

    // TODO: Change string and day changes once dates are pulled from server
    if (date.constructor.name.toLowerCase() === 'string') {
      date = new Date(date);
    }

    if(!years){
      return null;
    }

    if (control.value !== null && isNaN(dt.getTime())) {
      return null;
    }

    if (dt >= addDays(subYears(date, years), 0)) {
      return { MinAgeFromDateValidator: true };
    }
    return null;
  };
}*/

export function MaxAgeFromDateValidator(date: Date, years: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const dt = parse(control.value, 'MM/dd/yyyy', new Date());

    // TODO: Change string and day changes once dates are pulled from server
    if (date.constructor.name.toLowerCase() === 'string') {
      date = new Date(date);
    }

    if(!years){
      return null;
    }

    if (control.value !== null && isNaN(dt.getTime())) {
      return null;
    }

    if (dt <= addDays(subYears(date, years+1), 0)) {
      return { MaxAgeFromDateValidator: true };
    }
    return null;
  };
}
