<span
  [innerHTML]="domainProductTypeConfigContentModel$?.searchPage | htmlSanitize"
></span>

<p>
  <span style="font-size: 14pt">
    <strong>Types of Tests</strong>
  </span>
</p>
<ul>
  <li
    *ngFor="let product of products$; let i = index"
    [innerHTML]="product.productReason | htmlSanitize"
  ></li>
</ul>

<form [formGroup]="aForm" #formDir="ngForm">
  <div
    class="container"
    style="background: #93b4d2; padding: 10px"
    [hidden]="!(this.products$?.length > 0)"
  >
    <div class="row">
      <div class="col-sm-12">
        <div>
          <h3><label for="product">Select a Test Type</label></h3>
          <select
            class="form-control"
            formControlName="product"
            id="product"
            style="width: 75%"
            required
            (change)="productSelected()"
          >
            <option value="">Select a Test</option>
            <option
              *ngFor="let product of products$; let i = index"
              [value]="products$[i].product"
            >
              {{ product.productDisplayName }} -
              {{ product.price | currency }} (insurance will not be billed)
              <span *ngIf="product.futureAppointments <= 0"
                >(Currently Unavailable)</span
              >
            </option>
          </select>
        </div>
        <br />
        <br />
      </div>
    </div>
    <div class="row" [hidden]="!this.getSelectedProduct()?.productDesc">
      <div class="col-sm-12">
        <div
          style="padding-right: 100px"
          [innerHTML]="this.getSelectedProduct()?.productDesc | htmlSanitize"
        ></div>
      </div>
    </div>
    <br />
    <br />
    <div
      class="row"
      [hidden]="
        !this.getAvailableSites() || this.getAvailableSites()?.length <= 1
      "
    >
      <div class="col-sm-12">
        <div>
          <h3>
            <label for="sites">
              Select a location to see appointment times.</label
            >
          </h3>
          <select
            class="form-control"
            formControlName="site"
            id="site"
            style="width: 75%"
            required
            (change)="siteSelected()"
          >
            <option value="0">Select a Site</option>
            <option
              *ngFor="let site of this.getAvailableSites(); let i = index"
              [value]="sites$[i].siteId"
            >
              {{ site.siteDescription }}
              <span *ngIf="site.futureAppointments <= 0"
                >(Currently Unavailable)</span
              >
            </option>
          </select>
        </div>
        <br />
        <br />
      </div>
    </div>
    <div
        class="row"
        [hidden]="
          !this.getSelectedProduct() ||
          (this.getAvailableSites()?.length > 0 &&
            (this.getSite()?.value == '0' ||
              (this.getSite()?.value !== '0' &&
                this.getIsAvailableAppointmentDates())))
        "
      >
        <div class="col-sm-12">
          <div>
            <h3><span class="text-danger">(Currently Unavailable)</span></h3>
          </div>
        </div>
      </div>
    <div class="row" [hidden]="!this.getIsAvailableAppointmentDates()">
      <div class="col-sm-12">
        <div>
          <h3><label for="availableDates">Select a Date</label></h3>
          <select
            class="form-control"
            formControlName="availableDates"
            id="availableDates"
            style="width: 75%"
            required
            (change)="availableDateSelected($event.target.value)"
          >
            <option value="">Select a Date</option>
            <option
              *ngFor="let appointment of availableAppointments$; let i = index"
              [value]="availableAppointments$[i].appointmentDate"
            >
              {{ appointment.appointmentDate | date: "MM/dd/yyyy" }}
              <span *ngIf="appointment.numOpenApptsForDay <= 10">
                - {{ appointment.numOpenApptsForDay }} Remaining</span
              >
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <span [hidden]="!availableAppointmentsSelectedDate$">
    <h4>
      {{ getSelectedDate | date: "EEEE, MMMM dd, yyy" }} - Testing Appointment
      Times
    </h4>
    <div
      formArrayName="appointments"
      class="conatiner"
      style="
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 20px;
        padding-bottom: 10px;
      "
    >
      <!--Begin appt time rows-->
      <div
        *ngFor="
          let line of this.availableAppointmentsSelectedDate$;
          let appointmentIndex = index
        "
        [formGroupName]="appointmentIndex"
      >
        <div class="row">
          <div class="col-sm-8">
            <h4>
              {{
                this.availableAppointmentsSelectedDate$[appointmentIndex]
                  ?.appointmentTimeDisplay
              }}
            </h4>
            {{ this.getSelectedProduct().productDisplayName }} ({{
              this.getSelectedProduct().price | currency
            }}
            each)
          </div>
          <div class="col-sm-4">
            Test Quantity:<span
              class="text-danger"
              *ngIf="
                appointmentsSelected(appointmentIndex) >
                maxAppointmentsToSelect(
                  this.availableAppointmentsSelectedDate$[appointmentIndex]
                    .numAppsAvailable
                )
              "
            >
              {{
                maxAppointmentsToSelect(
                  this.availableAppointmentsSelectedDate$[appointmentIndex]
                    .numAppsAvailable
                )
              }}
              max
            </span>
            <input
              class="form-control"
              type="text"
              formControlName="numAppsSelected"
              placeholder="Enter a number"
              mask="99"
              [clearIfNotMatch]="false"
              (change)="resetHoldWarnings()"
              [ngClass]="{
                'is-invalid':
                  getAppointment(appointmentIndex).controls.numAppsSelected
                    .invalid
              }"
            />
            <br />
            <div style="text-align: right; font-weight: bold">
              {{
                this.availableAppointmentsSelectedDate$[appointmentIndex]
                  .numAppsAvailable
              }}
              Available
            </div>
          </div>
        </div>
      </div>
      <!--End appt time rows-->
    </div>
    <br />
    <div class="text-right">
      <h4 [hidden]="this.totalAppointmentsSelected > 4">
        Total: {{ subTotal | currency }}
      </h4>
      <button
        class="btn btn-primary"
        type="button"
        (click)="onSubmit()"
        [disabled]="
          aForm.pristine ||
          aForm.invalid ||
          this.totalAppointmentsSelected > 4 ||
          this.totalAppointmentsSelected < 1
        "
      >
        Next
      </button>
    </div>
  </span>
  <div
    class="text-right text-danger"
    [hidden]="this.totalAppointmentsSelected <= 4"
  >
    Max 4 appointments per order
  </div>
</form>
<div class="text-right text-danger" [hidden]="!this.quantityChangeWarning">
  We apologize, but the appointment time&#40;s&#41; you selected is/are no
  longer available. Please select a new date above.
</div>
<div class="text-right text-danger" [hidden]="!this.dayChangeWarning">
  We apologize, but the appointment time&#40;s&#41; you selected is/are no
  longer available. Please select a new date above.
</div>
<br />
<br />
<br />
