import { element } from 'protractor';
import { IFutureVaccineAppointmentsByDateModel, IFutureAppointmentsByDateModel } from './../../../models/appointments';
import { DomainService } from 'src/app/services/http/domain.service';
import { IDomainProductModel, IDomainSiteCountModel, IDomainProductTypeConfigContentsModel, IDomainSiteEmployerCountModel } from './../../../models/domains';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, ElementRef, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { IHoldRequestModel, IAppointmentHoldRequestModel, IAppointmentOnHoldModel } from 'src/app/models/appointmentHolds';
import { IAvailableAppointmentsModel } from 'src/app/models/appointments';
import { AppointmentService } from 'src/app/services/http/appointment.service';
import { AppointmentHoldService } from 'src/app/services/http/appointmenthold.service';
import { AppointmentHubService } from 'src/app/services/signalr/appointment-hub.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vaccine',
  templateUrl: './vaccine.component.html',
  styleUrls: ['./vaccine.component.css']
})
export class VaccineComponent implements OnInit {
  aForm: FormGroup;
  availableAppointments1$: IFutureVaccineAppointmentsByDateModel[];
  availableAppointments2$: IFutureAppointmentsByDateModel[];
  availableAppointmentsSelectedDate1$: IAvailableAppointmentsModel[];
  availableAppointmentsSelectedDate2$: IAvailableAppointmentsModel[];
  domainProductTypeConfigContentModel$: IDomainProductTypeConfigContentsModel;
  products$: IDomainProductModel[];
  allProducts$: IDomainProductModel[]
  product1: IFutureVaccineAppointmentsByDateModel;
  product2: IFutureAppointmentsByDateModel;
  sites$: IDomainSiteEmployerCountModel[];
  selectedDate1: Date;
  selectedDate2: Date;
  selectedAppointment1: IAvailableAppointmentsModel;
  selectedAppointment2: IAvailableAppointmentsModel;
  quantityChangeWarning: boolean;
  dayChangeWarning: boolean;
  soldOutWarning: boolean;
  availabilityCount: number;

  get Attestation() {

    return this.aForm.get('attestation');
  }
  getIsAvailableAppointmentDates(): boolean {
    return this.availableAppointments1$?.length > 0;
  }

  maxAppointmentsToSelect(maxAvailable): number {
    return maxAvailable >= 4 ? 4 : maxAvailable;
  }

  appointmentsSelected(index: number): number {
    const appointments = this.aForm.get('appointments') as FormArray;
    return appointments.at(index).value.numAppsSelected;
  }

  getAppointment(index: number): FormGroup {
    const appointments = this.aForm.get('appointments') as FormArray;
    return appointments.controls[index] as FormGroup;
  }

  getSelectedProduct1(): IDomainProductModel {
    return this.products$?.find(p => p.product === this.getProduct().value);
  }

  getSelectedProduct2(): IDomainProductModel {
    return this.allProducts$?.find(p => p.product === this.getProduct()?.value);
  }

  getAvailableSites(): IDomainSiteEmployerCountModel[] {
    return this.sites$?.filter(s => s.futureAppointments > 0);
  }

  getSelectedSite(): IDomainSiteEmployerCountModel {
    return this.sites$?.find(s => s.siteId.toString() === this.getSite().value.toString());
  }

  getProduct() {
    return this.aForm.get('product');
  }

  get AvailableTimes1() {
    return this.aForm.get('availableTimes1');
  }

  get AvailableTimes2() {
    return this.aForm.get('availableTimes2');
  }

  getSite() {
    return this.aForm.get('site');
  }

  constructor(private router: Router, private fb: FormBuilder,
    private appointmentService: AppointmentService, private appointmentHoldService: AppointmentHoldService,
    private domainService: DomainService, public appointmentHubService: AppointmentHubService, private elRef: ElementRef) { }

  ngOnInit(): void {

    this.aForm = this.fb.group({
      attestation: [''],
      product: [''],
      site: [''],
      availableDates1: [''],
      availableTimes1: [''],
      availableDates2: [''],
      availableTimes2: ['']
    });

    this.domainService.getDomainProductTypeConfigContentsById('Vaccine').subscribe(data => {
      this.domainProductTypeConfigContentModel$ = data as IDomainProductTypeConfigContentsModel;
    });

    this.domainService.getDtcDomainProductVaccine().subscribe(data => {
      this.products$ = data as IDomainProductModel[];

      if (this.products$?.length === 1) {
        this.getProduct().patchValue(this.products$[0].product);
        this.productSelected();
      }
    });

    this.domainService.getDomainProduct().subscribe(data => {
      this.allProducts$ = data as IDomainProductModel[];
    });

    this.appointmentHubService.startAppointmentAvailabilityConnection();
    this.appointmentHubService.getVaccineAvailabilityCountListener();
  }

  getFutureAppointments(dt: Date): void {
    this.appointmentService.getFutureVaccineAppointmentsAvailable(this.getSelectedProduct1()?.product,
      this.getSelectedSite()?.siteId, 'DTC').subscribe(data => {
        this.availableAppointments1$ = data as IFutureVaccineAppointmentsByDateModel[];

        if (dt === null || !this.availableAppointments1$.find(a => a.appointmentDate === dt)) {
          if (this.quantityChangeWarning) {
            this.quantityChangeWarning = false;
            if (this.availableAppointments1$?.length > 0) {
              this.dayChangeWarning = true;
            } else {
              this.soldOutWarning = true;
            }
          }

          dt = this.availableAppointments1$[0]?.appointmentDate;
          this.aForm.get('availableDates1').patchValue('');
        }

        if (this.availableAppointments1$?.length === 1) {
          // Need to pause and allow drop down to be populated before patching
          setTimeout(() => {
            this.aForm.get('availableDates1').patchValue(this.availableAppointments1$[0].appointmentDate);
            this.availableDateSelected1(this.availableAppointments1$[0].appointmentDate);
            this.selectedDate1 = dt;
          }, 50);
        }
      });
  }

  resetHoldWarnings() {
    this.quantityChangeWarning = false;
    this.dayChangeWarning = false;
    this.soldOutWarning = false;
  }

  updateAvailableAppointmentsForSelectedDate1() {
    const datePipe = new DatePipe('en-US');

    if (datePipe.transform(this.selectedDate1, 'MM/dd/yyyy') == null) {
      this.availableAppointmentsSelectedDate1$ = null;
      this.availableAppointmentsSelectedDate2$ = null;
      return;
    }

    this.appointmentService.getAvailableAppointments(
      datePipe.transform(this.selectedDate1, 'MM/dd/yyyy'), this.getSelectedProduct1()?.product, this.getSelectedSite()?.siteId, 'DTC'
    ).subscribe(data => {
      this.availableAppointmentsSelectedDate1$ = data as IAvailableAppointmentsModel[];

      if (this.availableAppointmentsSelectedDate1$?.length === 1) {
        this.aForm.get('availableTimes1').patchValue(this.availableAppointmentsSelectedDate1$[0].appointmentId);
        this.availableAppointmentSelected1(this.availableAppointmentsSelectedDate1$[0].appointmentId.toString());
      }
    }
    );
  }

  updateAvailableAppointmentsForSelectedDate2() {
    const datePipe = new DatePipe('en-US');

    if (datePipe.transform(this.selectedDate2, 'MM/dd/yyyy') == null) {
      this.availableAppointmentsSelectedDate2$ = null;
      return;
    }

    this.appointmentService.getAvailableAppointments(
      datePipe.transform(this.selectedDate2, 'MM/dd/yyyy'), this.product1.childProduct, this.getSelectedSite()?.siteId, 'DTC'
    ).subscribe(data => {
      this.availableAppointmentsSelectedDate2$ = data as IAvailableAppointmentsModel[];

      if (this.availableAppointmentsSelectedDate2$?.length === 1) {
        this.aForm.get('availableTimes2').patchValue(this.availableAppointmentsSelectedDate2$[0].appointmentId);
        this.availableAppointmentSelected2(this.availableAppointmentsSelectedDate2$[0].appointmentId.toString());
      }
    }
    );
  }

  attestationSelected(attestation: string) {
    if (attestation?.toUpperCase() === 'N') {
      document.location.href = 'https://www.nationaljewish.org/patients-visitors/patient-info/important-updates/coronavirus-information-and-resources/covid-19-vaccines/colorado-covid-19-vaccine-eligibility/not-eligible';
    }
  }

  siteSelected() {
    this.availableAppointmentsSelectedDate1$ = null;

    if (this.getSelectedSite()?.futureAppointments > 0) {
      this.getFutureAppointments(null);
    } else {
      this.availableAppointments1$ = null;
    }
  }

  productSelected() {
    this.product1 = null;
    this.product2 = null;
    this.availableAppointments1$ = null;
    this.availableAppointmentsSelectedDate1$ = null;
    this.getSite().patchValue(0);

    if (this.getSelectedProduct1()?.product) {
      this.domainService.getDomainSiteEmployerCount(this.getSelectedProduct1().product, 'DTC').subscribe(data => {
        this.sites$ = data as IDomainSiteEmployerCountModel[];
        console.log(this.sites$);
        if (!this.getAvailableSites || this.getAvailableSites()?.length === 0) {
          this.soldOutWarning = true;
        }
        if (this.getAvailableSites()?.length === 1) {
          this.getSite().patchValue(this.getAvailableSites()[0].siteId);
          this.siteSelected();
        }
      });
    }
  }

  getFutureFollowUpAppointments(dtBegin: Date): void {
    if (this.product1) {
      this.appointmentService.getFutureFollowUpAppointmentsAvailable(this.getSelectedProduct1()?.product, this.product1.childProduct,
        this.getSelectedSite()?.siteId, 'DTC', dtBegin).subscribe(async data => {
          this.availableAppointments2$ = data as IFutureAppointmentsByDateModel[];

          if (this.availableAppointments2$ === null || this.availableAppointments2$.length < 1) {
            this.dayChangeWarning = true;
            this.siteSelected();
          }

          if (this.availableAppointments2$?.length === 1) {
            await this.delay(10);
            this.aForm.get('availableDates2').patchValue(this.availableAppointments2$[0].appointmentDate);
            this.availableDateSelected2(this.availableAppointments2$[0].appointmentDate);
          }
        });
    }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  availableDateSelected1(selectedDate: Date) {
    this.selectedAppointment1 = null;
    this.selectedAppointment2 = null;
    this.AvailableTimes1.patchValue('');
    this.AvailableTimes2.patchValue('');
    this.availableAppointmentsSelectedDate2$ = null;
    this.resetHoldWarnings();

    this.selectedDate1 = selectedDate;
    this.updateAvailableAppointmentsForSelectedDate1();

    if (selectedDate) {
      this.product1 = this.availableAppointments1$?.find(a => a.appointmentDate === selectedDate);
    }

    if (this.product1 && this.product1.childProduct && this.product1.childProduct !== '' && selectedDate) {
      this.getFutureFollowUpAppointments(selectedDate);
    }
  }

  availableDateSelected2(selectedDate: Date) {
    this.selectedAppointment2 = null;
    this.AvailableTimes2.patchValue('');
    this.resetHoldWarnings();

    this.selectedDate2 = selectedDate;
    this.product2 = this.availableAppointments2$.find(a => a.appointmentDate === selectedDate);

    this.updateAvailableAppointmentsForSelectedDate2();
  }

  availableAppointmentSelected1(appointmentId: string) {
    if (Number(appointmentId)) {
      this.selectedAppointment1 = this.availableAppointmentsSelectedDate1$.find(a => a.appointmentId === Number(appointmentId));
    } else {
      this.selectedAppointment1 = null;
    }
  }

  availableAppointmentSelected2(appointmentId: string) {
    if (Number(appointmentId)) {
      this.selectedAppointment2 = this.availableAppointmentsSelectedDate2$.find(a => a.appointmentId === Number(appointmentId));
    } else {
      this.selectedAppointment2 = null;
    }
  }

  onSubmit() {
    // tslint:disable-next-line: prefer-const
    let appointmentHolds: IAppointmentHoldRequestModel[] = [];
    const appointment1 = this.availableAppointmentsSelectedDate1$.find(a => a.appointmentId === this.selectedAppointment1?.appointmentId);
    const appointment2 = this.availableAppointmentsSelectedDate2$?.find(a => a.appointmentId === this.selectedAppointment2?.appointmentId);

    if (!appointment1 || appointment1?.appointmentId <= 0 || !appointment2 || appointment2?.appointmentId <= 0) {
      this.quantityChangeWarning = true;
      this.siteSelected();
    }

    const appointmentHold1: IAppointmentHoldRequestModel = {
      appointmentId: appointment1.appointmentId,
      numberOfAppointments: 1,
      product: appointment1.product,
      token: null,
      price: 0
    };

    appointmentHolds.push(appointmentHold1);

    if (this.product1?.childProduct !== '') {
      const appointmentHold2: IAppointmentHoldRequestModel = {
        appointmentId: appointment2.appointmentId,
        numberOfAppointments: 1,
        product: appointment2.product,
        token: null,
        price: 0
      };

      appointmentHolds.push(appointmentHold2);
    }

    const holdRequest: IHoldRequestModel = {
      appointmentHoldRequests: appointmentHolds,
      subTotal: 0
    };

    this.appointmentHoldService.requestAppointmentHolds(holdRequest).subscribe(data => {
      const holds$ = data as IAppointmentOnHoldModel[];

      if (holds$?.length > 0) {
        sessionStorage.setItem('appointmentHolds', JSON.stringify(holds$));
        this.router.navigateByUrl('/appointments/order');
      } else {
        this.quantityChangeWarning = true;
        this.siteSelected();
      }
    },
      (err) => {
        this.quantityChangeWarning = true;
        this.siteSelected();
      }
    );
  }

  ngOnDestroy() {
    this.appointmentHubService.disconnect();
  }
}
