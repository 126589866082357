import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { ConfirmationComponent } from './components/appointments/confirmation/confirmation.component';
import { OrderComponent } from './components/appointments/order/order.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenComponent } from './components/appointments/token/token.component';
import { VaccineComponent } from './components/appointments/vaccine/vaccine.component';
import { VaccineTokenComponent } from './components/appointments/vaccine-token/vaccine-token.component';
import { DatePipe } from '@angular/common';
import { VaccineConfirmationComponent } from './components/appointments/vaccine-confirmation/vaccine-confirmation.component';
import { HtmlSanitizePipe } from './pipes/html-sanitize.pipe';
import { DynamicFormComponent } from './components/features/dynamic-form/dynamic-form.component';
import { VaccineCancellationComponent } from './components/appointments/vaccine-cancellation/vaccine-cancellation.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'appointments', pathMatch: 'full'},
  { path: 'appointments', component: AppointmentsComponent, data: { title: 'Appointment Search'}, pathMatch: 'full'},
  { path: 'appointments/confirmation', component: ConfirmationComponent, data: { title: 'Appointment Confirmation'}, pathMatch: 'full'},
  { path: 'appointments/order', component: OrderComponent, data: { title: 'Appointment Order'}, pathMatch: 'full'},
  { path: 'appointments/token', component: TokenComponent, data: { title: 'Token Search'}, pathMatch: 'full' },
  { path: 'appointments/vaccine', component: VaccineComponent, data: { title: 'Vaccine Search'}, pathMatch: 'full'},
  { path: 'appointments/vaccineconfirmation', component: VaccineConfirmationComponent, data: { title: 'Vaccine Confirmation'}, pathMatch: 'full'},
  { path: 'appointments/vaccine/token', component: VaccineTokenComponent, data: { title: 'Vaccine Token Search'}, pathMatch: 'full'},
  { path: 'appointments/vaccine/token/:token', component: VaccineTokenComponent, data: { title: 'Vaccine Token Search'}, pathMatch: 'full'},
  { path: 'appointments/vaccine/cancellation/:token', component: VaccineCancellationComponent, data: { title: 'Vaccine Cancellation'}, pathMatch: 'full'}
];

const maskConfig: Partial<IConfig> = { clearIfNotMatch: true };

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AppointmentsComponent,
    ConfirmationComponent,
    OrderComponent,
    TokenComponent,
    VaccineComponent,
    VaccineConfirmationComponent,
    HtmlSanitizePipe,
    DynamicFormComponent,
    VaccineTokenComponent,
    VaccineCancellationComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(maskConfig),
    RouterModule.forRoot(appRoutes, { enableTracing: true, scrollPositionRestoration: 'enabled' }),
    NgbModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent],
  exports: [DynamicFormComponent]
})
export class AppModule { }
