<span [hidden]="!this.formBound">
  <div class="container-fluid" formGroupName="dForm">
    <div class="container-fluid" style="padding-right: 100px">
      
      
      <!---
      <div class="row" class="col-sm-12">
        <h3>COVID-19 Vaccine History</h3>
        If you have already been vaccinated with a COVID-19 vaccine, please complete the following:
        <br /><br />
      </div>
      -->
      <!-- <div class="form-row" [hidden]="!getQuestionPoolQuestionById(1)">
        <div class="col-sm-12">
          <label for="hadCOVIDVaccine"
            >{{ getQuestionPoolQuestionById(1)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.hadCOVIDVaccine.invalid &&
                    !dForm.controls.hadCOVIDVaccine.pristine) ||
                  this.formDir.submitted
                "
              >
                <span *ngIf="dForm.controls.hadCOVIDVaccine.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="hadCOVIDVaccine"
              name="hadCOVIDVaccine"
              id="hadCOVIDVaccine"
              (change)="hadCOVIDVaccineChanged()"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="hadCOVIDVaccine"
              name="hadCOVIDVaccine"
              id="hadCOVIDVaccine"
              (change)="hadCOVIDVaccineChanged()"
            />
            No
          </div>
          <br />
        </div>
      </div> -->

      <!-- <div [hidden]="dForm.controls.hadCOVIDVaccine?.value !== '1'"> -->
<!--       <div>
        <div class="col-sm-6">
          <label for="previousVaccineBrand">{{ getQuestionPoolQuestionById(80)?.questionText
            }}<span class="text-danger">
              <span *ngIf="
                    (dForm.controls.previousVaccineBrand.invalid &&
                      !dForm.controls.previousVaccineBrand.pristine) ||
                    this.formDir.submitted
                  "> -->
                <!-- <span *ngIf="dForm.controls.previousVaccineBrand.errors?.required">Required.</span> -->
             <!--    <span *ngIf="
                      dForm.controls.previousVaccineBrand.errors?.maxlength
                    ">Max Length 20.</span>
              </span>
            </span>
          </label>
          <br />
          <select class="form-control" formControlName="previousVaccineBrand" name="previousVaccineBrand"
            id="previousVaccineBrand" [ngClass]="{
                'is-invalid':
                  dForm.controls.previousVaccineBrand.invalid &&
                  formDir.submitted
              }">
            <option value=""></option>
            <option *ngFor="
                  let questionAnswerPool of getQuestionPoolQuestionById(80)
                    ?.questionAnswerPools;
                  let i = index
                " [value]="
                  getQuestionPoolQuestionById(80)?.questionAnswerPools[i].answer
                    .answerId
                ">
              {{ questionAnswerPool.answer.answerText }}
            </option>
          </select>
          <br />
        </div>

        <div class="col-sm-6">
          <div style="height: 100px; float: left; padding-right:5px;">
            <label for="previousVaccineDate">{{ getQuestionPoolQuestionById(79)?.questionText
              }}<span class="text-danger">
                <span *ngIf="
                    (dForm.controls.previousVaccineDate.invalid &&
                      !dForm.controls.previousVaccineDate.pristine) ||
                    this.formDir.submitted
                  "> -->
                  <!-- <span *ngIf="dForm.controls.previousVaccineDate.errors?.required">Required.</span> -->
                  <!-- <span *ngIf="!dForm.controls.previousVaccineDate.errors?.required"> -->
                 <!--  <span *ngIf="dForm.controls.previousVaccineDate.errors?.pattern">Format must be MM/dd/yyyy</span>
                  <span *ngIf="dForm.controls.previousVaccineDate.errors?.pattern">
                    <span *ngIf="
                          dForm.controls.previousVaccineDate.errors
                            ?.IsValidUSDateValidator
                        ">Must be a valid date.</span>
                  </span>
                </span>
              </span>
            </label>
            <br />
            <input formControlName="previousVaccineDate" name="previousVaccineDate" id="previousVaccineDate" type="text"
              class="form-control" placeholder="mm/dd/yyyy" mask="00/00/0000" [dropSpecialCharacters]="false"
              [showMaskTyped]="true" [ngClass]="{
                'is-invalid':
                  dForm.controls.previousVaccineDate.invalid &&
                  formDir.submitted
              }" />
          </div>
        </div>

          <div class="col-sm-6">
            <label for="previousVaccineBrand2">{{ getQuestionPoolQuestionById(72)?.questionText
              }}<span class="text-danger">
                <span *ngIf="
                      (dForm.controls.previousVaccineBrand2.invalid &&
                        !dForm.controls.previousVaccineBrand2.pristine) ||
                      this.formDir.submitted
                    ">
                  <span *ngIf="
                        dForm.controls.previousVaccineBrand2.errors?.maxlength
                      ">Max Length 20.</span>
                </span>
              </span>
            </label>
            <br />
            <select class="form-control" formControlName="previousVaccineBrand2" name="previousVaccineBrand2"
              id="previousVaccineBrand2" [ngClass]="{
                  'is-invalid':
                    dForm.controls.previousVaccineBrand2.invalid &&
                    formDir.submitted
                }">
              <option value=""></option>
              <option *ngFor="
                    let questionAnswerPool of getQuestionPoolQuestionById(72)
                      ?.questionAnswerPools;
                    let i = index
                  " [value]="
                    getQuestionPoolQuestionById(72)?.questionAnswerPools[i].answer
                      .answerId
                  ">
                {{ questionAnswerPool.answer.answerText }}
              </option>
            </select>
            <br />
          </div>

          <div class="col-sm-6">
            <div style="height: 100px; float: left; padding-right:5px;">
              <label for="previousVaccineDate2">{{ getQuestionPoolQuestionById(68)?.questionText
                }}<span class="text-danger">
                  <span *ngIf="
                      (dForm.controls.previousVaccineDate2.invalid &&
                        !dForm.controls.previousVaccineDate2.pristine) ||
                      this.formDir.submitted
                    ">
                    <span *ngIf="dForm.controls.previousVaccineDate2.errors?.pattern">Format must be MM/dd/yyyy</span>
                    <span *ngIf="dForm.controls.previousVaccineDate2.errors?.pattern">
                      <span *ngIf="
                            dForm.controls.previousVaccineDate2.errors
                              ?.IsValidUSDateValidator
                          ">Must be a valid date.</span>
                    </span>
                  </span>
                </span>
              </label>
              <br />
              <input formControlName="previousVaccineDate2" name="previousVaccineDate2" id="previousVaccineDate2"
                type="text" class="form-control" placeholder="mm/dd/yyyy" mask="00/00/0000"
                [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="{
                  'is-invalid':
                    dForm.controls.previousVaccineDate2.invalid &&
                    formDir.submitted
                }" />
            </div>
          </div>




          <div class="col-sm-6">
            <label for="highRiskVaccineBrand">{{ getQuestionPoolQuestionById(73)?.questionText
              }}<span class="text-danger">
                <span *ngIf="
                      (dForm.controls.highRiskVaccineBrand.invalid &&
                        !dForm.controls.highRiskVaccineBrand.pristine) ||
                      this.formDir.submitted
                    ">
                  <span *ngIf="
                        dForm.controls.highRiskVaccineBrand.errors?.maxlength
                      ">Max Length 20.</span>
                </span>
              </span>
            </label>
            <br />
            <select class="form-control" formControlName="highRiskVaccineBrand" name="highRiskVaccineBrand"
              id="highRiskVaccineBrand" [ngClass]="{
                  'is-invalid':
                    dForm.controls.highRiskVaccineBrand.invalid &&
                    formDir.submitted
                }">
              <option value=""></option>
              <option *ngFor="
                    let questionAnswerPool of getQuestionPoolQuestionById(73)
                      ?.questionAnswerPools;
                    let i = index
                  " [value]="
                    getQuestionPoolQuestionById(73)?.questionAnswerPools[i].answer
                      .answerId
                  ">
                {{ questionAnswerPool.answer.answerText }}
              </option>
            </select>
            <br />
          </div>

          <div class="col-sm-6">
            <div style="height: 100px; float: left; padding-right:5px;">
              <label for="highRiskVaccineDate">{{ getQuestionPoolQuestionById(74)?.questionText
                }}<span class="text-danger">
                  <span *ngIf="
                    (dForm.controls.highRiskVaccineDate.invalid &&
                      !dForm.controls.highRiskVaccineDate.pristine) ||
                    this.formDir.submitted
                  ">
                    <span *ngIf="dForm.controls.highRiskVaccineDate.errors?.pattern">Format must be MM/dd/yyyy</span>
                    <span *ngIf="dForm.controls.highRiskVaccineDate.errors?.pattern">
                      <span *ngIf="
                          dForm.controls.highRiskVaccineDate.errors
                            ?.IsValidUSDateValidator
                        ">Must be a valid date.</span>
                    </span>
                  </span>
                </span>
              </label>
              <br />
              <input formControlName="highRiskVaccineDate" name="highRiskVaccineDate" id="highRiskVaccineDate"
                type="text" class="form-control" placeholder="mm/dd/yyyy" mask="00/00/0000"
                [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="{
                'is-invalid':
                  dForm.controls.highRiskVaccineDate.invalid &&
                  formDir.submitted
              }" />
            </div>
          </div>

          <div class="col-sm-6">
            <label for="boosterDoseBrand">{{ getQuestionPoolQuestionById(75)?.questionText
              }}<span class="text-danger">
                <span *ngIf="
                      (dForm.controls.boosterDoseBrand.invalid &&
                        !dForm.controls.boosterDoseBrand.pristine) ||
                      this.formDir.submitted
                    ">
                  <span *ngIf="
                        dForm.controls.boosterDoseBrand.errors?.maxlength
                      ">Max Length 20.</span>
                </span>
              </span>
            </label>
            <br />
            <select class="form-control" formControlName="boosterDoseBrand" name="boosterDoseBrand"
              id="boosterDoseBrand" [ngClass]="{
                  'is-invalid':
                    dForm.controls.boosterDoseBrand.invalid &&
                    formDir.submitted
                }">
              <option value=""></option>
              <option *ngFor="
                    let questionAnswerPool of getQuestionPoolQuestionById(75)
                      ?.questionAnswerPools;
                    let i = index
                  " [value]="
                    getQuestionPoolQuestionById(75)?.questionAnswerPools[i].answer
                      .answerId
                  ">
                {{ questionAnswerPool.answer.answerText }}
              </option>
            </select>
            <br />
          </div>

          <div class="col-sm-6">
            <div style="height: 100px; float: left; padding-right:5px;">
              <label for="boosterDoseDate">{{ getQuestionPoolQuestionById(76)?.questionText
                }}<span class="text-danger">
                  <span *ngIf="
                    (dForm.controls.boosterDoseDate.invalid &&
                      !dForm.controls.boosterDoseDate.pristine) ||
                    this.formDir.submitted
                  ">
                    <span *ngIf="dForm.controls.boosterDoseDate.errors?.pattern">Format must be MM/dd/yyyy</span>
                    <span *ngIf="dForm.controls.boosterDoseDate.errors?.pattern">
                      <span *ngIf="
                          dForm.controls.boosterDoseDate.errors
                            ?.IsValidUSDateValidator
                        ">Must be a valid date.</span>
                    </span>
                  </span>
                </span>
              </label>
              <br />
              <input formControlName="boosterDoseDate" name="boosterDoseDate" id="boosterDoseDate"
                type="text" class="form-control" placeholder="mm/dd/yyyy" mask="00/00/0000"
                [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="{
                'is-invalid':
                  dForm.controls.boosterDoseDate.invalid &&
                  formDir.submitted
              }" />
            </div>
          </div>

          <div class="col-sm-6">
            <label for="otherDoseBrand">{{ getQuestionPoolQuestionById(77)?.questionText
              }}<span class="text-danger">
                <span *ngIf="
                      (dForm.controls.otherDoseBrand.invalid &&
                        !dForm.controls.otherDoseBrand.pristine) ||
                      this.formDir.submitted
                    ">
                  <span *ngIf="
                        dForm.controls.otherDoseBrand.errors?.maxlength
                      ">Max Length 20.</span>
                </span>
              </span>
            </label>
            <br />
            <select class="form-control" formControlName="otherDoseBrand" name="otherDoseBrand"
              id="otherDoseBrand" [ngClass]="{
                  'is-invalid':
                    dForm.controls.otherDoseBrand.invalid &&
                    formDir.submitted
                }">
              <option value=""></option>
              <option *ngFor="
                    let questionAnswerPool of getQuestionPoolQuestionById(77)
                      ?.questionAnswerPools;
                    let i = index
                  " [value]="
                    getQuestionPoolQuestionById(77)?.questionAnswerPools[i].answer
                      .answerId
                  ">
                {{ questionAnswerPool.answer.answerText }}
              </option>
            </select>
            <br />
          </div>

          <div class="col-sm-6">
            <div style="height: 100px; float: left; padding-right:5px;">
              <label for="otherDoseDate">{{ getQuestionPoolQuestionById(78)?.questionText
                }}<span class="text-danger">
                  <span *ngIf="
                    (dForm.controls.otherDoseDate.invalid &&
                      !dForm.controls.otherDoseDate.pristine) ||
                    this.formDir.submitted
                  ">
                    <span *ngIf="dForm.controls.otherDoseDate.errors?.pattern">Format must be MM/dd/yyyy</span>
                    <span *ngIf="dForm.controls.otherDoseDate.errors?.pattern">
                      <span *ngIf="
                          dForm.controls.otherDoseDate.errors
                            ?.IsValidUSDateValidator
                        ">Must be a valid date.</span>
                    </span>
                  </span>
                </span>
              </label>
              <br />
              <input formControlName="otherDoseDate" name="otherDoseDate" id="otherDoseDate"
                type="text" class="form-control" placeholder="mm/dd/yyyy" mask="00/00/0000"
                [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="{
                'is-invalid':
                  dForm.controls.otherDoseDate.invalid &&
                  formDir.submitted
              }" />
            </div>
          </div> -->

          <!-- <div style="margin-left: 45%; height: 100px; padding-left:5px;"
            [hidden]="dForm.controls.previousVaccineDate.value == '' || dForm.controls.previousVaccineBrand.value == '11'">
            <label for="previousVaccineDate2">{{ getQuestionPoolQuestionById(68)?.questionText
              }}<span class="text-danger">
                <span *ngIf="
                        (dForm.controls.previousVaccineDate2.invalid &&
                          !dForm.controls.previousVaccineDate2.pristine) ||
                        this.formDir.submitted
                      ">

                  <span *ngIf="dForm.controls.previousVaccineDate2.errors?.pattern">Format must be MM/dd/yyyy</span>
                  <span *ngIf="dForm.controls.previousVaccineDate2.errors?.pattern">
                    <span *ngIf="
                              dForm.controls.previousVaccineDate2.errors
                                ?.IsValidUSDateValidator
                            ">Must be a valid date.</span>
                  </span>
                </span>
              </span>
              
            </label>
            <br />
            <input formControlName="previousVaccineDate2" name="previousVaccineDate2" id="previousVaccineDate2"
              type="text" class="form-control" placeholder="mm/dd/yyyy" mask="00/00/0000"
              [dropSpecialCharacters]="false" [showMaskTyped]="true" [ngClass]="{
                    'is-invalid':
                      dForm.controls.previousVaccineDate2.invalid &&
                      formDir.submitted
                  }" />
          </div> -->





          <!-- <div class="row" class="col-sm-12">
            <h3>Screening Questions</h3>
          </div>

          <br /> -->

          <!-- <div class="form-row" [hidden]="!getQuestionPoolQuestionById(31)">
          <div class="col-sm-12">
            <label for="highRiskConditions">{{ getQuestionPoolQuestionById(31)?.questionText
              }}<span class="text-danger">*
                <span *ngIf="
                  (dForm.controls.highRiskConditions.invalid &&
                    !dForm.controls.highRiskConditions.pristine) ||
                  this.formDir.submitted
                ">
                  <span *ngIf="
                    dForm.controls.highRiskConditions.errors?.required
                  ">Required.</span>
                </span>
              </span></label>
            <br />
            <div style="padding-left: 25px">
              <input class="form-check-input" type="radio" value="1" required formControlName="highRiskConditions"
                name="highRiskConditions" id="highRiskConditions" />
              Yes&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="2" required formControlName="highRiskConditions"
                name="highRiskConditions" id="highRiskConditions" />
              No
            </div>
            <br />
          </div>
        </div>

        <br /> -->

          <!-- <div class="form-row" [hidden]="!getQuestionPoolQuestionById(44)">
          <div class="col-sm-12">
            <label for="doseNumber">{{ getQuestionPoolQuestionById(44)?.questionText
              }}<span class="text-danger">*
                <span *ngIf="
                  (dForm.controls.doseNumber.invalid &&
                    !dForm.controls.doseNumber.pristine) ||
                  this.formDir.submitted
                ">
                  <span *ngIf="
                    dForm.controls.doseNumber.errors?.required
                  ">Required.</span>
                </span>
              </span></label>
            <br />
            <div style="padding-left: 25px">
              <input class="form-check-input" type="radio" value="1" required formControlName="doseNumber"
                name="doseNumber" id="doseNumber" />
              1&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="2" required formControlName="doseNumber"
                name="doseNumber" id="doseNumber" />
              2&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="3" required formControlName="doseNumber"
                name="doseNumber" id="doseNumber" />
              3
            </div>
            <br />
          </div>
        </div> -->


        <!--</div>-->
        <!-- </div> -->
        <!-- <div class="form-row" [hidden]="!getQuestionPoolQuestionById(16)">
        <div class="col-sm-12">
          <label for="allergicReactionCovid19"
            >{{ getQuestionPoolQuestionById(16)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.allergicReactionCovid19.invalid &&
                    !dForm.controls.allergicReactionCovid19.pristine) ||
                  this.formDir.submitted
                "
              >
                <span
                  *ngIf="
                    dForm.controls.allergicReactionCovid19.errors?.required
                  "
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="allergicReactionCovid19"
              name="allergicReactionCovid19"
              id="allergicReactionCovid19"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="allergicReactionCovid19"
              name="allergicReactionCovid19"
              id="allergicReactionCovid19"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(17)">
        <div class="col-sm-12">
          <label for="allergicReactionGeneric"
            >{{ getQuestionPoolQuestionById(17)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.allergicReactionGeneric.invalid &&
                    !dForm.controls.allergicReactionGeneric.pristine) ||
                  this.formDir.submitted
                "
              >
                <span
                  *ngIf="
                    dForm.controls.allergicReactionGeneric.errors?.required
                  "
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="allergicReactionGeneric"
              name="allergicReactionGeneric"
              id="allergicReactionGeneric"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="allergicReactionGeneric"
              name="allergicReactionGeneric"
              id="allergicReactionGeneric"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(18)">
        <div class="col-sm-12">
          <label for="allergicReactionOther"
            >{{ getQuestionPoolQuestionById(18)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.allergicReactionOther.invalid &&
                    !dForm.controls.allergicReactionOther.pristine) ||
                  this.formDir.submitted
                "
              >
                <span
                  *ngIf="dForm.controls.allergicReactionOther.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="allergicReactionOther"
              name="allergicReactionOther"
              id="allergicReactionOther"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="allergicReactionOther"
              name="allergicReactionOther"
              id="allergicReactionOther"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(51)">
        <div class="col-sm-12">
          <label for="pregnant"
            >{{ getQuestionPoolQuestionById(51)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.pregnant.invalid &&
                    !dForm.controls.pregnant.pristine) ||
                  this.formDir.submitted
                "
              >
                <span *ngIf="dForm.controls.pregnant.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="pregnant"
              name="pregnant"
              id="pregnant"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="pregnant"
              name="pregnant"
              id="pregnant"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(49)">
        <div class="col-sm-12">
          <label for="clotting"
            >{{ getQuestionPoolQuestionById(49)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.clotting.invalid &&
                    !dForm.controls.clotting.pristine) ||
                  this.formDir.submitted
                "
              >
                <span *ngIf="dForm.controls.clotting.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="clotting"
              name="clotting"
              id="clotting"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="clotting"
              name="clotting"
              id="clotting"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(20)">
        <div class="col-sm-12">
          <label for="vaccine14Days"
            >{{ getQuestionPoolQuestionById(20)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.vaccine14Days.invalid &&
                    !dForm.controls.vaccine14Days.pristine) ||
                  this.formDir.submitted
                "
              >
                <span *ngIf="dForm.controls.vaccine14Days.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="vaccine14Days"
              name="vaccine14Days"
              id="vaccine14Days"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="vaccine14Days"
              name="vaccine14Days"
              id="vaccine14Days"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(21)">
        <div class="col-sm-12">
          <label for="dermalFilters"
            >{{ getQuestionPoolQuestionById(21)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.dermalFilters.invalid &&
                    !dForm.controls.dermalFilters.pristine) ||
                  this.formDir.submitted
                "
              >
                <span *ngIf="dForm.controls.dermalFilters.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="dermalFilters"
              name="dermalFilters"
              id="dermalFilters"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="dermalFilters"
              name="dermalFilters"
              id="dermalFilters"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(22)">
        <div class="col-sm-12">
          <label for="recentCovid"
            >{{ getQuestionPoolQuestionById(22)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.recentCovid.invalid &&
                    !dForm.controls.recentCovid.pristine) ||
                  this.formDir.submitted
                "
              >
                <span *ngIf="dForm.controls.recentCovid.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="recentCovid"
              name="recentCovid"
              id="recentCovid"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="recentCovid"
              name="recentCovid"
              id="recentCovid"
            />
            No
          </div>
          <br />
        </div>
      </div>
      <div class="form-row" [hidden]="!getQuestionPoolQuestionById(23)">
        <div class="col-sm-12">
          <label for="antibodies"
            >{{ getQuestionPoolQuestionById(23)?.questionText
            }}<span class="text-danger"
              >*
              <span
                *ngIf="
                  (dForm.controls.antibodies.invalid &&
                    !dForm.controls.antibodies.pristine) ||
                  this.formDir.submitted
                "
              >
                <span *ngIf="dForm.controls.antibodies.errors?.required"
                  >Required.</span
                >
              </span>
            </span></label
          >
          <br />
          <div style="padding-left: 25px">
            <input
              class="form-check-input"
              type="radio"
              value="1"
              required
              formControlName="antibodies"
              name="antibodies"
              id="antibodies"
            />
            Yes&nbsp;&nbsp;&nbsp;
            <input
              class="form-check-input"
              type="radio"
              value="2"
              required
              formControlName="antibodies"
              name="antibodies"
              id="antibodies"
            />
            No
          </div>
          <br />
        </div>
      </div> -->


        <!-- new questions-->
        <!-- <table>
          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(52)">
                <div class="col-sm-12">
                  <label for="sickOrFever">{{ getQuestionPoolQuestionById(52)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.sickOrFever.invalid &&
                    !dForm.controls.sickOrFever.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.sickOrFever.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td style="width:250px; padding: 10px;"> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="sickOrFever"
                  name="sickOrFever" id="sickOrFever" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="sickOrFever"
                  name="sickOrFever" id="sickOrFever" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="sickOrFever"
                  name="sickOrFever" id="sickOrFever" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(53)">
                <div class="col-sm-12">
                  <label for="allergies">{{ getQuestionPoolQuestionById(53)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.allergies.invalid &&
                    !dForm.controls.allergies.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.allergies.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!--</td>
          <td style="width:250px;"> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="allergies"
                  name="allergies" id="allergies" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="allergies"
                  name="allergies" id="allergies" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="allergies"
                  name="allergies" id="allergies" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(54)">
                <div class="col-sm-12">
                  <label for="seriousallergies">{{ getQuestionPoolQuestionById(54)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.seriousallergies.invalid &&
                    !dForm.controls.seriousallergies.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.seriousallergies.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="seriousallergies"
                  name="seriousallergies" id="seriousallergies" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="seriousallergies"
                  name="seriousallergies" id="seriousallergies" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="seriousallergies"
                  name="seriousallergies" id="seriousallergies" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(55)">
                <div class="col-sm-12">
                  <label for="anaphylaxis">{{ getQuestionPoolQuestionById(55)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.anaphylaxis.invalid &&
                    !dForm.controls.anaphylaxis.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.anaphylaxis.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="anaphylaxis"
                  name="anaphylaxis" id="anaphylaxis" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="anaphylaxis"
                  name="anaphylaxis" id="anaphylaxis" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="anaphylaxis"
                  name="anaphylaxis" id="anaphylaxis" />
                Don't Know
              </div>
            </td>
          </tr> -->

          <!-- <tr>
          <td>
            <div class="form-row" [hidden]="!getQuestionPoolQuestionById(56)">
              <div class="col-sm-12">
                <label for="pregnant">{{ getQuestionPoolQuestionById(56)?.questionText
                  }}<span class="text-danger">*
                    <span *ngIf="
                  (dForm.controls.pregnant.invalid &&
                    !dForm.controls.pregnant.pristine) ||
                  this.formDir.submitted
                ">
                      <span *ngIf="dForm.controls.pregnant.errors?.required">Required.</span>
                    </span>
                  </span></label>
              </div>
            </div>

            <div class="col-sm-12 extrapadding">
              <input class="form-check-input" type="radio" value="1" required formControlName="pregnant" name="pregnant"
                id="pregnant" />
              Yes&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="2" required formControlName="pregnant" name="pregnant"
                id="pregnant" />
              No&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="28" required formControlName="pregnant"
                name="pregnant" id="pregnant" />
              Don't Know
            </div>
          </td>
        </tr> -->

          <!-- <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(60)">
                <div class="col-sm-12">
                  <label for="bleedingDisorder">{{ getQuestionPoolQuestionById(60)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.bleedingDisorder.invalid &&
                    !dForm.controls.bleedingDisorder.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.bleedingDisorder.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="bleedingDisorder"
                  name="bleedingDisorder" id="bleedingDisorder" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="bleedingDisorder"
                  name="bleedingDisorder" id="bleedingDisorder" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="bleedingDisorder"
                  name="bleedingDisorder" id="bleedingDisorder" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(61)">
                <div class="col-sm-12">
                  <label for="guillainBarreSyndrome">{{ getQuestionPoolQuestionById(61)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.guillainBarreSyndrome.invalid &&
                    !dForm.controls.guillainBarreSyndrome.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.guillainBarreSyndrome.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="guillainBarreSyndrome"
                  name="guillainBarreSyndrome" id="guillainBarreSyndrome" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="guillainBarreSyndrome"
                  name="guillainBarreSyndrome" id="guillainBarreSyndrome" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="guillainBarreSyndrome"
                  name="guillainBarreSyndrome" id="guillainBarreSyndrome" />
                Don't Know
              </div>
            </td>
          </tr> -->

          <!-- <tr>
          <td>
            <div class="form-row" [hidden]="!getQuestionPoolQuestionById(58)">
              <div class="col-sm-12">
                <label for="covidIll">{{ getQuestionPoolQuestionById(58)?.questionText
                  }}<span class="text-danger">*
                    <span *ngIf="
                  (dForm.controls.covidIll.invalid &&
                    !dForm.controls.covidIll.pristine) ||
                  this.formDir.submitted
                ">
                      <span *ngIf="dForm.controls.covidIll.errors?.required">Required.</span>
                    </span>
                  </span></label>
              </div>
            </div>

            <div class="col-sm-12 extrapadding">
              <input class="form-check-input" type="radio" value="1" required formControlName="covidIll" name="covidIll"
                id="covidIll" />
              Yes&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="2" required formControlName="covidIll" name="covidIll"
                id="covidIll" />
              No&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="28" required formControlName="covidIll"
                name="covidIll" id="covidIll" />
              Don't Know
            </div>
          </td>
        </tr> -->

          <!-- <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(59)">
                <div class="col-sm-12">
                  <label for="convalescentPlasma">{{ getQuestionPoolQuestionById(59)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.convalescentPlasma.invalid &&
                    !dForm.controls.convalescentPlasma.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.convalescentPlasma.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="convalescentPlasma"
                  name="convalescentPlasma" id="convalescentPlasma" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="convalescentPlasma"
                  name="convalescentPlasma" id="convalescentPlasma" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="convalescentPlasma"
                  name="convalescentPlasma" id="convalescentPlasma" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(57)">
                <div class="col-sm-12">
                  <label for="dermalFillers">{{ getQuestionPoolQuestionById(57)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.dermalFillers.invalid &&
                    !dForm.controls.dermalFillers.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.dermalFillers.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="dermalFillers"
                  name="dermalFillers" id="dermalFillers" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="dermalFillers"
                  name="dermalFillers" id="dermalFillers" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="dermalFillers"
                  name="dermalFillers" id="dermalFillers" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(62)">
                <div class="col-sm-12">
                  <label for="historyBloodClots">{{ getQuestionPoolQuestionById(62)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.historyBloodClots.invalid &&
                    !dForm.controls.historyBloodClots.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.historyBloodClots.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="historyBloodClots"
                  name="historyBloodClots" id="historyBloodClots" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="historyBloodClots"
                  name="historyBloodClots" id="historyBloodClots" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="historyBloodClots"
                  name="historyBloodClots" id="historyBloodClots" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(63)">
                <div class="col-sm-12">
                  <label for="historyMycarditis">{{ getQuestionPoolQuestionById(63)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.historyMycarditis.invalid &&
                    !dForm.controls.historyMycarditis.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.historyMycarditis.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="historyMycarditis"
                  name="historyMycarditis" id="historyMycarditis" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="historyMycarditis"
                  name="historyMycarditis" id="historyMycarditis" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="historyMycarditis"
                  name="historyMycarditis" id="historyMycarditis" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(64)">
                <div class="col-sm-12">
                  <label for="historyHeparin">{{ getQuestionPoolQuestionById(64)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.historyHeparin.invalid &&
                    !dForm.controls.historyHeparin.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.historyHeparin.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="historyHeparin"
                  name="historyHeparin" id="historyHeparin" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="historyHeparin"
                  name="historyHeparin" id="historyHeparin" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="historyHeparin"
                  name="historyHeparin" id="historyHeparin" />
                Don't Know
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(65)">
                <div class="col-sm-12">
                  <label for="historyInflamation">{{ getQuestionPoolQuestionById(65)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.historyInflamation.invalid &&
                    !dForm.controls.historyInflamation.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.historyInflamation.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div> -->
              <!-- </td>
          <td> -->
              <!-- <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="historyInflamation"
                  name="historyInflamation" id="historyInflamation" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="historyInflamation"
                  name="historyInflamation" id="historyInflamation" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="historyInflamation"
                  name="historyInflamation" id="historyInflamation" />
                Don't Know
              </div>
            </td>
          </tr> -->

          <!-- <tr>
          <td>
            <div class="form-row" [hidden]="!getQuestionPoolQuestionById(66)">
              <div class="col-sm-12">
                <label for="immune">{{ getQuestionPoolQuestionById(66)?.questionText
                  }}<span class="text-danger">*
                    <span *ngIf="
                  (dForm.controls.immune.invalid &&
                    !dForm.controls.immune.pristine) ||
                  this.formDir.submitted
                ">
                      <span *ngIf="dForm.controls.immune.errors?.required">Required.</span>
                    </span>
                  </span></label>
              </div>
            </div>

            <div class="col-sm-12 extrapadding">
              <input class="form-check-input" type="radio" value="1" required formControlName="immune" name="immune"
                id="immune" />
              Yes&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="2" required formControlName="immune" name="immune"
                id="immune" />
              No&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="28" required formControlName="immune" name="immune"
                id="immune" />
              Don't Know
            </div>
          </td>
        </tr> -->

          <!-- <tr>
          <td>
            <div class="form-row" [hidden]="!getQuestionPoolQuestionById(67)">
              <div class="col-sm-12">
                <label for="immunosuppressive">{{ getQuestionPoolQuestionById(67)?.questionText
                  }}<span class="text-danger">*
                    <span *ngIf="
                  (dForm.controls.immunosuppressive.invalid &&
                    !dForm.controls.immunosuppressive.pristine) ||
                  this.formDir.submitted
                ">
                      <span *ngIf="dForm.controls.immunosuppressive.errors?.required">Required.</span>
                    </span>
                  </span></label>
              </div>
            </div>

            <div class="col-sm-12 extrapadding">
              <input class="form-check-input" type="radio" value="1" required formControlName="immunosuppressive"
                name="immunosuppressive" id="immunosuppressive" />
              Yes&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="2" required formControlName="immunosuppressive"
                name="immunosuppressive" id="immunosuppressive" />
              No&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="radio" value="28" required formControlName="immunosuppressive"
                name="immunosuppressive" id="immunosuppressive" />
              Don't Know
            </div>
          </td>
        </tr> -->

          <!-- <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(69)">
                <div class="col-sm-12">
                  <label for="youoryourchildimmunocomprimised">{{ getQuestionPoolQuestionById(69)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.youoryourchildimmunocomprimised.invalid &&
                    !dForm.controls.youoryourchildimmunocomprimised.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.youoryourchildimmunocomprimised.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div>

              <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required
                  formControlName="youoryourchildimmunocomprimised" name="youoryourchildimmunocomprimised"
                  id="youoryourchildimmunocomprimised" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required
                  formControlName="youoryourchildimmunocomprimised" name="youoryourchildimmunocomprimised"
                  id="youoryourchildimmunocomprimised" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required
                  formControlName="youoryourchildimmunocomprimised" name="youoryourchildimmunocomprimised"
                  id="youoryourchildimmunocomprimised" />
                Don't Know
              </div>
            </td>
          </tr> -->

          <!-- <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(70)">
                <div class="col-sm-12">
                  <label for="underlyingmedical">{{ getQuestionPoolQuestionById(70)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.underlyingmedical.invalid &&
                    !dForm.controls.underlyingmedical.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.underlyingmedical.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div>

              <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="underlyingmedical"
                  name="underlyingmedical" id="underlyingmedical" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="underlyingmedical"
                  name="underlyingmedical" id="underlyingmedical" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="underlyingmedical"
                  name="underlyingmedical" id="underlyingmedical" />
                Don't Know
              </div>
            </td>
          </tr> -->

          <!-- <tr>
            <td>
              <div class="form-row" [hidden]="!getQuestionPoolQuestionById(71)">
                <div class="col-sm-12">
                  <label for="increasedriskforcovid">{{ getQuestionPoolQuestionById(71)?.questionText
                    }}<span class="text-danger">*
                      <span *ngIf="
                  (dForm.controls.increasedriskforcovid.invalid &&
                    !dForm.controls.increasedriskforcovid.pristine) ||
                  this.formDir.submitted
                ">
                        <span *ngIf="dForm.controls.increasedriskforcovid.errors?.required">Required.</span>
                      </span>
                    </span></label>
                </div>
              </div>

              <div class="col-sm-12 extrapadding">
                <input class="form-check-input" type="radio" value="1" required formControlName="increasedriskforcovid"
                  name="increasedriskforcovid" id="increasedriskforcovid" />
                Yes&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="2" required formControlName="increasedriskforcovid"
                  name="increasedriskforcovid" id="increasedriskforcovid" />
                No&nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" value="28" required formControlName="increasedriskforcovid"
                  name="increasedriskforcovid" id="increasedriskforcovid" />
                Don't Know
              </div>
            </td>
          </tr> -->


        <!-- </table>



        <br /> -->


        <!-- end of new questions !-->




        <!-- <div class="form-row" [hidden]="!getQuestionAnswerPoolByQuestionId(47)">
          <div class="col-sm-12"
            [hidden]="this.singleUse === 'N' && !this.employer.toLocaleLowerCase().includes('(patient)')">
            <strong>
              I have read or had explained to me, and I understand the risks and
              benefits of recieving the COVID-19 vaccine. I have had a chance to
              ask questions, which were answered to my satisfaction. I hereby
              release this provider, its employees and its volunteers from any
              liability for any results which may occur from the administration of
              this vaccine.</strong>
            <br /><br />
          </div>
          <div class="col-sm-12"
            [hidden]="this.singleUse !== 'N' || this.employer.toLocaleLowerCase().includes('(patient)')">
            <strong>You understand the risks and benefits of receiving the covid-19
              vaccine. You have had a chance to ask questions, which were answered
              to your satisfaction. You hereby release this provider, its
              employees and its volunteers from any liability for any results that
              may occur from the administration of the vaccine.</strong>
            &nbsp;&nbsp;
            <button type="button" class="btn" (click)="copyMessage(this.employer)" title="Verify Message"
              alt="Verify Message">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-clipboard" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                <path fill-rule="evenodd"
                  d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
              </svg>
            </button>
            <br /><br />
          </div>
        </div>


        <div class="form-row" [hidden]="!getQuestionAnswerPoolByQuestionId(47)">
          <div class="col-sm-6">
            <label for="signature">{{
              isMinor
              ? getQuestionPoolQuestionById(47)?.questionText.replace(
              "Patient, ",
              ""
              ) +
              " *(parent/guardian attesting must be present with the minor at the appointment)"
              : getQuestionPoolQuestionById(47)?.questionText.replace(
              ", Parent/Guardian",
              ""
              )
              }}<span class="text-danger">*
                <span *ngIf="
                (dForm.controls.signature.invalid &&
                  !dForm.controls.signature.pristine) ||
                this.formDir.submitted
              ">
                  <span *ngIf="dForm.controls.signature.errors?.required">Required.</span>
                </span>
              </span></label>
            <br />




            <input formControlName="signature" name="signature" id="signature" type="text" class="form-control" required
              [readonly]="this.singleUse === 'N' && !this.employer.toLocaleLowerCase().includes('(patient)')" [ngClass]="{
              'is-invalid':
                dForm.controls.signature.invalid && formDir.submitted
            }" />

          </div> -->




          <!-- <div class="col-sm-6">
          <label for="signatureDate">{{ getQuestionPoolQuestionById(48)?.questionText
            }}<span class="text-danger">*
              <span *ngIf="
                    (dForm.controls.signatureDate.invalid &&
                      !dForm.controls.signatureDate.pristine) ||
                    this.formDir.submitted
                  ">
                <span *ngIf="dForm.controls.signatureDate.errors?.required">Required.</span>
                <span *ngIf="!dForm.controls.signatureDate.errors?.required">
                  <span *ngIf="dForm.controls.signatureDate.errors?.pattern">Format must be MM/dd/yyyy</span>
                  <span *ngIf="dForm.controls.signatureDate.errors?.pattern">
                    <span *ngIf="
                          dForm.controls.signatureDate.errors
                            ?.IsValidUSDateValidator
                        ">Must be a valid date.</span>
                  </span>
                </span>
              </span>
            </span>
          </label>
          <br />
          <input formControlName="signatureDate" name="signatureDate" id="signatureDate" type="text"
            class="form-control" required placeholder="mm/dd/yyyy" mask="00/00/0000" [dropSpecialCharacters]="false"
            [showMaskTyped]="true" [ngClass]="{
                'is-invalid':
                  dForm.controls.signatureDate.invalid &&
                  formDir.submitted
              }" />
        </div> -->




          <!-- <div class="form-row" [hidden]="!getQuestionAnswerPoolByQuestionId(47)">
            <div class="col-sm-12">
              <div class="col-sm-12">Type in Full Name</div>
            </div>
          </div>
        </div> -->

      </div>
    </div>

</span>