<span [innerHTML]="orderConfirmation.confirmationHtml | htmlSanitize"></span>

<ng-template #multiDoseNotification>
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        (click)="closeMultiDoseNotification()"
      >
        &times;
      </button>
      <h4 class="modal-title">Two Doses Required</h4>
    </div>
    <div class="modal-body">
      <p>
        COVID-19 vaccination requires two doses.
        <strong
          >You must receive BOTH doses for the vaccine to be effective.</strong
        >
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-default"
        (click)="closeMultiDoseNotification()"
      >
        OK
      </button>
    </div>
  </div>
</ng-template>
