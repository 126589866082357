<span [innerHTML]="orderConfirmation.confirmationHtml | htmlSanitize">
</span>

<ng-template #portalRegistrationNotification>
  <div class="modal-content">
    <div class="modal-header">
      <button
        type="button"
        class="close"
        (click)="closePortalRegistrationNotification()"
      >
        &times;
      </button>
      <h4 class="modal-title">Please Activate Your Account</h4>
    </div>
    <div class="modal-body">
      <p>
        To receive your {{ orderConfirmation?.productDisplayName }} results,
        please look for an email with the subject of
        <em>Activate Your Patient Account Now.</em> This personalized email
        should arrive within two hours and is the fastest way to access your
        account and results. Please check your SPAM folder before taking
        additional steps. If you are under 18 or already have an account, you
        will not receive a new account.
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-default"
        (click)="closePortalRegistrationNotification()"
      >
        OK
      </button>
    </div>
  </div>
</ng-template>
