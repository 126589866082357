import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentHubService {
  public availabilityCount: number = 1;
  constructor(private http: HttpClient) { }

  url: string = environment.apiBaseUrl;
  private appointmentAvailabilityConnection: signalR.HubConnection

  public startAppointmentAvailabilityConnection = () => {
    this.appointmentAvailabilityConnection = new signalR.HubConnectionBuilder()
    //[0, 2000, 10000, 30000, null] 0,2,10,30 seconds
      .withAutomaticReconnect()
      .withUrl(this.url + 'vaccineAvailability')
      .build();

    this.appointmentAvailabilityConnection
      .start()
      .then(() => console.log('Connection started')).catch(err => console.log('Error while starting connection: ' + err))
  }

  public getVaccineAvailabilityCountListener() {
    this.appointmentAvailabilityConnection.on('broadcastVaccineAvailabilityCount', (availabilityCount) => {
      // console.log(availabilityCount + ' ' + (new Date()).toTimeString());
      this.availabilityCount = availabilityCount as number;
    })
  }

  public disconnect() {
    console.log("stopped");
    this.appointmentAvailabilityConnection.stop();
  }
}
