import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import { IAppointmentOnHoldModel } from 'src/app/models/appointmentHolds';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http: HttpClient, private datePipe: DatePipe) { }
  url: string = environment.apiBaseUrl;

  getAvailableAppointments(dt: string, product: string, siteId: number, employerName: string) {
    let params = new HttpParams().set("appointmentDate", dt).set("product", product).set("siteId", siteId.toString()).set("employerName", employerName);
    return this.http.get(this.url + 'Appointment/AvailableAppointments', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getFutureAppointmentsAvailable(product: string, siteId: number, employerName: string) {
    let params = new HttpParams().set("product", product).set("siteId", siteId.toString()).set("employerName", employerName);
    return this.http.get(this.url + 'Appointment/FutureAppointmentsAvailable', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getFutureFollowUpAppointmentsAvailable(parentProduct: string, childProduct: string, siteId: number,
    employerName: string, parentDate: Date) {
    let params = new HttpParams().set("parentProduct", parentProduct).set("childProduct", childProduct)
      .set("siteId", siteId.toString()).set("employerName", employerName).set("parentDate", this.datePipe.transform(parentDate, 'MM/dd/yyyy'));
    return this.http.get(this.url + 'Appointment/FutureFollowUpAppointmentsAvailable', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getFutureVaccineAppointmentsAvailable(product: string, siteId: number, employerName: string) {
    let params = new HttpParams().set("product", product).set("siteId", siteId.toString()).set("employerName", employerName);
    return this.http.get(this.url + 'Appointment/FutureVaccineAppointmentsAvailable', { params })
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  getAppointmentsOnHold(holds: IAppointmentOnHoldModel[]) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post(this.url + 'Appointment/GetAppointmentsOnHold/', holds, { headers })
      .pipe(catchError(this.handleError));
  }

  getVaccineAvailabilityCount() {
    return this.http.get(this.url + 'Appointment/GetVaccineAvailabilityCount')
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Invalid Server Response; please try again later.');
  }
}
